import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import { Text, TouchableOpacity, View } from 'react-native';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { authGetToken } from '../actions/token';
import { BASE_URL } from '../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatPlayersName } from '../utils/utils';
import * as translations from '../utils/translations';
import { getDomainOperator } from "../utils/domainMapper";

const operator = getDomainOperator();

const design = operator.design;

const styles = {
  title: {
    backgroundColor: design.primaryColor,
    height: '64px',
    width: '60%',
    maxWidth: '350px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 9999
  },
  titleText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 550
  },
  exitButton: {
    backgroundColor: '#FF085D',
    width: 22,
    height: 22,
    position: 'absolute',
    paddingBottom: 5,
    top: 1,
    right: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999
  },
  exitButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5
  },
  infoContainer: {
    marginTop: 70,
    padding: 10
  },
  loader: {
    width: '100%',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noStatisticsText: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

const classes = makeStyles({
  container: {
    height: '100vh !important'
  }
});

export const PlayerPointsDialog = ({open, playerId, onClose, ...props}) => {
  const dispatch = useDispatch();

  const [data, setData] = React.useState({
    breakDown: null
  });

  React.useEffect(() => {
    const effect = async () => {
      const authToken = await dispatch(authGetToken());

      const res = await fetch(BASE_URL + '/users/team/teamplayer/' + playerId + '/stats/round/-1', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': authToken
        }
      });

      if(res.ok) {
        setData((await res.json()).data);
      } else {
        typeof(onClose) === 'function' && onClose();

        alert('An error occurred while trying to fetch the players breakdown');
      }
    };

    effect();
  }, [playerId]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="dialog-title"
      open={open}
      PaperProps={{
        style: {
          width: '60%',
          maxWidth: '350px'
        }
      }}
      style={{ maxHeight: '1700px' }}
      classes={{
        root: classes.container,
        container: classes.container
      }}
    >
      <View style={styles.title}>
        <TouchableOpacity
          style={styles.exitButton}
          onPress={onClose}
        >
          <Text style={styles.exitButtonText}>x</Text>
        </TouchableOpacity>
        <Text style={styles.titleText}>
          {formatPlayersName(props, 'firstFormatted')}: {props.points} {props.points === 1 ? translations.tKey('str_player_points_dialog_point') : translations.tKey('str_player_points_dialog_points')}
        </Text>
      </View>

      <View style={styles.infoContainer}>
        {data.breakDown ? (
          data.breakDown.length !== 0 ? (
            data.breakDown.map((value, index) => (
              <View
                style={styles.infoRow}
                key={index}
              >
                <Text style={{ paddingRight: '10px' }}>{value.criteria}</Text>
                <Text>{value.points}</Text>
              </View>
            ))
          ) : (
            <Text style={styles.noStatisticsText}>{translations.tKey('str_player_points_dialog_no_existing_stats')}</Text>
          )
        ) : (
          <div style={styles.loader}>
            <CircularProgress/>
          </div>
        )}
      </View>
    </Dialog>
  );
};

PlayerPointsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  playerId: PropTypes.string.isRequired,

  onClose: PropTypes.func.isRequired,

  firstName: PropTypes.string,
  lastName: PropTypes.string,
  points: PropTypes.number
};