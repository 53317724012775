import * as React from "react";

function SvgPlayer(props) {
  return (
    <svg viewBox="0 0 18.257 13.613" {...props}>
      <path
        d="M18.257 4.442L13.815 0h-2.363c0 .383-1.04.693-2.323.693S6.806.383 6.806 0H4.443L.001 4.442l2.4 2.4 2.046-2.046v8.823h9.375V4.796l2.046 2.046z"
        fill="#bebebe"
      />
    </svg>
  );
}

export default SvgPlayer;
