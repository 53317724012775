import {
  SET_PLAYER_SHORT_INFO, SET_AVAILABLE_PLAYERS_FOR_TRANSFER,
  REMOVE_AVAILABLE_PLAYER_FOR_TRANSFER,
  RESTORE_AVAILABLE_PLAYER_FOR_TRANSFER,
  SET_TEAM_FOR_TRANSFERS
} from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { getTeam } from '../../Team/actions/team';
import { setError } from '../../Common/actions/errors';
import { setBudget } from '../../Home/actions/home';

export const getPlayerShortInfo = playerId => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/player/' + playerId + '/info', {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (!res.data.image) {
          res.data.image = BASE_URL + "/uploads/nologo.png";
        } else {
          res.data.image = BASE_URL + res.data.image;
        }
        if (Array.isArray(res.data.possibleChanges)) {
          res.data.possibleChanges.forEach(change => {
            change.iconUrl = BASE_URL + change.iconUrl;
          });
        }
        if (Array.isArray(res.data.previousMatches)) {
          res.data.previousMatches.forEach(match => {
            match.teamLogо = BASE_URL + match.teamLogо;
          })
        }

        if (Array.isArray(res.data.nextMatches)) {
          res.data.nextMatches.forEach(match => {
            match.teamLogо = BASE_URL + match.teamLogо;
          })
        }
        dispatch(setPlayerShortInfo(res.data))
      });
  };
}

export const setPlayerShortInfo = playerInfo => {
  return {
    type: SET_PLAYER_SHORT_INFO,
    playerInfo
  };
};

export const getAvailablePlayersForTransfer = (positionId, teamId, sortBy, excludeTeamPlayers, id) => {
  return (dispatch, getState) => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        let params = '';
        if (positionId) {
          params += 'position_id=' + positionId;
        }
        if (teamId) {
          if (params.length > 0) params += '&';
          params += 'team_id=' + teamId;
        }
        if (!sortBy) sortBy = 'team';
        if (params.length > 0) params += '&';
        params += 'sort_by=' + sortBy;

        return fetch(BASE_URL + (`/championships/${id}/players?` + params), {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        res.data.forEach(player => player.iconUrl = BASE_URL + player.iconUrl);

        if (excludeTeamPlayers === undefined || !excludeTeamPlayers) {
          const userTeam = getState().transfers.userTeam;
          res.data = res.data.filter(player => {
            return !userTeam.groups.some(group => group.players.some(p => p.playerId == player.playerId)) &&
              !userTeam.reserves.some(p => p.playerId == player.playerId);
          });
        }
        dispatch(setAvailablePlayersForTransfer(res.data))
      });
  };
}

export const makeTransfer = (oldPlayers, newPlayers) => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/transfers', {
          credentials: 'include',
          method: 'POST',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          },
          body: JSON.stringify(oldPlayers.map((oldPlayer, i) => {
            return {
              oldPlayerId: oldPlayer.playerId,
              newPlayerId: newPlayers[i].playerId
            }
          }))
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error && res.status === 503) {
          dispatch(setError('Кръга е затворен!', 503));
          return;
        }
        if (res.has_error) {
          alert(res.message);
          return;
        }

        const userTeam = res.data;
        userTeam.groups.forEach(g => {
          g.players.forEach(p => {
            p.iconUrl = BASE_URL + p.iconUrl;
            p.realPlayerId = p.id;
          });
        });
        userTeam.reserves.forEach(p => {
          p.iconUrl = BASE_URL + p.iconUrl;
          p.realPlayerId = p.id;
        });
        dispatch(setTeam(res.data));
        // refresh in team screen
        dispatch(getTeam());
        dispatch(setBudget(res.data.budget));
      });
  };
}

export const setTeam = team => {
  return {
    type: SET_TEAM_FOR_TRANSFERS,
    team
  };
};


export const setAvailablePlayersForTransfer = players => {
  return {
    type: SET_AVAILABLE_PLAYERS_FOR_TRANSFER,
    players
  };
};


export const removeAvailablePlayerForTransfer = playerId => {
  return {
    type: REMOVE_AVAILABLE_PLAYER_FOR_TRANSFER,
    playerId
  };
};

export const restoreAvailablePlayerForTransfer = player => {
  return {
    type: RESTORE_AVAILABLE_PLAYER_FOR_TRANSFER,
    player
  };
};