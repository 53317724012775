import React from 'react';
import { View, Text, Image, TouchableOpacity, Linking } from 'react-native';

import { createStyles } from 'react-native-media-queries';
import close from '../../assets/images/close.png';
import check from '../../assets/images/check.png';
import expandMore from '../../assets/images/expand_more.png';
import expandLess from '../../assets/images/expand_less.png';
import * as translations from '../../Common/utils/translations'
import { Icon } from '../../Common/components/Icon';

class TournamentRules extends React.Component {

  state = {
    safetyNetOpen: false,
    stackingPenaltyOpen: false,
    seasonalGameUrl: 'https://blog.ffstars.com/2020/08/11/fantasyseason21/'
  }

  getRowComponent = (name, value) => {
    return (
      <View style={styles.nameValueRow}>
        <View style={styles.nameRow}>
          <Text style={styles.textNormal}>{name}</Text>
        </View>
        <View style={styles.valueRow}>
          <Text style={styles.textNormal}>{value}</Text>
        </View>
      </View>
    );
  }

  getArrow = (open) => (
    <div style={{ ...styles.iconContainer, ...(open && styles.flipVertical) }}>
      <Icon icon="arrow" size={14} color="#FF4183"/>
    </div>
  )

  loadInBrowser = () => {
    Linking.openURL(this.state.seasonalGameUrl).catch(err => console.error("Couldn't load page", err));
  };

  render() {
    const {rules, subgameId} = this.props;

    if (!rules) {
      return null;
    }

    return (
      <View style={styles.container}>
        {this.getRowComponent(translations.tKey('str_rules_tournament_run'), rules.tournament_run)}
        {this.getRowComponent(translations.tKey('str_rules_budget'), `${rules.team_budget}M`)}
        {this.getRowComponent(translations.tKey('str_rules_max_players_per_team'), rules.max_players_per_team)}

        {this.getRowComponent(
          translations.tKey('str_rules_bench_players'),
          !rules.reserve_players || rules.reserve_players == -1
            ? 'X'
            : rules.reserve_players)}

        <TouchableOpacity
          style={{width: '100%'}}
          onPress={() => {
            this.props.onScoringClicked(subgameId)
          }}
        >
          <View style={styles.nameValueRow}>
            <View style={styles.nameRow}>
              <Text style={styles.textNormal}>{translations.tKey('str_rules_score_calc')}</Text>
              {this.getArrow(false)}
            </View>
            <View style={styles.valueRow}>
              <Text style={styles.textNormal}>{rules.score_calculation}</Text>
            </View>
          </View>
        </TouchableOpacity>

        {this.getRowComponent(
          translations.tKey('str_rules_teams_per_user_allowed'),
          !rules.teams_per_user || rules.teams_per_user == -1
            ? translations.tKey('str_rules_unlimited')
            : rules.teams_per_user)}

        {this.getRowComponent(
          translations.tKey('str_rules_prize_distribution'),
          rules.prize_distribution === '---'
            ? rules.prize_distribution
            : `${translations.tKey('str_rules_ratio')} ${rules.prize_distribution}`)}

        {rules.is_seasonal_game && (
          <View style={styles.nameValueRow}>
            <View style={styles.expandableColumn}>
              <TouchableOpacity
                style={styles.expandableOpacity}
              >
                  <Text style={styles.textNormal}>
                    {translations.tKey('str_seasonal_detail_rules')}
                  </Text>

              
              </TouchableOpacity>
            </View>

            <View style={styles.valueRow}>
            <Text  style={styles.textNormal}>
               <a href="https://blog.ffstars.com/?p=1479" target="_blank">Тук</a>
                  </Text>
            </View>
          </View>
        )}

        {rules.safety_net_active && (
          <View style={styles.nameValueRow}>
            <View style={styles.expandableColumn}>
              <TouchableOpacity
                style={styles.expandableOpacity}
                onPress={() =>
                  this.setState({safetyNetOpen: !this.state.safetyNetOpen})
                }
              >
                <View style={styles.imageRow}>
                  <Text style={styles.textNormal}>
                    {translations.tKey('str_rules_safety_net')}
                  </Text>

                  {this.getArrow(this.state.safetyNetOpen)}
                </View>
              </TouchableOpacity>
            </View>

            <View style={styles.valueRow}>
              <Image style={styles.image} source={rules.stacking_penalty_active ? close : check} />
            </View>
          </View>
        )}

        {this.state.safetyNetOpen && (
          <View style={styles.descriptionView}>
            <Text style={styles.descriptionText}>
              {translations.tKey("str_tournament_rules_safety_subs")}
            </Text>
          </View>
        )}

        {this.state.stackingPenaltyOpen && (
          <View style={styles.descriptionView}>
            <Text style={styles.descriptionText}>
            {translations.tKey("str_tournament_rules_safety_net_open")}
            </Text>
          </View>)}
      </View>
    );
  }
}

export default TournamentRules;

const base = {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  textNormal: {
    fontSize: 14
  },
  descriptionText: {
    fontSize: 14
  },
  descriptionView: {
    width: '100%',
    paddingHorizontal: '5%',
    paddingVertical: '20px',
    marginVertical: '20px',
    backgroundColor: '#EFEFEF'
  },
  nameValueRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  nameRow: {
    paddingVertical: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: '5px',
    maxWidth: '70%'
  },
  valuesRow: {
    paddingVertical: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  expandableColumn: {
    width: '80%',
    display: 'flex',
    paddingVertical: '5px'
  },
  imageRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconContainer: {
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    width: 20,
    height: 20,
    resizeMode: 'cover'
  },
  flipVertical: {
    transform: 'scale(1, -1)'
  }
};

const styles = createStyles(base)

