const log = (logLever, ...args) => {
  if (process.env.REACT_APP_LOG_LEVEL >= logLever) {
    console.log('[Trace]', ...args);
  }
}

export const logTrace = (...attr) => log(6, ...attr);
export const logDebug = (...attr) => log(5, ...attr);
export const logInfo  = (...attr) => log(4, ...attr);
export const logWarn  = (...attr) => log(3, ...attr);
export const logError = (...attr) => log(2, ...attr);
export const logFatal = (...attr) => log(1, ...attr);