import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
 
 export const RoundsDialog = (props) => {
    const { rounds } = props;
    if (!rounds) return null;
    return(
    <Dialog onClose={() => props.onClose()} aria-labelledby="dialog-title" open={props.open}
    style={{maxHeight: 500}}>
        <DialogTitle id="dialog-title">Изберете кръг</DialogTitle>
        <div>
          <List>
            {rounds.map(round => (
              <ListItem button onClick={(event) => { 
                 props.onClose(round)
              }
                } key={round.id}>
                <ListItemText primary={round.name} />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
     )
 }
 
 export default RoundsDialog;