import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { BASE_URL } from '../../Common/config/config';
import { isMobile } from "react-device-detect";
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView } from "react-native";
import { createStyles, maxWidth, maxHeight } from 'react-native-media-queries';
import { getDomainOperator } from '../../Common/utils/domainMapper';

const operator = getDomainOperator();

const design = operator.design;

const base = {
  title: {
    backgroundColor: design.primaryColor,
    height: "64px",
    width: '100%',
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  },
  titleText: {
    color: "white",
    fontSize: 20,
    fontWeight: 550
  },
  exitButton: {
    backgroundColor: "#FF085D",
    width: 22,
    height: 22,
    position: "absolute",
    paddingBottom: 5,
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    zIndex: 9999,
  },
  exitButtonText: {
    color: "white",
    fontWeight: 'bold',
    fontSize: 15
  },
  listContainer: {
    maxHeight: '400px',
    overflow: 'auto'
  }
};

export const DropDownDialog = (props) => {
  const { items } = props;
  if (!items) return null;

  return (
    <Dialog
      onClose={() => {
        props.onClose()
       
      }}
      aria-labelledby="dialog-title"
      open={props.open}
      
      PaperProps={{
        style: {
          margin: 0,
          width: '90%',
          maxWidth: '400px',
          borderRadius: 1
        },
      }}

      style={{ maxHeight: 500 }}>
      <View style={styles.title}>
        <TouchableOpacity style={styles.exitButton}
          onPress={() => props.onClose()}>
          <Text style={styles.exitButtonText}>x</Text></TouchableOpacity>
        <Text style={styles.titleText}>{props.title ? props.title : ""}</Text>

      </View>

      <List style={styles.listContainer}>
        {items.map((item, index) => (
          <ListItem
            style={props.selected && props.selected.playerId == item.playerId
              ? {backgroundColor: design.secondaryColors}
              : null
            }
            button
            onClick={(event) => {
              props.onClose(item)
            }}
            key={index}
          >
            {item.image && (
              <ListItemIcon>
                <Image style={{width: 30, height: 30, borderRadius: 15}} source={BASE_URL + item.image.url} />
              </ListItemIcon>
            )}

            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default DropDownDialog;

const styles = createStyles(base);