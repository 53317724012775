
import { SET_LEAGUE_RANKING } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';


export const getRoomRanking = roomId => {
  return (dispatch, getState) => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
    })
    .then(token => {
        const page = getState().leaguesRankings.currentPage + 1;
        return fetch(BASE_URL + '/palms/rooms/rankings?page='+page, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      dispatch(setLeagueRanking(res.data, roomId))
    });
  };
}

export const setLeagueRanking = (leagueRanking, id) => {
  return {
    type: SET_LEAGUE_RANKING,
    leagueRanking,
    id
  };
};