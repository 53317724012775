import * as React from "react";

function SvgForm(props) {
  return (
    <svg viewBox="0 0 16.01 13.459" {...props}>
      <path
        d="M13.36 3.5a3.2 3.2 0 01-.954 1.987c-.485.561-1 1.094-1.546 1.68a3.766 3.766 0 00-3.834-1.793c-.261-2.524-2.121-3.22-4.022-3.1A3.311 3.311 0 00.087 5.04a3.362 3.362 0 002.073 4 1.106 1.106 0 01.635.66 5.615 5.615 0 003.345 3.52 7.056 7.056 0 003.445.047 4.166 4.166 0 011.539.093 1.4 1.4 0 001.222-.193 7.17 7.17 0 002.823-3.97 19.9 19.9 0 00.843-5.583A3.66 3.66 0 0013.73.183a1.542 1.542 0 00-1.672.143 10.634 10.634 0 00-.924.7c-.793.7-.676 1.16-.185 1.8a4.038 4.038 0 001.142.918c.444.277.856.043 1.269-.244z"
        fill="#bebebe"
      />
    </svg>
  );
}

export default SvgForm;
