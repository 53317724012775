import { 
  SET_ROUNDS, 
  SET_CURRENT_ROUND_ID
} from "../actions/actionTypes";
 
const initialState = {
    rounds: null,
    currentRoundId: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_ROUNDS:
      return {
        ...state,
        rounds: action.rounds
      };

    case SET_CURRENT_ROUND_ID:
      return {
        ...state,
        currentRoundId: action.roundId
      };

    default:
      return state;
  }
};

export default reducer;