import { GET_USER_DASHBOARD, GET_LEADERBOARD, SET_SELECTED_ITEMS, DELETE_SELECTED_ITEMS, LAST_LEADERBOARD_ID, GET_ROUND_SLIDER_INFO } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setError } from '../../Common/actions/errors';
import { uiStartLoading, uiStopLoading, leaderBoardStartLoading, leaderBoardStopLoading } from "../../Common/actions/ui";
import { showConfirmPopup } from "../../ConfirmPopup/actions/confirmPopup"
import * as translations from '../../Common/utils/translations';

export const getUserDashboard = (status, id) => {
  return dispatch => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading())
        console.log('No auth token exists!');

      })
      .then(token => {
        var query = `?status=${status}`;
        if (id) {
          query += `&sub_tournament_id=${id}`
        }
        return fetch(BASE_URL + `/palms/dashboard${query}`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        localStorage.removeItem("changeGameFilter")
        dispatch(uiStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setDashboard(res.data))
        }
      });
  };
}

export const setDashboard = data => {
  return {
    type: GET_USER_DASHBOARD,
    data
  };
};

export const getLeaderboard = (page, name, id, sortName, sortType) => {
  return (dispatch, getState) => {
    dispatch(leaderBoardStartLoading())
    dispatch({ type: LAST_LEADERBOARD_ID, id })
    dispatch(authGetToken())
      .catch(err => {
        dispatch(leaderBoardStopLoading())
        console.log('No auth token exists!');

      })
      .then(token => {
        let nameQuerry = "";
        if (name) {
          nameQuerry = `&name=${name}`
        }
        let sortNameQuerry = '';
        if (sortName) {
          sortNameQuerry = `&sortBy=${sortName}`
        };
        let sortTypeQuerry = ``;
        if (sortType) {
          sortTypeQuerry = `&sort=${sortType}`;
        }
        return fetch(BASE_URL + `/leaderBoard/subTournament/${id}?page=${page}${nameQuerry}${sortNameQuerry}${sortTypeQuerry}`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(leaderBoardStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(leaderBoardStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          if (getState().dashboard.lastLeaderboadId == res.data.sub_tournament_id) {
            res.data.data = res.data.data.sort((a, b) => +b.points - (+a.points))
            dispatch(setLeaderBoard(res.data))
          }
        }
      });
  };
}

export const getRoundSliderInfo = (subtournamenId, userTeamId) => {
  return (dispatch, getState) => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading())
        console.log('No auth token exists!');

      })
      .then(token => {
        return fetch(BASE_URL + `/gameRounds/subTournament/${subtournamenId}/userTeam/${userTeamId}`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setRoundSliderData(res.data));
        }
      });
  };
}

export const setRoundSliderData = (data) => {
    return {
      type: GET_ROUND_SLIDER_INFO,
      data
    }
}

export const setLeaderBoard = data => {
  return {
    type: GET_LEADERBOARD,
    data
  };
};

export const setSelectedItems = (selectedGame, selectedTeam) => {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_ITEMS,
      selectedGame,
      selectedTeam

    })
  }
}

export const deleteSelectedItems = () => {
  return dispatch => { dispatch({ type: DELETE_SELECTED_ITEMS }) }
}