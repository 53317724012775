import React, { Component } from "react";
import moment from "moment";
import { Text } from "react-native";

class Timer extends Component {

    state = {
        hours: 0,
        mins: 0,
        secs: 0
    }

    componentDidMount() {
        this.setState({ eventDate: moment.duration().add({ seconds: this.props.time }) });
        this.updateTimer();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.time != this.props.time) {
            this.setState({ eventDate: moment.duration().add({ seconds: nextProps.time }) });
        }
    }

    addZeroIfNeeded = number => {
        if (number < 10) {
            number = `0${number}`;
        }
        return number;
    }

    updateTimer = () => {
        const x = setInterval(() => {
            let { eventDate } = this.state
            if (!eventDate) return;
            if (eventDate <= 0) {
                if (this.props.onTimerEnded) {
                    this.props.onTimerEnded();
                }
                clearInterval(x)
            } else {
                eventDate = eventDate.subtract(1, "s")
                const hours = this.addZeroIfNeeded((eventDate.days() * 24) + eventDate.hours());
                const mins = this.addZeroIfNeeded(eventDate.minutes())
                const secs = this.addZeroIfNeeded(eventDate.seconds())

                this.setState({
                    hours,
                    mins,
                    secs,
                    eventDate
                })
            }
        }, 1000)

    }

    render() {
        if (!this.state.eventDate) return null;
        const { style } = this.props; 
        const { hours, mins, secs } = this.state
        return (
            <Text style={style}>{`${hours}:${mins}:${secs}`}</Text>
        )
    }
}

export default Timer;