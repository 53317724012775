import { 
  SET_PREVIOUS_TEAM, 
} from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';

export const getTeam = (roundId, roomId, userId) => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
    })
    .then(token => {
        let params = roundId ? "round_id=" + roundId : '';
        if (params.length > 0 && roundId) params += '&';
        params += roomId ? 'room_id=' + roomId : '';
        if (params.length > 0 && userId) params += '&';
        params += userId ? 'user_id=' + userId : '';

        if (params.length > 0) params = "?" + params;
        return fetch(BASE_URL + '/users/points'+params, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      const userTeam = res.data;
      if (roundId) userTeam.roundId = roundId;
  
      if (Array.isArray(userTeam.groups))
        userTeam.groups.forEach(g => {
          if (Array.isArray(g.players))
            g.players.forEach(p => {
              p.iconUrl = BASE_URL + p.iconUrl;
              p.realPlayerId = p.id;
              delete p.teamName;
            });
        });

      if (Array.isArray(userTeam.reserves))
        userTeam.reserves.forEach(p => {
            p.iconUrl = BASE_URL + p.iconUrl;
            p.realPlayerId = p.id;
            delete p.teamName;
        });
      
      dispatch(setTeam(userTeam));
    });
  };
}


export const setTeam = team => {
  return {
    type: SET_PREVIOUS_TEAM,
    team
  };
};