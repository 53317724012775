import { SET_CHAMPIONSHIP_INFO } from "../actions/actionTypes";

const initialState = {
    championshipInfo: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAMPIONSHIP_INFO:
      return {
        ...state,
        championshipInfo: {...action.info}
      };
    default:
      return state;
  }
};

export default reducer;