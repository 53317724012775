import { SET_ROOM_INFO, SET_FOUND_ROOM } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setBudget, getHomeInfo } from '../../Home/actions/home';
import { getTeam } from '../../Team/actions/team';
import { getTeam as getTeamForTransfers } from '../../Transfers/actions/team';
import { setError } from '../../Common/actions/errors';

export const enterRoom = (room, roundId) => {
  return (dispatch, getState) => {
      dispatch(authGetToken())
      .catch(err => {
          console.log('No auth token exists!');
      })
      .then(token => {
          return fetch(BASE_URL + '/palms/rooms/enter', {
              credentials: 'include',
              headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  "Authorization": token
              },
              method: "PUT",
              body: JSON.stringify({room_id: room.id})
          });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {        
        dispatch(setRoomInfo({...room, ...res.data}));
        dispatch(setBudget(res.data.budget));
        dispatch(getTeam());
        dispatch(getTeamForTransfers());
      });
  }
}


export const searchRoom = (bet, maxPlayers) => {
    return dispatch => {
      dispatch(authGetToken())
      .catch(err => {
          console.log('No auth token exists!');
      })
      .then(token => {
          return fetch(BASE_URL + '/palms/rooms', {
              credentials: 'include',
              headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  "Authorization": token
              },
              method: "POST",
              body: JSON.stringify({bet, max_players: maxPlayers})
          });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        res.data.bet = bet;
        dispatch(setFoundRoom(res.data[0]));
      });
    };
  }

  export const joinRoom = id => {
    return (dispatch, getState) => {
      dispatch(authGetToken())
      .catch(err => {
          console.log('No auth token exists!');
      })
      .then(token => {
          return fetch(BASE_URL + '/palms/rooms/join', {
              credentials: 'include',
              headers: {
                  "Content-Type": "application/json; charset=utf-8",
                  "Authorization": token
              },
              method: "PUT",
              body: JSON.stringify({room_id: id})
          });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .catch(err => console.log(err))
      .then(res => {
        if (res.has_error) {
          let message = res.message;
          if (res.message.indexOf('PalmsBetError') != -1) {
             message = message.slice('PalmsBetError : '.length);
             message = JSON.parse(message).status_descr;
          }
          dispatch(setError('PalmsBet грешка: ' + message));
          return;
        }
        res.data.id = res.data.room_id;
        const room = {...res.data, ...getState().room.foundRoom};
        dispatch(setBudget(room.budget));
        dispatch(setRoomInfo(room));
        // load team and refresh all rooms
        dispatch(getTeam());
        dispatch(getHomeInfo(getState().rounds.currentRoundId));
      });
    };

  }
  
  export const setFoundRoom = room => {
    return {
      type: SET_FOUND_ROOM,
      room
    };
  };

  export const setRoomInfo = (info, roundId) => {
    return {
      type: SET_ROOM_INFO,
      info
    };
  };