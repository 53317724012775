import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import InformationDialog from '../../HomeTournaments/components/InformationDialog';
import { Icon } from './Icon';
import * as translations from '../utils/translations';

export const IconInformationDialog = (props) => {
	const [open, setOpen] = React.useState(false);
	return (
		<React.Fragment>
			<InformationDialog
				title={translations.tKey("str_legend_information_dialog_title")}
				open={open}
				showIcon={false}
				showConfirmButton={false}
				shouldClearFixtures={false}
				onClose={() => setOpen(false)}
			>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="info" />
					</View>

					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_stats")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="number" />
					</View>
					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_number")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="player" />
					</View>
					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_player")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="user" />
					</View>
					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_user")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="difficulty" />
					</View>
					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_difficulty")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="form" />
					</View>
					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_form")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="prizePool" />
					</View>
					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_prize_pool")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="points" />
					</View>
					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_points")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="doublePoints" />
					</View>
					<Text style={styles.legendText}>{props.isSeasonal ? translations.tKey("str_legend_information_dialog_round_points") : translations.tKey("str_legend_information_dialog_avg_points")}</Text>
				</View>
				<View style={styles.legendRow}>
					<View style={styles.iconContainer}>
						<Icon icon="prize" />
					</View>
					<Text style={styles.legendText}>{translations.tKey("str_legend_information_dialog_prize")}</Text>
				</View>
			</InformationDialog>

			<div onClick={() => setOpen(true)} style={{ ...props.IconStyles, cursor: 'pointer'}}>
				<Icon icon="legend" size={23} />
			</div>
		</React.Fragment>
	);
};

IconInformationDialog.propTypes = {
	IconStyles: PropTypes.object
};

const styles = StyleSheet.create({
	legendRow: {
		flexDirection: 'row',
		width: '100%',
		marginVertical: 5
	},

	iconContainer: {
		width: '10%'
	},

	legendText: {
		width: '90%'
	}
});