import { SET_REAL_TEAMS } from "../actions/actionTypes";

const initialState = {
  realTeams: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REAL_TEAMS:
      return {
        ...state,
        realTeams: action.teams
      };
    default:
      return state;
  }
};

export default reducer;