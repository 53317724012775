import React from "react";
import {
    StyleSheet,
    TouchableOpacity,
    Text,
    View,
    Image
} from "react-native";
import PopupDialog from '../../Common/components/PopupDialog';
import okIcon from '../../assets/approve-transfer.svg.png';
import cancelIcon from '../../assets/cancel-transfer.svg.png';

const redirectPopup = props => {
    return (
        <PopupDialog isVisible={true} title={"Уайлдкард"} style={{ width: '100%', height: '100%'}}>
            <View style={styles.container}>
                <Text style={{ fontSize: 13, textAlign: 'justify', marginBottom: 20, paddingLeft: 10, paddingRight: 10 }}>
                    Сигурни ли сте, че искате да активирате своя Уайлдкард? Уайлдкард дава правото на неограничен брой безплатни трансфери и веднъж активиран, не може да бъде отменен.
                </Text>

                <View style={styles.buttons}>
                    <TouchableOpacity
                        style={[styles.buttonContainer, { borderRightWidth: 1, borderColor: 'white' }]}
                        onPress={props.onDismiss}>
                        <Image source={cancelIcon} style={styles.icon} />
                        <Text style={styles.buttonText}>
                            Откажи
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.buttonContainer}
                        onPress={props.onConfirm}>
                        <Text style={styles.buttonText}>
                            Потвърди
                        </Text>
                        <Image source={okIcon} style={styles.icon} />
                    </TouchableOpacity>
                </View>
            </View>
        </PopupDialog>
    );
};

export default redirectPopup;

const styles = StyleSheet.create({
    container: {
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonContainer: {
        width: '50%',
        height: 40,
        paddingHorizontal: 5,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    buttons: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'black',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        paddingVertical: 10,
    },
    buttonText: {
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    },
    icon: {
        width: 20,
        height: 20,
        marginHorizontal: 8
    },
});
