import {
  UI_START_LOADING,
  UI_STOP_LOADING,
  LEADERBOARD_START_LOADING,
  LEADERBOARD_STOP_LOADING,
  TEAM_START_LOADING,
  TEAM_STOP_LOADING
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  isLeaderBoardLoading: false,
  isTeamLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case UI_STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case LEADERBOARD_START_LOADING:
      return {
        ...state,
        isLeaderBoardLoading: true
      };
    case LEADERBOARD_STOP_LOADING:
      return {
        ...state,
        isLeaderBoardLoading: false
      };
      case TEAM_START_LOADING:
      return {
        ...state,
        isTeamLoading: true
      };
    case TEAM_STOP_LOADING:
      return {
        ...state,
        isTeamLoading: false
      };
    default:
      return state;
  }
};

export default reducer;