import React from 'react';
import {
    View,
    TouchableOpacity,
    Image,
    Text,
    StyleSheet
} from 'react-native';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';

import teamImage from '../../assets/team_tabbar.png';
import teamImageHovered from '../../assets/team_hover.png';
import transferImage from '../../assets/transfers.png';
import transferImageHovered from '../../assets/transfers_hover.png';
import resultsImage from '../../assets/results.png';
import resultsImageHovered from '../../assets/results_hover.png';
import statsImage from '../../assets/stats.png';
import statsImageHovered from '../../assets/stats_hover.png';

import { connect } from 'react-redux';
import { enterRoom } from '../../SearchRoom/actions/rooms';
import RoomsPopup from '../../Common/components/RoomsPopup';

class TopBar extends React.Component {

    state = {
        activeIndex: 0,
        roomDialogOpened: false        
    }

    componentDidMount() {        
        if (this.props.activeIndex) {
            this.setState({activeIndex: this.props.activeIndex})
        }
    }

    changePage = i => {
        if (i === this.state.activeIndex) return;
        this.setState({activeIndex : i});
        if (!this.props.navigation) return;
        if (i == 1) {
            this.props.navigation.navigate('Rankings', {roomId: this.props.roomInfo.id});
        } else {
            this.props.navigation.goBack();
        }
    } 

    changeRoom = room => {
        this.setState({roomDialogOpened: false});
        if (!room.id || this.props.roomInfo.id === room.id) return;
        this.props.enterRoom(room);                
        if (room.isFinished)
            this.props.navigation.navigate('Points');
        else
            this.props.navigation.navigate('Team');
    }

    render() {
        const { activeIndex } = this.state;
        if (!this.props.roomInfo) {
            if (this.props.navigation) 
                this.props.navigation.navigate('Home');
            return null;
        }        
        return (
        <View style={styles.tabsContainer}>
            <RoomsPopup open={this.state.roomDialogOpened} onClose={this.changeRoom} />

            <View style={[styles.roomTabContainer, this.state.activeIndex === 0 ? {backgroundColor: '#11314B'} : null]}>
                <TouchableOpacity 
                    style={styles.tab}
                    onPress={() => this.changePage(0)}>
                    <TouchableOpacity onPress={() => this.setState({roomDialogOpened: true})} 
                                        style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Text style={{color: 'white', width: '100%', textAlign: 'center', fontSize: 19}}>
                            {this.props.roomInfo.title ? this.props.roomInfo.title.toUpperCase() : ''}
                        </Text>
                        <IosArrowDown fontSize="1.5em" style={{fontWeight: 'bold'}} color="white"/>
                    </TouchableOpacity>
                    <Text style={{color: '#C9AA64', textAlign: 'center', fontWeight: 'bold'}}>{`залог - ${this.props.roomInfo.bet} лв`}</Text>
                </TouchableOpacity>
            </View>
            <View style={[styles.roomTabContainer, this.state.activeIndex === 1 ? {backgroundColor: '#11314B'} : null]}>
                <TouchableOpacity 
                    style={styles.tab}
                    onPress={() => this.changePage(1)}>
                    <Text style={{color: 'white', textAlign: 'center', fontSize: 19}}>КЛАСИРАНЕ</Text>
                </TouchableOpacity>
            </View>
        </View>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        roomInfo: state.room.roomInfo
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    enterRoom: room => dispatch(enterRoom(room)),
  }
}
  
  
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);

const styles = StyleSheet.create({
   tabsContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'black',
  },
  roomTabContainer: {
    height: 70,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#306985'
  },
  tab: {
    height: 70,
    width: '100%', 
    padding: 5,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  }
});