import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Text, TouchableOpacity, View } from "react-native";
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';

export const SchemeDialog = (props) => {
  const { schemes } = props;

  const [open, setOpen] = React.useState(false);

  if (!schemes) return null;

  return (
   
    <React.Fragment>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="dialog-title"
        open={open}
        style={{ maxHeight: 2000 }}
      >
        <DialogTitle>
          Изберете схема
        </DialogTitle>

        <List>
          {schemes.map(scheme => (
            <ListItem
              button
              key={scheme.key}
              onClick={() => {
                setOpen(false);
                typeof props.onClose === 'function'
                  && props.onClose(scheme);
              }}
            >
              <ListItemText primary={scheme.label} />
            </ListItem>
          ))}
        </List>
      </Dialog>
      <React.Fragment>
        {props.disabled ? 
          <View style={styles.schemeContainer} >
           <Text style={styles.headerText}> {props.userTeam.currentScheme} </Text>
           <IosArrowDown fontSize="1.5em" style={{ fontWeight: 'bold' }} color="white"/>
         </View>
        :
        <TouchableOpacity style={styles.schemeContainer} onPress={(e) => {
           e.stopPropagation();
           e.preventDefault();
          setOpen(!open)
        
        }}>
          <Text style={styles.headerText}> {props.userTeam.currentScheme} </Text>
          <IosArrowDown fontSize="1.5em" style={{ fontWeight: 'bold' }} color="white"/>
        </TouchableOpacity>
      }
      </React.Fragment>
    </React.Fragment>
  )
}

const styles = {
  headerText: {
    fontSize: 17,
    color: 'white'
  },
  schemeContainer: {
    flexDirection: 'row',
    width: 80
  },
};
