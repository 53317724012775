import * as React from "react";

function SvgPointspp(props) {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <defs>
        <style>{".pointspp_svg__a{fill:#bebebe}"}</style>
      </defs>
      <path
        className="pointspp_svg__a"
        d="M4.12 6.718a.251.251 0 00-.06-.1L2.719 5.311a.248.248 0 01.138-.424l1.853-.269a.248.248 0 00.187-.136l.829-1.679a.249.249 0 01.446 0l.625 1.266a6.748 6.748 0 014.5-.707 5.945 5.945 0 10-7.937 7.937 6.727 6.727 0 01.756-4.584z"
      />
      <path
        className="pointspp_svg__a"
        d="M10.05 4.101a5.949 5.949 0 105.949 5.949 5.949 5.949 0 00-5.949-5.949zm3.229 5.314l-1.341 1.307a.249.249 0 00-.072.22l.317 1.845a.249.249 0 01-.361.262l-1.657-.871a.249.249 0 00-.232 0l-1.657.871a.249.249 0 01-.361-.262l.317-1.845a.249.249 0 00-.072-.22L6.819 9.415a.248.248 0 01.138-.424l1.853-.269a.248.248 0 00.187-.136l.829-1.679a.249.249 0 01.446 0l.829 1.679a.248.248 0 00.187.136l1.853.269a.248.248 0 01.139.424z"
      />
    </svg>
  );
}

export default SvgPointspp;
