import { SET_REAL_TEAMS } from "./actionTypes";
import { BASE_URL } from '../config/config';
import { uiStartLoading, uiStopLoading } from "./ui";
import { authGetToken } from '../../Common/actions/token';

export const getAllTeams = (id) => {
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
       
        dispatch(uiStopLoading());
      })
      .then(token => {
        return fetch(BASE_URL + `/championships/${id}/teams`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        console.log(err);
        alert("Get all teams failed, please try again!");
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(res => {
        res.data.forEach(team => {
          team.logo.url = BASE_URL + team.logo.url;
        })
        dispatch(setRealTeams(res.data));
      })
  };
}


export const setRealTeams = teams => {
  return {
    type: SET_REAL_TEAMS,
    teams
  };
};