import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { createStyles, maxWidth } from 'react-native-media-queries';
import backArrow from '../../assets/images/back_arrow_pink.svg';
import search from '../../assets/images/search.svg';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import DropDownDialog from '../../HomeTournaments/components/DropDownDialog';
import Pagination from '../../Common/components/Pagination';
import * as translations from '../../Common/utils/translations';
import downArrow from '../../assets/images/down_arrow.svg';
import injuredIcon from '../../assets/injured-player.svg.png';
import banIcon from '../../assets/banned-player.svg.png';
import transferedIcon from '../../assets/transfered-player.svg.png';
import {
  getAvailablePlayers,
  setGroup,
  transferPlayer,
  setRemainingBudget
} from '../../Team/actions/team';
import TrottledSearchField from '../../Common/components/TrottledSearchField';
import { getTournamentForTransfer, clearTournamentForTransfer } from '../actions/enterTournament';
import LoadingScreen from '../../Common/components/LoadingScreen';
import './slider.scss';
import { Icon } from '../../Common/components/Icon';
import { IconInformationDialog } from '../../Common/components/IconInformationDialog';
import { logTrace } from '../../Common/utils/logger';
import { isMobile } from 'react-device-detect';
import { getDomainOperator } from '../../Common/utils/domainMapper';

const GOALKEPER = 1;
const DEFENDER = 2;
const MIDFIELDER = 3;
const ATTACKER = 4;
const GKP_MIN = 1;
const DEF_MIN = 3;
const MID_MIN = 2;
const FWD_MIN = 1;

class SelectPlayers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSeasonalGame: false,
      playersSelected: 0,
      team: [],
      teamsDataFilterOpen: false,
      playerStatus: {
        id: 1,
        value: 'expected',
        name: translations.tKey('str_select_players_expected')
      },
      team_id: null,
      max_price: null,
      group: null,
      name: null,
      position_id: null,
      playerTeams: [],
      availablePlayers: [],
      currentSort: {
        name: "price",
        sort: "desc"
      },
      sortCriterias: [
        {
          name: 'diff',
          sort: 'desc'
        },
        {
          name: 'name',
          sort: 'desc'
        },
        {
          name: 'points',
          sort: 'desc'
        },
        {
          name: 'shape',
          sort: 'desc'
        },
        {
          name: 'gw_price',
          sort: 'asc'
        }
      ]
    };
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.homeInfo) {
      this.props.getTournamentForTransfer(this.props.navigation.getParam('id', '0'));
      return;
    }
    const group = JSON.parse(sessionStorage.getItem('currentGroup'));

    const tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    this.setState({ isSeasonalGame: this.props.fixtures?.isSeasonal || (tournament && tournament.is_seasonal_game) })
    if (!tournament) {
      this.props.getTournamentForTransfer(this.props.navigation.getParam('id', '0'));
      return;
    }
    this.props.getAvailablePlayers({
      page: 1,
      sub_tournament_id: this.props.navigation.getParam('subId', '0'),
      position_id: group.groupId,
      status: 'expected'
    });

    if (this.props.userTeam && this.props.userTeam.groups) {
      const playerTeams = [];
      this.props.userTeam.groups.forEach(group => {
        group.players.forEach(player => {
          if (player) {
            var playerTeam = playerTeams.find(t => t.teamId == player.teamId);
            if (playerTeam) {
              playerTeam.players += 1;
            } else {
              playerTeams.push({ teamId: player.teamId, players: 1 });
            }
          }
        });
      });
      this.setState({ playerTeams });
    }

    this.setState({
      team: this.props.userTeam.groups,
      group,
      position_id: group.groupId,
      max_price: tournament.player_max_price
    });
    this.calculateRemainingPlayers(this.props.userTeam.groups, tournament);

  }

  componentWillReceiveProps(nextProps) {

    if (this.props.userTeam != nextProps.userTeam) {
      if (this.shouldReturnToPitchView() && !JSON.parse(sessionStorage.getItem('transfer'))) {
        localStorage.removeItem('addNewTeamHome');
        this.props.navigation.goBack();
        return;
      } else if (JSON.parse(sessionStorage.getItem('transfer'))) {
        sessionStorage.removeItem('transfer');
        sessionStorage.removeItem('transferTeamId');
      }
      let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
      if (!tournament) {
        tournament = this.props.tournamentForTransfer;
      }
      this.setState({ team: nextProps.userTeam.groups });
      this.calculateRemainingPlayers(this.props.userTeam.groups, tournament);
      const playerTeams = [];
      nextProps.userTeam.groups.forEach(group => {
        group.players.forEach(player => {
          if (player) {
            let playerTeam = playerTeams.find(t => t.teamId == player.teamId);
            if (playerTeam) {
              playerTeam.players += 1;
            } else {
              playerTeams.push({ teamId: player.teamId, players: 1 });
            }
          }
        });
      });
      this.setState({ playerTeams });
    }
    if (this.props.tournamentForTransfer != nextProps.tournamentForTransfer) {
      const group = JSON.parse(sessionStorage.getItem('currentGroup'));
      this.props.getAvailablePlayers({
        page: 1,
        sub_tournament_id: this.props.navigation.getParam('subId', '0'),
        position_id: group.groupId,
        status: 'expected'
      });

      this.setState({
        team: this.props.userTeam.groups,
        group,
        position_id: group.groupId,
        max_price: nextProps.tournamentForTransfer.player_max_price
      });
      this.calculateRemainingPlayers(this.props.userTeam.groups, nextProps.tournamentForTransfer);
    }
    if (this.props.availablePlayers != nextProps.availablePlayers) {
      if (!this.state.currentSort) {
        this.setState({ availablePlayers: nextProps.availablePlayers });
        return;
      }
      this.setState({
        availablePlayers: {
          ...nextProps.availablePlayers,
          data: nextProps.availablePlayers.data
        },
      });




    }
  }

  getMorePlayers = (name, value) => {
    const data = { ...this.state };

    data[name] = value;

    if (name !== 'status') {
      data.status = this.state.playerStatus.value;
    }

    data.sub_tournament_id = this.props.navigation.getParam('subId', '0');
    data.position_id = data.position_id === -1
      ? null
      : data.position_id;
    const currentSort = this.state.currentSort;
    let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }
    if (currentSort && currentSort.name == "gw_price") {
      currentSort.sortBy = "price"
    }
    data.sortBy = currentSort ? currentSort.name : null;
    data.sort = currentSort ? currentSort.sort : null;
    this.props.getAvailablePlayers(data);
  };

  calculateRemainingPlayers = (team, tournament) => {
    var playersSelected = 0;
    team.forEach(group => {
      group.players.forEach(player => {
        if (player) {
          playersSelected += 1;
        }
      });
    });
    const transferPlayer = JSON.parse(sessionStorage.getItem('transfer'));
    if (transferPlayer) {
      playersSelected -= 1;
    }
    this.setState({ playersSelected });
  };

  getDiffBoxColor = (diff) => {
    const style = {
      paddingVertical: '3px',
      paddingHorizontal: '10px'
    };
    switch (diff) {
      case 1: {
        style.backgroundColor = '#02894E';
        break;
      }
      case 2: {
        style.backgroundColor = design.secondaryColor;
        break;
      }
      case 3: {
        style.backgroundColor = '#EBFF00';
        break;
      }
      case 4: {
        style.backgroundColor = '#FF085D';
        break;
      }
      case 5: {
        style.backgroundColor = '#A50039';
        break;
      }
    }
    return style;
  };

  getPositionLabel = (positionId) => {
    const labelStyle = {
      fontSize: 8,
      fontWeight: 'bold',
      paddingVertical: 2,
      paddingHorizontal: 3,
      position: 'absolute',
      top: 18
    };
    var text = '';
    switch (positionId) {
      case GOALKEPER: {
        labelStyle.backgroundColor = '#EBFF00';
        text = translations.tKey('str_select_players_gkp_tab');
        break;
      }
      case DEFENDER: {
        labelStyle.backgroundColor = '#01FF87';
        text = translations.tKey('str_select_players_def_tab');
        break;
      }
      case MIDFIELDER: {
        labelStyle.backgroundColor = '#00F0FF';
        text = translations.tKey('str_select_players_mid_tab');
        break;
      }
      case ATTACKER: {
        labelStyle.backgroundColor = '#FF085D';
        labelStyle.color = 'white';
        text = translations.tKey('str_select_players_fwd_tab');
        break;
      }
    }
    return <Text style={labelStyle}>{text}</Text>;
  };


  calculateProgressRemainingBudget = (tournament) => {
    var percent = (this.props.userTeam.remainingBudget / tournament.team_budget) * 100;
    return {
      width: `${percent}%`,
      height: '100%',
      backgroundColor: '#00F0FD',
    };
  };

  calculateProgressPlayersSelected = (tournament) => {
    let titularLimit = tournament.titulars_limit;

    if (this.state.isSeasonalGame) {
      titularLimit += 4;
    }

    var percent = (this.state.playersSelected / titularLimit) * 100;
    return {
      width: `${percent}%`,
      height: '100%',
      backgroundColor: '#00F0FD',
    };
  };

  openTeamsDataFilter = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      teamsDataFilterOpen: true
    });
  };

  closeTeamsDataFilter = (item) => {
    this.setState({
      teamsDataFilterOpen: false,
      team_id: item
        ? item.id
        : this.state.team_id
    });

    if (item) {
      this.getMorePlayers('team_id', item.id);
    }
  };

  getMaximumNumberOfPlayersInGroup = (positionId, tournament) => {
    switch (positionId) {
      case GOALKEPER: {
        return tournament.goalkeepers_limit;
      }
      case DEFENDER: {
        return tournament.defenders_limit;
      }
      case MIDFIELDER: {
        return tournament.midfielders_limit;
      }
      case ATTACKER: {
        return tournament.attackers_limit;
      }
    }
  };

  playerPressed = (player) => {
    localStorage.setItem('playerPressed', true);
    if (this.shouldGrayOutRow(player)) return;
    let group = this.props.userTeam.groups.find(t => t.groupId == player.groupId);
    const transfer = JSON.parse(sessionStorage.getItem('transfer'));
    if (transfer) {
      let teamLimit;
      let teamId = JSON.parse(sessionStorage.getItem('transferTeamId'));
      if (!teamId) {
        let dashboard = JSON.parse(localStorage.getItem('Dashboard'));
        if (dashboard) {
          teamId = dashboard.team.user_team_id;
        }
      }
      if (this.props.tournamentForTransfer) {
        teamLimit = this.props.tournamentForTransfer.players_per_team_limit;
      } else {
        let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
        teamLimit = tournament.players_per_team_limit;
      }
      let count = 0;

      this.props.userTeam.groups.forEach(g => {
        if (g.groupId === group.groupId) {
          group.players.forEach(p => {
            if (p && p.teamId === player.teamId) {
              count++;
            }
          });
        } else {
          g.players.forEach(p => {
            if (p && p.teamId === player.teamId) {
              count++;
            }
          });
        }
      });

      const oldPlayerGroup = this.props.userTeam.groups.find(t => t.groupId == transfer.groupId);
      const oldPlayerIndex = oldPlayerGroup.players.findIndex(u => u && u.playerId == transfer.playerId);
      const oldPlayer = oldPlayerGroup.players[oldPlayerIndex];
      if (oldPlayer.teamId == player.teamId) {
        count--;
      }

      if (count >= teamLimit) {
        alert('Максимум ' + teamLimit + "-ма играчи от един и същ отбор!");
        return;
      }
      if (!teamId) {
        const oldPlayerGroup = this.props.userTeam.groups.find(t => t.groupId == transfer.groupId);
        const oldPlayerIndex = oldPlayerGroup.players.findIndex(u => u && u.playerId == transfer.playerId);
        const oldPlayer = oldPlayerGroup.players[oldPlayerIndex];
        if (oldPlayer) {
          if (oldPlayer.isCaptain) {
            player.isCaptain = true;
          }
          if (oldPlayer.isViceCaptain) {
            player.isViceCaptain = true;
          }
          oldPlayerGroup.players[oldPlayerIndex] = null;
          oldPlayerGroup.players.splice(oldPlayerIndex, 1);
        }
        group.players.push(player);
        this.props.setGroup(group);
        this.props.setGroup(oldPlayerGroup);
        localStorage.removeItem('addNewTeamHome');
        if (this.state.isSeasonalGame) {
          this.props.setRemainingBudget(Math.abs(this.props.userTeam.remainingBudget - player.price))
        } else {
          this.props.setRemainingBudget(Math.abs(this.props.userTeam.remainingBudget - player.gw_price))
        }

        this.props.navigation.goBack();
      } else {
        // this.props.transferPlayer(teamId, this.props.navigation.getParam('subId', '0'),
        //   {
        //     oldPlayerId: transfer.playerId,
        //     newPlayerId: player.playerId
        //   });
        // localStorage.removeItem('addNewTeamHome');

        // this.props.navigation.goBack();

        const oldPlayerGroup = this.props.userTeam.groups.find(t => t.groupId == transfer.groupId);
        const oldPlayerIndex = oldPlayerGroup.players.findIndex(u => u && u.playerId == transfer.playerId);
        const oldPlayer = oldPlayerGroup.players[oldPlayerIndex];
        if (oldPlayer) {
          if (oldPlayer.isCaptain) {
            player.isCaptain = true;
          }
          if (oldPlayer.isViceCaptain) {
            player.isViceCaptain = true;
          }

          oldPlayerGroup.players[oldPlayerIndex] = null;
          oldPlayerGroup.players.splice(oldPlayerIndex, 1);
        }
        group.players.push(player);
        this.props.setGroup(group);
        this.props.setGroup(oldPlayerGroup);
        localStorage.removeItem('addNewTeamHome');
       
        if (this.state.isSeasonalGame) {
          this.props.setRemainingBudget(Math.abs(this.props.userTeam.remainingBudget - player.price))
        } else {
          this.props.setRemainingBudget(Math.abs(this.props.userTeam.remainingBudget - player.gw_price))
        }
        this.props.navigation.goBack();
      }
    } else {
      let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
      if (!tournament) {
        tournament = this.props.tournamentForTransfer;
      }
      let titularLimit = tournament.titulars_limit;

      if (this.state.isSeasonalGame) {
        titularLimit += 4;
      }

      // check if player is already selected and if so remove him from the group
      const playerIndex = group.players.findIndex(u => u && u.playerId == player.playerId);
      if (playerIndex >= 0) {
        group.players[playerIndex] = null;
        this.props.setGroup(group);
        if (this.state.isSeasonalGame) {
          this.props.setRemainingBudget(Math.abs(this.props.userTeam.remainingBudget + player.price))
        } else {
          this.props.setRemainingBudget(Math.abs(this.props.userTeam.remainingBudget + player.gw_price))
        }
      } else {
        let count = 0;
        this.props.userTeam.groups.forEach(g => {
          if (g.groupId === group.groupId) {
            group.players.forEach(p => {
              if (p && p.teamId === player.teamId) count++;
            });
          } else {
            g.players.forEach(p => {
              if (p && p.teamId === player.teamId) count++;
            });
          }
        });

        if (count >= tournament.players_per_team_limit) {
          alert('Максимум ' + tournament.players_per_team_limit + "-ма играчи от един и същ отбор!");
          return;
        }

        // if player is not selected check if there is enough space and if the budget allows
        if (group.players.filter(t => !(!t)).length == this.getMaximumNumberOfPlayersInGroup(player.groupId, tournament)) {
          console.info('Not enough space in this group');

          return;
        } else if ((+this.props.userTeam.remainingBudget.toFixed(1) - (tournament.is_seasonal_game ? player.price : player.gw_price)) < 0) {
          console.info('Not enough budget');

          return;
        } else if (titularLimit == this.props.userTeam.groups.reduce((accumulator, current) => {
          return accumulator.concat(current.players.filter(t => t != null));
        }, []).length) {
          // maximum number of players in the team is reached
          return;
        }
        group.players[group.players.findIndex(t => !t)] = player;

        if (this.state.isSeasonalGame) {
          this.props.setRemainingBudget(Math.abs(this.props.userTeam.remainingBudget - player.price))
        } else {
          this.props.setRemainingBudget(Math.abs(this.props.userTeam.remainingBudget - player.gw_price))
        }

        logTrace("Setting group", group);
        this.props.setGroup(group);
      }
    }
  };

  shouldReturnToPitchView = () => {
    var shouldReturn = true;
    this.props.userTeam.groups.forEach(group => {
      group.players.forEach(player => {
        if (!player) {
          shouldReturn = false;
        }
      });
    });
    let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }

    let titularLimit = tournament.titulars_limit;

    if (this.state.isSeasonalGame) {
      titularLimit += 4;
    }


    if (titularLimit == this.props.userTeam.groups.reduce((accumulator, current) => {
      return accumulator.concat(current.players.filter(t => t != null));
    }, []).length) {
      shouldReturn = true;
    }
    return shouldReturn;
  };

  isPlayerSelected = (player) => {
  
    if (!player) return false;
    let group = this.props.userTeam.groups.find(t => t.groupId == player.groupId);
    return group.players.findIndex(u => u && u.playerId == player.playerId) >= 0;
  };

  shouldGrayOutRow = (player) => {
    if (!player) return false;
    let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament) {
      tournament = this.props.tournamentForTransfer;
    }

    const group = this.props.userTeam.groups.find(t => t.groupId == player.groupId);
    const transfer = JSON.parse(sessionStorage.getItem('transfer'));


    if (this.isPlayerSelected(player)) {
      return false;
    }
    // if the players in the group are filled
    if (transfer) {
      if (group.players.filter(t => !(!t)).length == this.getMaximumNumberOfPlayersInGroup(player.groupId, tournament) && (transfer.groupId != player.groupId)) {
        return true;
      }
    } else {
      if (group.players.filter(t => !(!t)).length == this.getMaximumNumberOfPlayersInGroup(player.groupId, tournament)) {
        return true;
      }
    }
    if (tournament.is_seasonal_game) {
      if (+this.props.userTeam?.remainingBudget.toFixed(1) - player.price < 0) {
        return true;
      }

    } else {
      if (+this.props.userTeam?.remainingBudget.toFixed(1) - player.gw_price < 0) {
        return true;
      }
    }

    const currentNumberOfPlayersInTeam = this.props.userTeam.groups.reduce((accumulator, current) => {
      return accumulator.concat(current.players.filter(t => t != null));
    }, []).length;
    // if the limit of players overall is reached

    let titularLimit = tournament.titulars_limit;

    if (this.state.isSeasonalGame) {
      titularLimit += 4;
    }


    if (titularLimit == currentNumberOfPlayersInTeam && !transfer) {
      return true;
    }
    if (titularLimit == 11) {
      //if there are unfilled groups and the players in the team remaining are not enough to fill said groups to atleast the minimum
      if ((transfer && transfer.groupId != player.groupId)) {
        if (titularLimit - currentNumberOfPlayersInTeam <= this.getRemainingMinimumsForGroups() && this.isPlayerGroupFilledToAtleastMinimum(group)) {
          return true;
        }
      } else if (!transfer) {
        if (titularLimit - currentNumberOfPlayersInTeam <= this.getRemainingMinimumsForGroups() && this.isPlayerGroupFilledToAtleastMinimum(group)) {
          return true;
        }
      }

      // trying to transfer a player with another from the same team
      // no need to check the number of players from said team selected
      // because we are swapping a player from the same team and the number will not change
      if (transfer && player.teamId == transfer.teamId) {
        return false;
      } else {
        if (this.areThereMoreThanThreePlayersFromThisTeamSelected(player, tournament)) {
          return true;
        }
      }
    }

    return false;
  };

  areThereMoreThanThreePlayersFromThisTeamSelected = (player, tournament) => {
    const playerTeam = this.state.playerTeams.find(t => t.teamId == player.teamId);

    if (!playerTeam) return false;
    return playerTeam.players >= tournament.players_per_team_limit && player.teamId == playerTeam.teamId;
  };

  isPlayerGroupFilledToAtleastMinimum = (group) => {
    switch (group.groupId) {
      case GOALKEPER: {
        return group.players.filter(player => !(!player)).length >= GKP_MIN;
      }
      case DEFENDER: {
        return group.players.filter(player => !(!player)).length >= DEF_MIN;
      }
      case MIDFIELDER: {
        return group.players.filter(player => !(!player)).length >= MID_MIN;
      }
      case ATTACKER: {
        return group.players.filter(player => !(!player)).length >= FWD_MIN;
      }
    }
  };

  getRemainingMinimumsForGroups = () => {
    var minimumsSum = 0;
    this.props.userTeam.groups.forEach(group => {
      switch (group.groupId) {
        case GOALKEPER: {
          if (group.players.filter(player => !(!player)).length < GKP_MIN) {
            minimumsSum += (GKP_MIN - group.players.filter(player => !(!player)).length);
          }
          break;
        }
        case DEFENDER: {
          if (group.players.filter(player => !(!player)).length < DEF_MIN) {
            minimumsSum += (DEF_MIN - group.players.filter(player => !(!player)).length);
          }
          break;
        }
        case MIDFIELDER: {
          if (group.players.filter(player => !(!player)).length < MID_MIN) {
            minimumsSum += (MID_MIN - group.players.filter(player => !(!player)).length);
          }
          break;
        }
        case ATTACKER: {
          if (group.players.filter(player => !(!player)).length < FWD_MIN) {
            minimumsSum += (FWD_MIN - group.players.filter(player => !(!player)).length);
          }
          break;
        }
      }
    });
    return minimumsSum;
  };

  handleInput = (text) => {
    this.setState({ name: text });
    this.getMorePlayers('player_name', text);
  };

  getPositionButton = (groupId, text) => {
    return (
      <div
        onClick={() => {
          this.setState({
            group: {
              ...this.state.group,
              groupId: groupId
            },

            position_id:
              groupId === -1
                ? null
                : groupId
          });

          this.getMorePlayers('position_id', groupId === -1 ? null : groupId);
        }}
        style={
          this.state.group && this.state.position_id === groupId || (this.state.position_id === null && groupId === -1) ? styles.activeButton : styles.inactiveButton
        }
      >
        <TouchableOpacity>
          <Text style={styles.buttonText}>{text}</Text>
        </TouchableOpacity>
      </div>
    );
  };

  handleSliderChange = (event) => {
    this.setState({
      max_price: event.target.value
    });
  };

  sortBy = (sortCriteria, switchSort) => {
    const currentSort = !this.state.currentSort
      || (this.state.currentSort.name != sortCriteria || this.state.currentSort.sort != this.state.sortCriterias.find(t => t.name == sortCriteria).sort)
      ? { ...this.state.sortCriterias.find(t => t.name == sortCriteria) }
      : this.state.currentSort;
      let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
      if (!tournament) {
        tournament = this.props.tournamentForTransfer;
      }
      if (currentSort && currentSort.name == "gw_price") {
        currentSort.name = "price"
      }
    this.props.getAvailablePlayers({
      page: 1,
      sub_tournament_id: this.props.navigation.getParam('subId', '0'),
      position_id: this.state.position_id,
      status: this.state.playerStatus.value,
      team_id: this.state.team_id,
      max_price: this.state.max_price,
      sortBy: currentSort ? currentSort.name : null,
      sort: currentSort ? currentSort.sort : null
    });
    this.setState({
      currentSort,
      sortCriterias: this.state.sortCriterias.map(t => {
        if (t.name == sortCriteria && switchSort) {
          if (t.sort == 'asc') {
            t.sort = 'desc';
          } else {
            t.sort = 'asc';
          }
        }
        return { ...t };
      })
    });
  };

  cancelTransfer = () => {
    const player = JSON.parse(sessionStorage.getItem('transfer'));
    if (player) {
      this.props.setRemainingBudget(this.props.userTeam.remainingBudget - player.price)
    }

    sessionStorage.removeItem('transfer');
    sessionStorage.removeItem('transferTeamId');
    localStorage.removeItem('addNewTeamHome');

    //this.props.navigation.goBack();
    window.history.back();
    return false;
  }

  render() {
    let tournament = this.props.homeInfo && this.props.homeInfo.find(tournament => tournament.id == this.props.navigation.getParam('id', '0'));
    if (!tournament || (!this.props.userTeam && !this.props.userTeam.totalBudget && !this.props.userTeam.remainingBudget)) {
      tournament = this.props.tournamentForTransfer;
    }
    if (!tournament) return <LoadingScreen />;
    const subgame = tournament.subgames.find(subgame => subgame.id == this.props.navigation.getParam('subId', '0'));
    if (!subgame) return null;
    const dropDownTeamsData = [
      {
        id: null,
        name: translations.tKey('str_select_players_all_teams')
      }
    ];
    tournament.teams.map(team => {
      dropDownTeamsData.push({ id: team.id, name: team.name });
    });

    const dropDownPlayerStatusData = [
      {
        id: 1,
        value: 'expected',
        name: translations.tKey('str_select_players_expected')
      },
      {
        id: 2,
        value: 'all',
        name: translations.tKey('str_select_players_all')
      }
    ];

    return (
      <div style={styles.container}>
        <DropDownDialog
          title={translations.tKey('str_select_players_teams')}
          items={dropDownTeamsData}
          open={this.state.teamsDataFilterOpen}
          onClose={this.closeTeamsDataFilter}
        />
        <div style={styles.backButtonDiv} onClick={this.cancelTransfer}>
          <TouchableOpacity style={styles.backHeader}>

            <Image style={styles.backImage} source={backArrow} />
            <Text style={styles.backText}>{translations.tKey('str_select_players_back_button')}</Text>
          </TouchableOpacity>
        </div>
        <View style={{...styles.stickyTop, width: '100%'}}>
          <View style={styles.progressBar}>
            <View style={this.calculateProgressRemainingBudget(tournament)} />
            <Text style={styles.progressBarLeftText}>{translations.tKey('str_enter_game_remaining_budget')}</Text>
            <View style={styles.progressBarRightTextContainer}>
              <Text style={[styles.entriesText, styles.blackText]}>{this.props.userTeam?.remainingBudget.toFixed(1)}</Text>
              <Text
                style={[styles.entriesTotalText, styles.blackText]}>{` / ${this.props.userTeam?.totalBudget.toFixed(1)}M`}</Text>
            </View>
          </View>

          <View style={styles.progressBar}>
            <View style={this.calculateProgressPlayersSelected(tournament)} />
            <Text style={styles.progressBarLeftText}>{translations.tKey('str_enter_game_players_selected')}</Text>
            <View style={styles.progressBarRightTextContainer}>
              <Text style={[styles.entriesText, styles.blackText]}>{this.state.playersSelected}</Text>
              <Text style={[styles.entriesTotalText, styles.blackText]}>{` / ${this.state.isSeasonalGame ? tournament.titulars_limit + 4 : tournament.titulars_limit}`}</Text>
            </View>
          </View>
        </View>

        <View style={styles.maxPriceContainer}>
          <Text style={styles.maxPriceLabel}>{translations.tKey('str_select_players_max_price')}</Text>
          <Text style={styles.maxPriceValue}>{`${this.state.max_price}M`}</Text>
        </View>
        <div style={{ width: '100%' }}>
          <progress style={{ maxWidth: 'min(450px, 90vw)' }} max={tournament.player_max_price} value={this.state.max_price} />
          <input
            style={{
              width: '100%', '--min': `${0};`,
              '--max:': `${tournament.player_max_price};`,
              '--val:': `${tournament.player_max_price}`
            }}
            id={'range'}
            name={'range'}
            type={'range'}
            min={0}
            max={tournament.player_max_price}
            step={0.1}
            onMouseUp={() => {
              this.getMorePlayers('max_price', this.state.max_price);
            }}
            onTouchEnd={() => {
              this.getMorePlayers('max_price', this.state.max_price);
            }}
            onChange={this.handleSliderChange} />
        </div>
        <View style={styles.buttonsRow}>
          <div style={styles.pickerButton} onClick={this.openTeamsDataFilter}>
            <TouchableOpacity>
              <View style={styles.pickerButtonContainer}>
                <View style={styles.pickerButtonLeftRow}>
                  <Text numberOfLines={1}
                    style={styles.pickerButtonText}>{dropDownTeamsData.find(t => t.id == this.state.team_id).name}</Text>
                </View>
                <IosArrowDown fontSize="1em" color="#FF4183" style={{ marginRight: '3%' }} />
              </View>
            </TouchableOpacity>
          </div>
        </View>
        <View style={styles.buttonsRow}>
          {this.getPositionButton(-1, translations.tKey('str_select_players_all_tab'))}
          {this.getPositionButton(1, translations.tKey('str_select_players_gkp_tab'))}
          {this.getPositionButton(2, translations.tKey('str_select_players_def_tab'))}
          {this.getPositionButton(3, translations.tKey('str_select_players_mid_tab'))}
          {this.getPositionButton(4, translations.tKey('str_select_players_fwd_tab'))}
        </View>

        {this.props.isLoading
          ? (
            <div style={styles.loaderContainer}>
              <ActivityIndicator size={'large'} color={design.primaryColor} />
            </div>
          ) : (
            this.props.availablePlayers && (
              <View style={{ width: '100%' }}>
                <View style={{ ...styles.totalFound, position: 'relative', paddingLeft: 10, paddingRight: 10 }}>

                  <div style={{ display: "flex", width: "100%", alignItems: "center", paddingRight: "10px" }}>
                    <Text style={styles.foundText}>{`${translations.tKey('str_select_players_found')} `}</Text>
                    <Text
                      style={styles.foundResultText}>{`${this.props.availablePlayers.total} ${translations.tKey('str_select_players_results')}`}</Text>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Text style={[styles.foundResultText, { marginRight: 5 }]}>{translations.tKey('str_legend')}</Text>
                    <IconInformationDialog IconStyles={{ cursor: 'pointer', display: "flex", justifyContent: "center" }} />
                  </div>
                </View>
                <View style={styles.tableHeader}>
                  <View style={{ ...styles.tableCell, ...styles.tableCellBig }} onClick={() => {
                    this.sortBy('name', true);
                  }}>
                    <Icon icon="player" />

                    <Image style={this.state.currentSort.name == "name" ? (this.state.currentSort.sort == "desc" ? styles.arrowImage :  styles.arrowImageFlipped) : styles.arrowImage} source={downArrow} />
                  </View>
                  <View style={{ ...styles.tableCell, ...styles.tableCellMedium }} onClick={() => {
                    this.sortBy('diff', true);
                  }}>
                    <Icon icon="difficulty" />

                    <Image style={this.state.currentSort.name == "diff" ? (this.state.currentSort.sort == "desc" ? styles.arrowImage :  styles.arrowImageFlipped) : styles.arrowImage} source={downArrow} />
                  </View>
                  <View style={{ ...styles.tableCell, ...styles.tableCellSmall }} onClick={() => {
                    this.sortBy('shape', true);
                  }}>
                    <Icon icon="form" />

                    <Image style={this.state.currentSort.name == "shape" ? (this.state.currentSort.sort == "desc" ? styles.arrowImage :  styles.arrowImageFlipped) : styles.arrowImage} source={downArrow} />
                  </View>
                  <View style={{ ...styles.tableCell, ...styles.tableCellSmall }} onClick={() => {
                    this.sortBy('points', true);
                  }}>
                    <Icon icon="points" />

                    <Image style={this.state.currentSort.name == "points" ? (this.state.currentSort.sort == "desc" ? styles.arrowImage :  styles.arrowImageFlipped) : styles.arrowImage  } source={downArrow} />
                  </View>
                  <View style={{ ...styles.tableCell, ...styles.tableCellMedium }} onClick={() => {
                    this.sortBy('gw_price', true);
                  }}>
                    <Icon icon="prize" />

                    <Image style={ this.state.currentSort.name == "price" ? (this.state.currentSort.sort == "desc" ? styles.arrowImage :  styles.arrowImageFlipped) : styles.arrowImage  } source={downArrow} />
                  </View>
                </View>
                <View style={styles.tableBody}>
                  {this.state.availablePlayers.data && this.state.availablePlayers.data.map(player => {

                    const isPlayerSelected = this.isPlayerSelected(player);
                    const transfer = JSON.parse(sessionStorage.getItem('transfer'));
                    if (transfer && isPlayerSelected) return null;
                    return <View
                      style={isPlayerSelected ? styles.selectedTableRow : [styles.tableRow, this.shouldGrayOutRow(player) ? { opacity: 0.3 } : null]}>
                      <div style={styles.tableCellBig} onClick={() => {
                        this.playerPressed(player);
                      }}>
                        <View style={styles.playerShirtColumn}>
                          <Image style={styles.playerShirtImage} source={player.iconUrl} />
                          {this.getPositionLabel(player.positionId)}
                        </View>
                        <View style={styles.playerNameMatchColumn}>
                          <Text
                            numberOfLines={1}
                            style={{
                              ...(isPlayerSelected
                                ? styles.selectedTableText
                                : styles.tableText)
                            }}
                          >
                            {player.firstName.trim().length > 0
                              ? `${player.firstName.trim()[0].toUpperCase()}. `
                              : ''}{player.lastName}
                          </Text>
                          <View style={styles.playerMatchText}>
                            <Text
                              style={[isPlayerSelected ? styles.matchSelectedText : styles.matchRegularText, !player.teams[0].player_in_team ? styles.matchDimmedText : {}]}>
                              {`${player.teams[0].name} - `}
                            </Text>
                            <Text
                              style={[isPlayerSelected ? styles.matchSelectedText : styles.matchRegularText, !player.teams[1].player_in_team ? styles.matchDimmedText : {}]}>
                              {`${player.teams[1].name}`}
                            </Text>
                          </View>
                        </View>
                      </div>
                      <View style={styles.tableCellMedium}>
                        <Text style={[styles.tableText, this.getDiffBoxColor(player.diff)]}>{player.diff}</Text>
                      </View>
                      <View style={styles.tableCellSmall}>
                        <Text
                          style={isPlayerSelected ? styles.selectedTableText : styles.tableText, { marginLeft: -10 }}>{player.shape.toFixed(1)}</Text>
                      </View>
                      <View style={styles.tableCellSmall}>
                        <Text style={isPlayerSelected ? styles.selectedTableText : styles.tableText}>{player.points}</Text>
                      </View>
                      <View style={styles.tableCellMedium}>
                        <Text
                          style={isPlayerSelected ? styles.selectedTableText : styles.tableText}>{tournament.is_seasonal_game ? `${player.price.toFixed(1)}M` : `${player.gw_price.toFixed(1)}M`}</Text>
                      </View>
                    </View>;
                  })}
                </View>

                <div style={styles.paginationContainer}>
                  <Pagination data={this.props.availablePlayers} changePageTo={(page) => {
                    this.getMorePlayers('page', page);
                  }} />
                </div>
              </View>
            )
          )
        }

        <div style={{ ...styles.enterButtonDiv, ...styles.stickyBottom}}
          onClick={() => {
            sessionStorage.removeItem('transfer');
            sessionStorage.removeItem('transferTeamId');
            localStorage.removeItem('addNewTeamHome');
            this.props.navigation.goBack();
          }}>
          <TouchableOpacity
            style={[styles.enterButton]}>
            <Text style={styles.enterButtonText}>{translations.tKey('str_select_players_confirm_players')}</Text>
          </TouchableOpacity>
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAvailablePlayers: data => dispatch(getAvailablePlayers(data)),
    setGroup: (group) => dispatch(setGroup(group)),
    transferPlayer: (teamId, subgameId, body) => dispatch(transferPlayer(teamId, subgameId, body)),
    setRemainingBudget: (budget) => dispatch(setRemainingBudget(budget)),
    getTournamentForTransfer: id => dispatch(getTournamentForTransfer(id)),
    clearTournamentForTransfer: () => dispatch(clearTournamentForTransfer())
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    homeInfo: state.homeTournaments.tournamentsInfo.tournaments,
    userTeam: state.userTeam.userTeam,
    availablePlayers: state.userTeam.availablePlayers,
    isLoading: state.ui.isLoading,
    tournamentForTransfer: state.enterTournament.tournament,
    fixtures: state.tournamentDetails.fixtures
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlayers);

const operator = getDomainOperator();

const design = operator.design;

const base = {
  stickyTop: {
    backgroundColor: 'white',
    position: '-webkit-sticky', /* Safari */
    position: 'sticky',
    top: 0,
    zIndex: '1',
  },
  stickyBottom: {
    backgroundColor: 'white',
    position: '-webkit-sticky', /* Safari */
    position: 'sticky',
    bottom: 0,
    zIndex: '1',
  },
  entriesText: {
    fontSize: 13,
    fontWeight: 'bold'
  },
  entriesTotalText: {
    fontSize: 16,
    opacity: 0.4,
    fontWeight: 'bold'
  },
  enterButton: {
    width: '80%',
    backgroundColor: design.secondaryColor,
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px'
  },
  enterButtonText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  enterButtonDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loaderContainer: {
    minHeight: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paginationContainer: {
    margin: '15px 0'
  },
  tableCell: {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    alignSelf: 'center',
    paddingHorizontal: '5%'
  },
  progressBar: {
    width: '100%',
    height: '50px',
    backgroundColor: '#EFEFEF',
    marginBottom: '10px'
  },
  blackText: {
    fontWeight: 800,
  },
  progressBarLeftText: {
    width: '50%',
    fontWeight: 550,
    fontSize: 16,
    textAlign: 'left',
    position: 'absolute',
    left: 10,
    top: 14
  },
  progressBarRightTextContainer: {
    display: 'flex',
    width: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 10,
    top: 14
  },
  backHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    paddingVertical: '20px',
    cursor: 'pointer'
  },
  backImage: {
    width: 21,
    height: 14,
    resizeMode: 'contain',
    marginRight: '15px'
  },
  backText: {
    color: 'black',
    fontSize: 20,
    fontWeight: 550
  },
  searchContainer: {
    width: '100%',
    paddingVertical: '10px',
    paddingHorizontal: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '2px solid black'
  },
  searchImage: {
    width: '14.5px',
    height: '14.5px',
    resizeMode: 'contain',
    marginLeft: '10px',
    marginRight: '10px'
  },
  textInput: {
    width: '90%',
    outline: 'none',
    fontSize: 16,
    fontWeight: 550,
    color: design.textColorPrimary,
  },
  buttonsRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: '10px'
  },
  pickerButton: {
    width: '98%'
  },
  pickerButtonContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${design.primaryColor}`,
    paddingVertical: '10px'
  },
  pickerButtonLeftRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '84%',
    marginLeft: '3%',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  pickerButtonPurpleBadge: {
    backgroundColor: design.primaryColor,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: '4px',
    paddingHorizontal: '5px',
    marginRight: '5px'
  },
  pickerButtonPurpleBadgeText: {
    color: 'white',
    fontSize: 8
  },
  pickerButtonText: {
    fontSize: 14,
    fontWeight: 550
  },
  maxPriceContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px'
  },
  maxPriceLabel: {
    width: '50%',
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 550
  },
  maxPriceValue: {
    width: '50%',
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 550
  },
  sliderContainer: {
    width: '100%',
    overflowX: 'hidden'
  },
  slider: {
    width: '100%',
    height: 40
  },
  totalFound: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '40px',
    marginBottom: '20px'
  },
  foundText: {
    fontSize: 16,
    opacity: 0.3,
    fontWeight: 'bold'
  },
  foundResultText: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: '10px',
    backgroundColor: '#EFEFEF',
    width: '100%'
  },
  tableCellMedium: {
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableCellSmall: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableCellBig: {
    flex: 4,
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  infoIcon: {
    width: 24,
    height: 24,
    backgroundColor: '#EFEFEF',
    textAlign: 'center',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  infoIconText: {
    fontFamily: 'Lobster',
    fontSize: 12
  },
  tableText: {
    fontSize: isMobile ? 12 : 14,
    fontWeight: 550
  },
  selectedTableText: {
    fontSize: 14,
    fontWeight: 550,
    color: 'white'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '44px'
  },
  selectedTableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '44px',
    backgroundColor: design.primaryColor
  },
  headerText: {

    textAlign: 'center'
  },
  playerShirtColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playerShirtImage: {
    width: '28px',
    height: '28px',
    resizeMode: 'contain'
  },
  activeButton: {
    width: '19%',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: design.secondaryColor,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inactiveButton: {
    width: '19%',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#EFEFEF',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  playerNameMatchColumn: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: '5px'
  },
  playerMatchText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  matchDimmedText: {
    fontSize: 12,
    opacity: 0.3
  },
  matchRegularText: {
    fontSize: 12
  },
  matchSelectedText: {
    fontSize: 12,
    color: 'white'
  },
  backButtonDiv: {
    marginBottom: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  arrowImage: {
    width: '7px',
    height: '4.3px',
    resizeMode: 'contain',
    marginRight: '15px'
  },
  arrowImageFlipped: {
    width: '7px',
    height: '4.3px',
    resizeMode: 'contain',
    marginRight: '15px',
    transform: "rotate(180deg)"
  },
  specialIconStyle: {
    position: 'absolute',
    width: 18,
    height: 18,
    top: 3,
    right: 0,
    zIndex: 1
  }
};

const styles = createStyles(
  base,
  maxWidth(576, {}),
  maxWidth(376, {})
);