import * as React from "react";

function SvgPrizepool(props) {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <defs>
        <style>{".prizepool_svg__a{fill:#bebebe}"}</style>
      </defs>
      <path
        className="prizepool_svg__a"
        d="M8.463 0v7.233l6.463-3.231A7.957 7.957 0 008.463 0z"
      />
      <path
        className="prizepool_svg__a"
        d="M7.53 7.993V0a8.006 8.006 0 106.786 12.9L7.734 8.379a.472.472 0 01-.204-.386z"
      />
      <path
        className="prizepool_svg__a"
        d="M15.347 4.846L8.916 8.062l5.931 4.071a7.968 7.968 0 00.5-7.287z"
      />
    </svg>
  );
}

export default SvgPrizepool;
