import React from 'react';
import { View, Text, Image, ActivityIndicator } from 'react-native';
import { createStyles } from 'react-native-media-queries';

import search from '../../assets/images/search.svg';
import * as translations from '../../Common/utils/translations';

import Pagination from '../../Common/components/Pagination';
import ThrottledSearchField from '../../Common/components/TrottledSearchField';

import { Icon } from '../../Common/components/Icon';
import { IconInformationDialog } from '../../Common/components/IconInformationDialog';
import { getDomainOperator } from '../../Common/utils/domainMapper';

class TournamentEntries extends React.Component {

  state = {
    name: null,
    entries: null,
    sortCriterias: [
      {
        name: 'position',
        sort: 'desc'
      },
      {
        name: 'name',
        sort: 'desc'
      },
      {
        name: 'points',
        sort: 'desc'
      },
      {
        name: 'points_pp',
        sort: 'desc'
      }
    ]
  };

  componentDidMount() {
    this.setState({ entries: this.props.entries });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    console.log(nextProps.entries)
    if (nextProps.entries !== this.props.entries) {
      if (!this.state.currentSort) {
        this.setState({ entries: nextProps.entries });
        return;
      }

      let sortCriteria = this.state.currentSort.name;
      let data = nextProps.entries.data.slice().sort((a, b) => {
        if (sortCriteria === 'name' ? (a[sortCriteria] > b[sortCriteria]) : (+a[sortCriteria] > +b[sortCriteria])) {
          return this.state.currentSort.sort === 'asc' ? 1 : -1;
        }
        if (sortCriteria === 'name' ? (a[sortCriteria] < b[sortCriteria]) : (+a[sortCriteria] < +b[sortCriteria])) {
          return this.state.currentSort.sort === 'asc' ? -1 : 1;
        }
        return 0;

      })
      this.setState({
        entries: {...nextProps.entries, data}
      });
    }
  }

  getRowComponent = (name, value) => {
    return <View style={styles.nameValueRow}>
      <View style={styles.nameRow}>
        <Text style={styles.textNormal}>{name}</Text>
      </View>
      <View style={styles.valueRow}>
        <Text style={styles.textNormal}>{value}</Text>
      </View>
    </View>;
  };

  sortBy = (sortCriteria) => {
    const currentSort =  !this.state.currentSort
    || (this.state.currentSort.name != sortCriteria || this.state.currentSort.sort != this.state.sortCriterias.find(t => t.name == sortCriteria).sort)
    ? { ...this.state.sortCriterias.find(t => t.name == sortCriteria) }
    : this.state.currentSort;
    this.props.onNextRequest(1, this.state.name, currentSort ? currentSort : {});
    this.setState({
      currentSort: currentSort,
      sortCriterias: this.state.sortCriterias.map(t => {
        if (t.name === sortCriteria) {
          if (t.sort === 'asc') {
            t.sort = 'desc';
          } else {
            t.sort = 'asc';
          }
        }
        return { ...t };
      })
    });
  };

  render() {
    const { entries } = this.state;
    const { isLoading } = this.props;

    return (
      <div style={styles.container}>
        <View style={styles.allItemsTextContainer}>
          <Text
            style={styles.allItemsText}>{`${translations.tKey('str_entries_all_teams')} ${(entries && !isLoading) ? entries.total : ''}`}</Text>
            <Text style={{fontSize: 16, fontWeight: "bold", position: "absolute", right: "30px"}}>{translations.tKey('str_legend')}</Text>
          <IconInformationDialog />
        </View>
        <View style={styles.searchContainer}>
          <Image style={styles.searchImage} source={search} />
          <ThrottledSearchField
            className={styles.textInput}
            placeholderTextColor={design.textColorPrimary}
            style={styles.textInput}
            placeholder={translations.tKey('str_entries_search_user')}
            onChange={(text) => {
              this.setState({ name: text });
              this.props.onNextRequest(1, text);
            }}
          />
        </View>
        {isLoading ? <ActivityIndicator size={'large'} color={design.primaryColor} style={{ marginTop: 20 }} /> :
          <div style={styles.tableContainer}>
            <div style={styles.tableHeader}>
              <div style={{ ...styles.tableCellSmall, ...styles.spaceBetween }} onClick={() => {
                this.sortBy('position');
              }}>
                <Icon icon="number" />

                <Icon icon="arrow" style={styles.arrowImage} color="black" />
              </div>
              <div style={{ ...styles.tableCellBig, ...styles.spaceBetween }} onClick={() => {
                this.sortBy('name');
              }}>
                <Icon icon="user" />

                <Icon icon="arrow" style={styles.arrowImage} color="black" />
              </div>

              <div style={{ ...styles.tableCellMedium, ...styles.spaceBetween }} onClick={() => {
                this.sortBy('points_pp');
              }}>
                <Icon icon="doublePoints" />

                <Icon icon="arrow" style={styles.arrowImage} color="black" />
              </div>

              <div style={{ ...styles.tableCellMedium, ...styles.spaceBetween }} onClick={() => {
                this.sortBy('points');
              }}>
                <Icon icon="points" />

                <Icon icon="arrow" style={styles.arrowImage} color="black" />
              </div>

            </div>
            {entries && entries.data.map((entry, index) => (
              <div style={styles.tableRow} key={index}>
                <Text style={[styles.tableCellSmall, styles.tableCellText]}>{`${entry.position}.`}</Text>
                <Text style={[styles.tableCellBig, styles.tableCellText]}>{entry.name}</Text>
                <Text
                  style={[styles.tableCellMedium, styles.tableCellText, styles.centerAlign]}>{entry.points_pp}</Text>
                <Text
                  style={[styles.tableCellMedium, styles.tableCellText, styles.textBold, styles.centerAlign]}>{entry.points}</Text>
              </div>
            ))}
          </div>}

        {!isLoading /* && entries?.last_page > 1 */ && (
          <div style={{ margin: '10px 0' }}>
            <Pagination data={entries} changePageTo={(page) => {
              const currentSort = this.state.currentSort;
              this.props.onNextRequest(page, this.state.name, currentSort ? currentSort : {});
            }} />
          </div>
        )}
      </div>
    );
  }
}

export default TournamentEntries;

const operator = getDomainOperator();

const design = operator.design;

const base = {
  textBold: {
    fontWeight: 900
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  centerAlign: {
    justifyContent: 'center'
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  tableContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '92%',
    flexDirection: 'column'
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  tableHeaderText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '5px 0px'
  },
  tableCellText: {
    fontSize: 14
  },
  tableCellSmall: {
    width: '10%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableCellMedium: {
    width: '19%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'

  },
  tableCellBig: {
    width: '55%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  searchImage: {
    width: '14.5px',
    height: '14.5px',
    resizeMode: 'contain',
    marginLeft: '10px',
    marginRight: '10px'
  },
  allItemsTextContainer: {
    width: '92%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  allItemsText: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  searchContainer: {
    width: '100%',
    paddingVertical: '10px',
    paddingHorizontal: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '2px solid black'
  },
  arrowImage: {
    width: '7px',
    height: '4.3px',
    resizeMode: 'contain',
    marginLeft: '2px',
    marginTop: '2px',
    marginRight: 15,
    cursor: 'pointer'
  },
  textInput: {
    width: '90%',
    outline: 'none',
    fontSize: 16,
    fontWeight: 550,
    color: design.textColorPrimary,
  }
};

const styles = createStyles(base);

