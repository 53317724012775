import React, { Component } from 'react';

import {TextInput } from "react-native";

const WAIT_INTERVAL = 200;
const ENTER_KEY = 13;

export default class TrottledSearchField extends Component {
    constructor(props) {
        super();

        this.state = {
            value: props.value
        };
    }

    componentWillMount() {
        this.timer = null;
    }

    handleChange = (value) => {
        clearTimeout(this.timer);

        this.setState({ value });

        this.timer = setTimeout(this.triggerChange, this.props.interval ? this.props.interval : WAIT_INTERVAL);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            this.triggerChange();
        }
    }

    triggerChange = () =>{
        const { value } = this.state;

        this.props.onChange(value);
    }

    render() {
        const { className } = this.props;

        return (
            <TextInput
                style={className}
                placeholderTextColor={this.props.placeholderTextColor}
                placeholder={this.props.placeholder}
                value={this.state.value}
                onChangeText={this.handleChange}
                onKeyDown={this.handleKeyDown}
            />
        );
    }
}