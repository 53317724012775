import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer from "../Welcome/reducers/auth";
import championshipReducer from '../Home/reducers/championship';
import roomsReducer from '../SearchRoom/reducers/rooms';

import uiReducer from "../Common/reducers/ui";
import realTeamsReducer from "../Common/reducers/teams";
import errorsReducer from "../Common/reducers/errors";

import userTeamReducer from '../Team/reducers/team';
import teamPlayerInfoReducer from '../Team/reducers/players';
import playerInfoReducer from '../Transfers/reducers/players';
import transfersReducer from '../Transfers/reducers/team';
import positionsReducer from '../Stats/reducers/positions';
import homeReducer from '../Home/reducers/home';

import pointsReducer from '../Points/reducers/team';
import roundsReducer from '../Points/reducers/rounds';

import resultsReducer from '../Results/reducers/results';
import leaguesRankingsReducer from '../Rankings/reducers/leaguesRankings';

import homeTournamentsReducer from "../HomeTournaments/reducers/homeTournaments";
import tournamentDetailsReducer from "../TournamentDetails/reducers/tournamentDetails";
import enterTournamentReducer from "../EnterTournament/reducers/enterTournament";
import dashboardReducer from "../Dashboard/reducers/dashboard";
import headerReducer from "../Common/reducers/header";
import highlightsReducer from "../TournamentHighlights/reducers/highlights";
import confirmModalReducer from "../ConfirmPopup/reducers/confirmPopup";

const rootReducer = combineReducers({
  realTeams: realTeamsReducer,
  auth: authReducer,
  ui: uiReducer,
  room: roomsReducer,
  errors: errorsReducer,
  championship: championshipReducer,
  userTeam: userTeamReducer,
  playerInfo: playerInfoReducer,
  teamPlayerInfo: teamPlayerInfoReducer,
  points: pointsReducer,
  rounds: roundsReducer,
  transfers: transfersReducer,
  results: resultsReducer,
  positions: positionsReducer,
  leaguesRankings: leaguesRankingsReducer,
  userInfo: homeReducer,
  homeTournaments: homeTournamentsReducer,
  tournamentDetails: tournamentDetailsReducer,
  enterTournament: enterTournamentReducer,
  dashboard: dashboardReducer,
  header: headerReducer,
  highlights: highlightsReducer,
  confirmModal: confirmModalReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};

export default configureStore;
