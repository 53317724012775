import React from 'react';
import { StyleSheet, ScrollView, RefreshControl } from 'react-native';

const refreshContainer = (props) => {
    return (
        <ScrollView 
            style={styles.scrollView} 
            contentContainerStyle={[styles.container, props.containerStyle]}
            refreshControl={<RefreshControl
                                refreshing={props.refreshing}
                                onRefresh={props.onRefresh}/>}>
            {props.children}
        </ScrollView>
    );
}

export default refreshContainer;

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
    minHeight: 770
  }
});

