import {
    GET_USER_DASHBOARD,
    GET_LEADERBOARD,
    SET_SELECTED_ITEMS,
    DELETE_SELECTED_ITEMS,
    LAST_LEADERBOARD_ID,
    GET_ROUND_SLIDER_INFO
} from "../actions/actionTypes";

const initialState = {
    dashboard: null,
    leaderboard: null,
    selectedTeam: null,
    selectedGame: null,
    lastLeaderboadId: null,
    roundSliderInfo: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DASHBOARD: {
            return {
                ...state,
                dashboard: action.data
            };
        }
        case GET_LEADERBOARD: {
            return {
                ...state,
                leaderboard: action.data
            }
        }
        case SET_SELECTED_ITEMS: {
            return {
                ...state,
                selectedGame: action.selectedGame,
                selectedTeam: action.selectedTeam
            }
        }
        case DELETE_SELECTED_ITEMS: {
            return {
                ...state,
                selectedGame: null,
                selectedTeam: null
            }
        }
        case LAST_LEADERBOARD_ID: {
            return {
                ...state,
                lastLeaderboadId: action.id
            }
        }
        case GET_ROUND_SLIDER_INFO: {
            return {
                ...state,
                roundSliderInfo: action.data
            }
        }
        default:
            return state;
    }
};

export default reducer;