import { 
  SET_LIVESCORE, 
  SET_RESULTS
} from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import saveIcon from '../../assets/save.svg.png';
import savedPenaltyIcon from '../../assets/saved-penalty.svg.png';
import yellowCardIcon from '../../assets/yellow-card.svg.png';
import redCardIcon from '../../assets/red-card.svg.png';
import doubleYellowCardIcon from '../../assets/double-yellow-card.svg.png';
import goalIcon from '../../assets/goal.svg.png';
import ownGoalIcon from '../../assets/own-goal.svg.png';
import substituteIcon from '../../assets/substitute.svg.png';
import penaltyIcon from '../../assets/penalty.svg.png';
import missedPenaltyIcon from '../../assets/missed-penalty.svg.png';


export const getLivescore = (matchId) => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
    })
    .then(token => {        
        return fetch(BASE_URL + '/users/games/'+matchId+'/livescore', {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
        if (res.data.score) {
            res.data.score.guestLogo = BASE_URL + res.data.score.guestLogo;
            res.data.score.hostLogo = BASE_URL + res.data.score.hostLogo;
        }
        const icons = [goalIcon, ownGoalIcon, penaltyIcon, missedPenaltyIcon,
        yellowCardIcon, redCardIcon, substituteIcon, saveIcon, doubleYellowCardIcon, savedPenaltyIcon];

        res.data.livescore.firstHalfStats.forEach(stat => stat.icon = icons[stat.statId-1]);
        res.data.livescore.secondHalfStats.forEach(stat => stat.icon = icons[stat.statId-1]);

        dispatch(setLivescore(res.data));
    });
  };
}


export const setLivescore = livescore => {
  return {
    type: SET_LIVESCORE,
    livescore
  };
};


export const getResults = (roundId, championshipId) => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
    })
    .then(token => {
        let params = roundId ? "round_id=" + roundId : '';
        if (params.length > 0) params = "?" + params;
        params = roundId ?  `&championshipId=${championshipId}` : `?championshipId=${championshipId}`
        
        return fetch(BASE_URL + '/users/results'+params, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
        const results = res.data;

        if (Array.isArray(results.results)) {
            results.results.forEach(result => {
                if (Array.isArray(result.scores)) {
                    result.scores.forEach(score => {
                        score.guestLogo = BASE_URL + score.guestLogo;
                        score.hostLogo = BASE_URL + score.hostLogo;
                    });
                }
            });
        }      
      dispatch(setResults(results));
    });
  };
}


export const setResults = results => {
  return {
    type: SET_RESULTS,
    results
  };
};