import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import budgetIcon from '../../assets/budget-transfers.png';

const budgetContainer = (props) => {
    const negativeBudget = typeof props.budget === 'number' && props.budget < 0 ? {color: 'red'} : null;
    return (
       <View style={styles.budgetContainer}>
            <View style={styles.statWithIcon}>
                <Image source={budgetIcon} style={styles.headerIcon} />
                <Text style={[styles.statText, negativeBudget]}>{props.budget}</Text>
            </View>
            <Text style={styles.statDescription}>Бюджет</Text>
        </View>
    );
}

export default budgetContainer;

const styles = StyleSheet.create({
 headerIcon: {
      width: 20,
      height: 20, 
  },
  statWithIcon: {
      flexDirection: 'row',
  },
  statContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1
  },
  budgetContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flex: 1,
    backgroundColor: '#090717'
  },
  statText: {
      fontWeight: 'bold',
      fontSize: 17,
      color: '#DEC84B'
  },
  statDescription: {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#69D565'
  }
});

