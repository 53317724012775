import { 
    GET_TOURNAMENTS_HOME_INFO,
    GET_ALL_CHAMPIONSHIPS
   } from "../actions/actionTypes";
  
  const initialState = {
      tournamentsInfo: {},
      tournamentsInfoPages: null,
      championships: null
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TOURNAMENTS_HOME_INFO: {
       
        let tournamentsInfo = action.info && action.info.current_page == 1 ? action.info : null;
        if (!tournamentsInfo) {
          tournamentsInfo = {...state.tournamentsInfo, tournaments: state.tournamentsInfo.tournaments.concat(action.info.tournaments)}
        }
       
        return {
          ...state,
          tournamentsInfo,
          tournamentsInfoPages: action.info
        };
      }
      case GET_ALL_CHAMPIONSHIPS: {
        return {
          ...state,
          championships: action.championships
        }
      }
  
      default:
        return state;
    }
  };
  
  export default reducer;