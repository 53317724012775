import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Linking } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Background from '../../Common/components/Background';
import RefreshContainer from '../../Common/components/RefreshContainer';
import LinkView from '../../Common/components/LinkView';
import LoadingScreen from '../../Common/components/LoadingScreen';
import RoundsDialog from '../../Home/components/RoundsDialog';
import gongAd from '../../assets/gong_ad.png';
import diemaSport from '../../assets/diema-sport.png';
import diemaSport2 from '../../assets/diema-sport-2.png';
import novaSport from '../../assets/novasport.png';
import gongIcon from '../../assets/gong.png';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';


import { connect } from 'react-redux';
import { getResults } from '../actions/results';
import { getRounds } from '../../Points/actions/rounds';

import TabBar from '../../Common/components/TabBar';
import TopBar from '../../Common/components/TopBar';
import { getDomainOperator } from '../../Common/utils/domainMapper';

class Results extends React.Component {
  static navigationOptions = {
    title: 'Резултати',
  };

  state = {
    refreshing: false,
    currentRoundId: null,
    roundsOpen: false
  }

  componentDidMount() {
    this.props.getResults(this.props.currentRoundId, window.localStorage.getItem("championshipId"));
    // this.props.getRounds();
  }

  componentDidUpdate(prevProps) {
    if (this.state.refreshing && prevProps.results !== this.props.results) {
      this.setState({ refreshing: false });
    }
    if (prevProps.roomInfo !== this.props.roomInfo) {
      this.props.getTeam();
    }
    if (this.props.navigation !== prevProps.navigation) {
      this.props.getResults(this.props.currentRoundId, window.localStorage.getItem("championshipId"));
    }
  }

  openLiveScore = id => {
    this.props.navigation.navigate('LiveScore', { scoreId: id });
  }

  refreshRound = roundId => {
    this.props.getResults(roundId, window.localStorage.getItem("championshipId"));
    this.setState({ currentRoundId: roundId })
  }

  _onRefresh = () => {
    this.setState({ refreshing: true });
    this.refreshRound(this.state.currentRoundId);
  }

  closeRounds = round => {
    if (!round.id) return;
    this.setState({ roundsOpen: false });
    this.refreshRound(round.id);
  };

  render() {
    if (!this.props.results || !this.props.rounds) return <LoadingScreen />;

    if (!this.props.roomInfo) {
      this.props.navigation.navigate('Home');
      return null;
    }

    let allRounds = [], currentRound = { key: 1, label: this.props.results.roundName };
    allRounds = this.props.rounds.map(round => {
      return {
        id: round.id,
        name: round.name
      }
    });
    currentRound = allRounds.find(r => r.label === this.props.results.roundName);
    return (
      <RefreshContainer refreshing={this.state.refreshing}
        onRefresh={this._onRefresh}>
        <Background />
        <TopBar navigation={this.props.navigation} />
        <View style={styles.innerContainer}>
          <RoundsDialog
            open={this.state.roundsOpen}
            onClose={this.closeRounds}
            rounds={allRounds} />
          <TouchableOpacity style={styles.headerView} onPress={() => this.setState({ roundsOpen: true })}>
            <View style={styles.roundContainer}>
              <Text style={styles.headerText}>{this.props.results.roundName} </Text>
              <IosArrowDown fontSize="1.5em" style={{ fontWeight: 'bold', color: design.textColorPrimary }} />
            </View>
          </TouchableOpacity>

          {this.props.results.results.map(result => (
            <View style={styles.dayContainer} key={result.date}>
              <Text style={styles.date}>{result.date}</Text>
              {result.scores.map(score => (
                <TouchableOpacity style={styles.scoreContainer} key={score.id}
                  onPress={() => this.openLiveScore(score.id)}>
                  {score.status ?
                    (!isNaN(Number(score.status)) || score.status === 'П' ?
                      <View style={[styles.minuteStatusContainer]}>
                        <Text style={styles.minuteStatus}>
                          {score.status + (score.status !== 'П' ? "'" : '')}
                        </Text>
                      </View> :
                      <Text style={styles.status}>{score.status}</Text>) :
                    <Text style={styles.status}>{"    "}</Text>}

                  <Text style={[styles.teamName, { textAlign: 'right' }]}>{score.hostName}</Text>
                  <Image source={{ uri: score.hostLogo }} style={styles.teamLogo} />

                  {score.time === undefined ?
                    <Text style={styles.score}>
                      {score.hostScore + ":" + score.guestScore}
                    </Text> :
                    <Text style={styles.time}>{score.time}</Text>
                  }

                  <Image source={{ uri: score.guestLogo }} style={styles.teamLogo} />
                  <Text style={styles.teamName}>{score.guestName}</Text>
                  <View style={styles.mediaContainer}>
                    {score.media_diema_sport ? <Image source={diemaSport} style={styles.icon} /> : null}
                    {score.media_diema_sport_2 ? <Image source={diemaSport2} style={styles.icon} /> : null}
                    {score.media_nova_sport ? <Image source={novaSport} style={styles.icon} /> : null}
                    {score.media_gong ? <Image source={gongIcon} style={styles.icon} /> : null}
                  </View>
                </TouchableOpacity>
              )
              )}
            </View>
          )
          )}
        </View>
        <TabBar navigation={this.props.navigation} />
      </RefreshContainer>
    );

  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getResults: (roundId, championshipId) => dispatch(getResults(roundId,championshipId)),
    getRounds: (id) => dispatch(getRounds(id))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    results: state.results.results,
    rounds: state.rounds.rounds,
    roomInfo: state.room.roomInfo,
    currentRoundId: state.rounds.currentRoundId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);

const operator = getDomainOperator();

const design = operator.design;

const styles = EStyleSheet.create({
  innerContainer: {
    width: '100%',
    alignItems: 'center',
    minHeight: 1200
  },
  headerView: {
    backgroundColor: '#CCD4E1',
    paddingVertical: 5,
    paddingHorizontal: 30,
    width: '90%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 90,
    marginBottom: 15
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 25,
    marginVertical: 5
  },
  headerInfo: {
    flexDirection: 'row'
  },
  headerInfoText: {
    marginHorizontal: 15,
    fontSize: 20
  },
  adImageContainer: {
    width: '90%',
    borderRadius: 10,
    marginVertical: 10,
    height: 60
  },
  adImage: {
    width: '99%',
    height: '99%',
    borderRadius: 10,
    resizeMode: 'contain'
  },
  roundContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    height: 60,
  },
  dayContainer: {
    width: '98%',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10
  },
  date: {
    color: 'white',
    fontSize: 14,
    marginHorizontal: 5
  },
  time: {
    color: '#808080',
    fontSize: 14,
    marginHorizontal: 15,
    fontWeight: 'bold'
  },
  scoreContainer: {
    width: '90%',
    borderRadius: 10,
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
    height: 50,
    justifyContent: 'space-evenly'
  },
  status: {
    textAlign: 'left',
    color: '#ACAEB1',
    fontSize: 17,
    marginHorizontal: 3
  },
  minuteStatusContainer: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    height: '100%',
    backgroundColor: '#72E86B',
    width: '3.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  minuteStatus: {
    color: design.textColorPrimary,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1rem'
  },
  teamName: {
    fontWeight: 'bold',
    fontSize: 14,
    width: 60
  },
  teamLogo: {
    width: 25,
    height: 25,
    marginHorizontal: 5,
    resizeMode: 'contain'
  },
  score: {
    fontWeight: 'bold',
    fontSize: 14,
    marginHorizontal: '0.7rem'
  },
  mediaContainer: {
    width: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  icon: {
    width: 17,
    height: 17,
    marginHorizontal: 1,
    borderRadius: 10
  }
});
