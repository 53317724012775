import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import userIcon from "../../assets/user.png";
import betIcon from "../../assets/bet.png";
import prizeIcon from "../../assets/prize.png";

class RoomView extends Component {
    state = {
      isExpanded: false
    }

    constructor(props) {
      super(props);
      if (props.isExpanded) {
        this.state.isExpanded = true;
      }
    }

    render() {
        const { room, onChooseRoom } = this.props;
        const { isExpanded } = this.state;
    
        return (
            <View style={styles.roomContainer} key={room.id}>
                <TouchableOpacity style={styles.roomBasicInfo} onPress={() => onChooseRoom(room)}>
                  <Text style={styles.basicInfoText}>{`${room.title} \t ${room.bet} лв.`}</Text>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    {!isNaN(Number(room.userPlace)) && <Text style={styles.basicInfoText}>{room.userPlace}</Text>}
                    <TouchableOpacity style={styles.circle} 
                      onPress={() => this.setState({isExpanded: !isExpanded})}>
                      {isExpanded ? 
                      <IosArrowUp fontSize="1.5em" style={{fontWeight: 'bold'}} color="white"/>
                      :
                      <IosArrowDown fontSize="1.5em" style={{fontWeight: 'bold'}} color="white"/>
                      }
                    </TouchableOpacity>
                  </View>
                </TouchableOpacity>
                {isExpanded && <View style={styles.roomMoreInfo}>
                    <View style={styles.moreInfoContainer}>
                      <Text style={[styles.infoText, styles.bold]}>{room.maxPlayers != -1 ? (room.currentPlayers + "/" + room.maxPlayers) : room.currentPlayers}</Text>
                      <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image source={userIcon} style={styles.image}/>
                        <Text style={styles.infoText}>Играчи</Text>
                      </View>
                    </View>
                      <View style={styles.moreInfoContainer}>
                      <Text style={[styles.infoText, styles.bold]}>{room.bet}лв</Text>
                      <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image source={betIcon} style={styles.image}/>
                        <Text style={styles.infoText}>Залог</Text>
                      </View>
                    </View>
                    <View style={styles.moreInfoContainer}>
                      <Text style={[styles.infoText, styles.bold, {color: '#C3A050'}]}>{room.prize}лв</Text>
                      <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image source={prizeIcon} style={styles.image}/>
                        <Text style={[styles.infoText, {color: '#C3A050'}]}>Печалба</Text>
                      </View>
                    </View>                        
                </View>}
            </View>
        );
    }
}

export default RoomView;

const styles = StyleSheet.create({
  roomContainer: {
    width: '100%',
    marginVertical: 10
  },
  roomBasicInfo: {
    backgroundColor: 'white',
    borderRadius: 20,
    width: '100%',
    padding: 17,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 4,
  },
  basicInfoText: {
    fontWeight:'bold',
    fontSize: 15
  },
  circle: {
    marginHorizontal: 10,
    width: 30,
    height: 30,
    backgroundColor: '#347190',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20
  },
  roomMoreInfo: {
    backgroundColor: '#0E2D49',
    width: '100%',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingTop: 40,
    top: -20
  },
  moreInfoContainer: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 60 
  },
  infoText: {
    color: '#8999A9',
    fontSize: 16
  },
  bold: {
    fontWeight:'bold'
  },
  rules: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 20
  },
  rulesText: {  
    color: '#C3A050',
    fontSize: 18,
    margin: 10
  },
  image: {
    width: 30, 
    height: 30,
    resizeMode: 'contain'
  }
});