import {
    SHOW_CONFIRM_MODAL,
    HIDE_CONFIRM_MODAL,
} from '../actions/actionTypes';

const initialState = {
    show: false,
    message: null,
    title: null,
    confirmAction: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        
        case SHOW_CONFIRM_MODAL:
            return {
                ...state,
                show: true,
                message: action.message,
                title: action.title,
                confirmAction: action.confirmAction
            }
        case HIDE_CONFIRM_MODAL:
            return {
                ...state,
                show: false,
                message: null,
                title: null,
                confirmAction: null
            }
        default:
            return state;
    }
}