import React from "react";
import { View, Text, TouchableOpacity, ActivityIndicator } from "react-native";
import LoadingScreen from '../../Common/components/LoadingScreen';
import { connect } from 'react-redux';
import { getHomeTournamentsInfo, getAllChampionShips } from "../actions/tournaments";
import TournamentCard from "../components/TournamentCard";
import DropDownDialog from "../components/DropDownDialog";
import FixturesDialog from "../components/FixturesDialog";
import { createStyles, maxWidth } from 'react-native-media-queries';
import { setTeam } from "../../Team/actions/team";
import {
    clearFixtures
} from "../../TournamentDetails/actions/tournamentDetails";
import InformationDialog from "../components/InformationDialog";
import * as translations from "../../Common/utils/translations"
import { getDomainOperator } from "../../Common/utils/domainMapper";
import comingSoonLogo from "../../assets/comin_soon_2024.png"
import { showConfirmPopup } from "../../ConfirmPopup/actions/confirmPopup";

class HomeTournaments extends React.Component {

    state = {
        leagueFilterOpen: false,
        timeFilterOpen: false,
        openFixturesDialog: false,
        infoDialogOpen: false,
        league: {
            id: null,
            name: translations.tKey("str_all_championships")
        },
        time: {
            id: 1,
            value: "upcoming",
            name: translations.tKey("str_league_filter_open")
        },
        tournament_id: null,
        infoDialogContent: ""
    }

    componentDidMount() {
        localStorage.removeItem('changeTeam');

            this.props.getHomeTournamentsInfo(this.state.time.value, this.state.league.id, 1);
            this.props.getAllChampionShips();
            this.props.setTeam(null, false);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.homeInfo && nextProps.homeInfo) {
            let count = 0;
            nextProps.counts.forEach(u => {
                count += u.count;
            })
            this.setState({
                league: {
                    id: null,
                    name: `${translations.tKey("str_all_championships")} (${count})`
                }
            })
        }
    }

    closeFixturesDialog = () => {
        this.props.clearFixtures();
        this.setState({ openFixturesDialog: false })
    }

    openFixturesDialog = (id) => {
        this.setState({ tournament_id: id, openFixturesDialog: true })
    }

    closeInfoDialog = () => {
        this.setState({ infoDialogOpen: false })
    }

    openLeagueFilter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.isLoading) return;
        this.setState({ leagueFilterOpen: true, timeFilterOpen: false });
    }

    openTimeFilter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.isLoading) return;
        this.setState({ leagueFilterOpen: false, timeFilterOpen: true });
    }

    closeLeagueFilter = (item) => {
        this.setState({ leagueFilterOpen: false, league: item ? item : this.state.league });
        if (item) {
            this.props.getHomeTournamentsInfo(this.state.time.value, item.id);
        }
    }

    closeTimeFilter = (item) => {
        this.setState({ timeFilterOpen: false, time: item ? item : this.state.time });
        if (item) {
            this.props.getHomeTournamentsInfo(item.value, this.state.league.id);
        }
    }

    showSubscriptionPopup = () => {
      this.props.showConfirmPopup(translations.tKey('str_subscription'), operator.subscription, null);
    }

    render() {
        const { navigation, homeInfo, championships, isLoading } = this.props;

        if (!homeInfo || !championships || isLoading) return <LoadingScreen />;


        const dropDownLeagueData = championships.map(t => {
            return {
                id: t.id,
                name: `${t.name} (${this.props.counts.find(count => count.championship_id == t.id).count})`,
                image: t.flag
            }
        });
        let count = 0;
        this.props.counts.forEach(u => {
            count += u.count;
        })
        dropDownLeagueData.push({ id: null, name: `${translations.tKey("str_all_championships")} (${count})` });
        const dropDownTimeData = [
            {
                id: 1,
                value: "upcoming",
                name: translations.tKey("str_league_filter_open")
            },
            {
                id: 2,
                value: "current",
                name: translations.tKey("str_league_filter_started")
            },
            {
                id: 3,
                value: "past",
                name: translations.tKey("str_league_filter_ended")
            }
        ];

        return (
            <div style={styles.container}>
                <DropDownDialog
                    title={translations.tKey("str_league_select_title")}
                    items={dropDownLeagueData}
                    open={this.state.leagueFilterOpen}
                    onClose={this.closeLeagueFilter}
                />
                <DropDownDialog
                    title={translations.tKey("str_league_filter_title")}
                    items={dropDownTimeData}
                    open={this.state.timeFilterOpen}
                    onClose={this.closeTimeFilter}
                />

                <FixturesDialog
                    title={translations.tKey("str_fixtures_dialog_title")}
                    tournamentId={this.state.tournament_id}
                    open={this.state.openFixturesDialog}
                    onClose={this.closeFixturesDialog} />

                <InformationDialog
                    title={translations.tKey("str_information_dialog_title")}
                    body={this.state.infoDialogContent}
                    open={this.state.infoDialogOpen}
                    onClose={this.closeInfoDialog}
                />
                <View style={styles.grayContainer}>
                    <View style={styles.cardsContainer}>
                        {operator.rewards &&
                        <View style={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: 'center', textAlign: 'center', margin: 'auto', marginTop: 40 }}>
                            <View style={{ ...styles.card }}>
                                {operator.rewards}
                            </View>
                        </View>}
                        {this.props.isLoading && !this.state.openFixturesDialog && (!this.props.homeInfo || this.props.homeInfo.current_page == 1) ?
                            <ActivityIndicator size={"large"} color={design.primaryColor} style={{ marginTop: 50 }} />
                            :
                            homeInfo.length > 0 ? homeInfo.map((t, index) => (
                                <TournamentCard
                                  key={index}
                                  navigation={navigation}
                                  tournament={t}
                                  onFixturesOpen={this.openFixturesDialog}
                                  onInfoClicked={(id) => {
                                      let content = "";

                                      switch (id) {
                                          case 1: {
                                              content = translations.tKey("str_info_budget");
                                              break;
                                          }
                                          case 2: {
                                              content = translations.tKey("str_info_captain");
                                              break;
                                          }
                                          case 3: {
                                              content = translations.tKey("str_info_vice");
                                              break;
                                          }
                                          default: {
                                            break;
                                          }
                                      }
                                      this.setState({ infoDialogOpen: true, infoDialogContent: content });
                                  }}
                                />
                            )) : (
                            <View style={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: 'center', textAlign: 'center', margin: 'auto', marginTop: 40 }}>
                                <View style={{ ...styles.card }}>
                                    <Text style={{ fontSize: 20 }}>{translations.tKey("str_home_coming_soon")}</Text>
                                    <img src={comingSoonLogo} alt="coming soon"></img>
                                </View>
                            </View>
                        )}
                        {this.props.pages.current_page < this.props.pages.last_page &&
                        <View style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginVertical: 20 }}>
                            {!this.props.isLoading ?
                                <TouchableOpacity onPress={() => {

                                    this.props.getHomeTournamentsInfo(this.state.time.value, this.state.league.id, +this.props.pages.current_page + 1)
                                }}
                                    style={{ borderRadius: 3, backgroundColor: design.primaryColor, paddingVertical: 10, width: "50%", alignItems: 'center', justifyContent: "center", display: 'flex' }}>
                                    <Text style={{ color: "white" }}>{translations.tKey("str_home_tournament_see_more")}</Text>
                                </TouchableOpacity>
                                : <ActivityIndicator size={"large"} color={design.primaryColor} style={{ marginTop: 50 }} />
                            }
                        </View>}
                    </View>
                </View>
            </div >
        );
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getHomeTournamentsInfo: (status, championshipId, page) => dispatch(getHomeTournamentsInfo(status, championshipId, page)),
        getAllChampionShips: () => dispatch(getAllChampionShips()),
        setTeam: (team, isAuto) => dispatch(setTeam(team, isAuto)),
        clearFixtures: () => dispatch(clearFixtures()),
        showConfirmPopup: (title, message, confirmAction) => dispatch(showConfirmPopup(title, message, confirmAction)),
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        homeInfo: state.homeTournaments.tournamentsInfo.tournaments,
        pages: state.homeTournaments.tournamentsInfoPages,
        counts: state.homeTournaments.tournamentsInfo.counts,
        championships: state.homeTournaments.championships,
        isLoading: state.ui.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeTournaments);

const operator = getDomainOperator();

const design = operator.design;

const base = {
    card: {
        minHeight: '50px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        width: '100%',
        backgroundColor: 'white',
        marginTop: '5px',
        marginBottom: '5px',
        padding: '3%',
        justifyContent: 'space-around',
        borderRadius: '3px'
    },
    container: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    grayContainer: {
        width: "100%",
        minHeight: '720px',
        backgroundColor: "#EFEFEF",
        display: 'flex',
        alignItems: "center",
        justifyContent: "flex-start"
    },
    cardsContainer: {
        width: "95%"
    },
    plusSign: {
        backgroundColor: design.primaryColor,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontSize: 14,
        width: "15px",
        height: '15px',
        paddingBottom: "2px",
        borderRadius: 7.5,
        marginTop: "4px"
    },
    tabRow: {
        display: 'flex',
        flexDirection: "row",
        width: '85%'
    },
    tabColumn: {
        display: 'flex',
        width: "88%"
    },
    headerView: {
        backgroundColor: 'white',
        paddingTop: 5,
        paddingBottom: 5,
        width: '48%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
    },
    headerButton: {
        width: "100%",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: 15,
        marginTop: 5,
        marginBottom: 5,
        textAlign: "left"
    },
    roundContainer: {
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "space-between"
    },
    headerContainer: {
        width: "95%",
        marginLeft: '2.5%',
        height: "120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    filtersContainer: {
        width: "100%",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    pressedTab: {
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundColor: "#EFEFEF",
        paddingTop: "10px",
        paddingBottom: "2px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px"
    },
    nonPressedTab: {
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundColor: "#02894E",
        paddingTop: "10px",
        paddingBottom: "2px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px"
    },
    pressedTabText: {
        fontSize: 14
    },
    nonPressedTabText: {
        color: '#EFEFEF',
        filter: "grayscale(0.8)",
    },
    tabTextAlignment: {
        textAlign: "center",
        width: "100%"
    }
};

const styles = createStyles(
    base,
    maxWidth(576, {
        tabColumn: {
            width: "95%"
        },
        plusSign: {
            marginTop: "2px"
        },
        pressedTabText: {
            fontSize: 12
        },
        nonPressedTabText: {
            fontSize: 12
        }
    }),
    maxWidth(376, {
        tabColumn: {
            width: "92%"
        },
        plusSign: {
            marginTop: 0,
        },
        pressedTabText: {
            fontSize: 12
        },
        nonPressedTabText: {
            fontSize: 12
        }
    }),
    maxWidth(321, {
        tabColumn: {
            width: "92%"
        },
        plusSign: {
            marginTop: 0,
        },
        pressedTabText: {
            fontSize: 9
        },
        nonPressedTabText: {
            fontSize: 9
        }
    })
)
