import { 
    GET_SUBTOURNAMENT_SCORING,
    GET_SUBTOURNAMENT_DETAILS,
    GET_PLAYER_INFO,
    CLEAR_TOURNAMENT_ENTERED,
    ENTER_SUB_TOURNAMENT,
    GET_TOURNAMENT_FOR_TRANSFER,
    CLEAR_TOURNAMENT_FOR_TRANSFER,
    CLEAR_TEAM_EDIT,
    SET_TEAM_EDIT,
    SET_TEAM_ERRORS
   } from "../actions/actionTypes";
  
  const initialState = {
      scoring: null,
      details: null,
      playerInfo: null,
      tournamentEntered: false,
      tournament: null,
      teamEditted: false,
      errorTeam: false,
      errorCaptain: false,
      errorVice: false
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SUBTOURNAMENT_SCORING: {
        return {
          ...state,
          scoring: action.info
        };
      }

      case GET_SUBTOURNAMENT_DETAILS: {
        return {
          ...state,
          details: action.data
        };
      }
      case GET_PLAYER_INFO: {
        return {
          ...state,
          playerInfo: action.data
        }
      }
      case ENTER_SUB_TOURNAMENT: {
        return {
          ...state,
          tournamentEntered: true
        }
      }
      case CLEAR_TOURNAMENT_ENTERED: {
        return {
          ...state,
          tournamentEntered: false
        }
      }
      case GET_TOURNAMENT_FOR_TRANSFER: {
        return {
          ...state,
          tournament: action.data
        }
      }
      case CLEAR_TOURNAMENT_FOR_TRANSFER: {
        return {
          ...state,
          tournament: null
        }
      }
      case SET_TEAM_EDIT: {
        return {
          ...state,
          teamEditted: true
        }
      }
      case CLEAR_TEAM_EDIT: {
        return {
          ...state,
          teamEditted: false
        }
      }
      case SET_TEAM_ERRORS: {
        return {
          ...state,
          errorName: action.errorName,
          errorCaptain: action.errorCaptain,
          errorTeam: action.errorTeam,
          errorVice: action.errorVice
        }
      }
      default:
        return state;
    }
  };
  
  export default reducer;