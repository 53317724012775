import { SET_LEAGUE_RANKING } from "../actions/actionTypes";
import { SET_ROOM_INFO } from '../../SearchRoom/actions/actionTypes';
 
const initialState = {
    leagueRanking: null,
    currentPage: 0,
    currentLeagueId: 0,
    loggedUser: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROOM_INFO:
      return {
        ...state,
        leagueRanking: null,
        currentPage: 0,
        currentLeagueId: 0,
        loggedUser: null
      };

    case SET_LEAGUE_RANKING:
      let leagueRanking = {...state.leagueRanking};
      if (action.id === state.currentLeagueId) {
        leagueRanking.rankings = [...state.leagueRanking.rankings, ...action.leagueRanking.rankings];
      } else {
        leagueRanking = action.leagueRanking;
      }
      return {
        ...state,
        leagueRanking,
        currentLeagueId: action.id,
        currentPage: action.id === state.currentLeagueId ? state.currentPage+1 : 1,
        loggedUser: action.leagueRanking.loggedUser
      };
      
    default:
      return state;
  }
};

export default reducer;