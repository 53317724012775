import * as React from "react";

function SvgUser(props) {
  return (
    <svg viewBox="0 0 13.951 16.479" {...props}>
      <defs>
        <style>{".user_svg__a{fill:#bebebe}"}</style>
      </defs>
      <ellipse
        className="user_svg__a"
        cx={2.834}
        cy={2.705}
        rx={2.834}
        ry={2.705}
        transform="translate(4.17)"
      />
      <path
        className="user_svg__a"
        d="M13.951 9.471l-3.395-3.394H8.751c0 .292-.795.529-1.775.529s-1.775-.237-1.775-.529H3.395L0 9.471l1.83 1.83 1.564-1.564v6.74h7.163V9.735l1.564 1.564z"
      />
    </svg>
  );
}

export default SvgUser;
