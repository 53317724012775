import { 
  SET_TEAM_FOR_TRANSFERS, 
  SET_AVAILABLE_PLAYERS_FOR_TRANSFER,
  REMOVE_AVAILABLE_PLAYER_FOR_TRANSFER,
  RESTORE_AVAILABLE_PLAYER_FOR_TRANSFER,
} from "../actions/actionTypes";
 
const initialState = {
    userTeam: null,
    availablePlayers: [] 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM_FOR_TRANSFERS:
      return {
        ...state,
        userTeam: action.team
      };
    case SET_AVAILABLE_PLAYERS_FOR_TRANSFER: 
      return {
        ...state,
        availablePlayers: action.players
      }
    case REMOVE_AVAILABLE_PLAYER_FOR_TRANSFER: 
    return {
        ...state,
        availablePlayers: state.availablePlayers.filter(p => p.playerId !== action.playerId)
      };
    case RESTORE_AVAILABLE_PLAYER_FOR_TRANSFER:
    return {
        ...state,
        availablePlayers: [action.player, ...state.availablePlayers]
      }; 
    default:
      return state;
  }
};

export default reducer;