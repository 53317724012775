import React from 'react';
import { View, Text } from 'react-native';
import moment from 'moment';
import { createStyles } from 'react-native-media-queries';
import { numberWithCommas } from '../../Common/utils/utils';
import * as translations from '../../Common/utils/translations';

class TournamentOverview extends React.Component {

  getRowComponent = (name, value) => {
    return <View style={styles.nameValueRow}>
      <View style={styles.nameRow}>
        <Text style={styles.textNormal}>{name}</Text>
      </View>
      <View style={styles.valueRow}>
        <Text style={styles.textNormal}>{value}</Text>
      </View>
    </View>;
  };

  render() {
    const {overView} = this.props;
    let seasonal_start = new Date(2020, 9, 2);
    let seasonal_end = new Date(2021, 4, 31);

    if (!overView) {
      return null;
    }
    let maxlimit = 40;
    return (
      <View style={styles.container}>
        {this.getRowComponent(translations.tKey('str_overview_name'), <Text>{ ((overView.name).length > maxlimit) ? 
    (((overView.name).substring(0,maxlimit-3)) + '...') : 
    overView.name }</Text>)}
        {this.getRowComponent(translations.tKey('str_overview_creator'), overView.creator)}
        
        {
        /* 
        {this.getRowComponent(translations.tKey('str_overview_start_date'), moment(overView.start_date).format('D MMM YYYY'))}
        {this.getRowComponent(translations.tKey('str_overview_end_date'), moment(overView.end_time).format('D MMM YYYY'))} */}

        {overView.is_seasonal_game ? this.getRowComponent(translations.tKey('str_overview_start_date'), moment(seasonal_start).format('D MMM YYYY')) : this.getRowComponent(translations.tKey('str_overview_start_date'), moment(overView.start_date).format('D MMM YYYY'))}
        {overView.is_seasonal_game ? this.getRowComponent(translations.tKey('str_overview_end_date'), moment(seasonal_end).format('D MMM YYYY')) :  this.getRowComponent(translations.tKey('str_overview_end_date'), moment(overView.end_time).format('D MMM YYYY'))}

        {overView.entry_fee && this.getRowComponent(translations.tKey('str_overview_buy_in'), `${overView.entry_fee.toFixed(2)}${translations.tKey('str_currency')}`)}
        {!!overView.total_prize_pool && overView.total_prize_pool !== 0 && this.getRowComponent(translations.tKey('str_overview_prize_pool'),
          `${numberWithCommas(overView.total_prize_pool.toFixed(2))}${translations.tKey('str_currency')}`)}
        {this.getRowComponent(translations.tKey('str_overview_entrants'), overView.current_players)}
      </View>
    );
  }
};


export default TournamentOverview;

const base = {

  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  nameValueRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textNormal: {
    fontSize: 14
  },
  nameRow: {
    paddingVertical: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  valuesRow: {
    paddingVertical: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
};

const styles = createStyles(base);

