import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { useStyles } from './Pagination.styles';
import { isMobile } from 'react-device-detect';

export const Pagination = ({ onPageClick, currentPage, isSeasonal, onLeftClick, onRightClick, ...props }) => {
  const classes = useStyles();
  const pageBlocks = [];
  let initialPixels = isMobile ? 645 : 595;
  let diff;
  let pixels;
  let maxLeft = diff*60;

  diff = currentPage-8;
  
  pixels = initialPixels - (diff*60) + (props.inputPixels);
  if(maxLeft == pixels) {
    return;
  }
  
  for (let i = 8; i <= props.count; i++) {
    let page = i;

    if(page <= 0) {
      i--;
    }

    if (page > 0 && page - 1 < props.count) {
     
      pageBlocks.push(
        <div
          onClick={() => onPageClick(page)}
          className={clsx(
            classes.page,
            page > props.currentRound && classes.upcoming,
            (page == props.actualRoundSelected && page !== currentPage) && classes.actualRoundSelected,
            page == currentPage ? classes.currentPageContainer : classes.pageContainer
          )}>
          {page}
        </div>
      );
    }
  }

  return (
    <div style={props.ContainerStyles} className={classes.container}>
      <div className={classes.innerContainer}>
        {currentPage > 1 && (
          <div
            className={clsx(classes.pageContainer, classes.previousButton, classes.page)}
              onClick={() => onLeftClick()}
          >
            <NavigateBeforeIcon />
          </div>
        )}
        <div className={classes.roundsContainer} style={{transform: `translate( ${pixels}px)` }}>
          {pageBlocks}
        </div>

        {currentPage < props.count && (
          <div
            className={clsx(classes.pageContainer, classes.nextButton, classes.page)}
              onClick={() => onRightClick()}
          >
            <NavigateNextIcon />
          </div>
        )}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,

  currentRound: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,

  onPageClick: PropTypes.func.isRequired,

  itemCount: PropTypes.number,

  ContainerStyles: PropTypes.object
};

Pagination.defaultProps = {
  itemCount: 7
};