import * as React from "react";

function SvgPoints(props) {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M8 0a8 8 0 108 8 8 8 0 00-8-8zm4.343 7.146l-1.8 1.758a.335.335 0 00-.1.3l.426 2.481a.334.334 0 01-.485.353l-2.228-1.172a.334.334 0 00-.311 0l-2.228 1.172a.334.334 0 01-.485-.353l.426-2.481a.335.335 0 00-.1-.3l-1.8-1.758a.334.334 0 01.185-.57l2.491-.362a.333.333 0 00.252-.183L7.7 3.773a.335.335 0 01.6 0l1.114 2.258a.333.333 0 00.252.183l2.491.362a.334.334 0 01.186.57z"
        fill="#bebebe"
      />
    </svg>
  );
}

export default SvgPoints;
