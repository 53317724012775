import React from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import Background from '../Common/components/Background';
import logo from '../assets/login-bam-logo.png';
import palmsBet from '../assets/palmsbet.png';

const ChooseRound = ({ navigation }) => {
  return (
    <View style={styles.container}>
        <Background />

        <TouchableOpacity style={styles.headerView}>
          <View style={styles.roundContainer}>
              <Text style={styles.headerText}>КРЪГ 21 </Text>
          </View>
        </TouchableOpacity>

        <View style={{flexDirection: 'row', width: '100%', marginVertical: 30}}>
            <Image style={styles.logo} source={logo} />
            <Image source={palmsBet} style={{width: 100, height: 100}} />
        </View>

        <View style={{width: '100%', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <Text style={[styles.subText, {width: '80%'}]}>1. Избери залог и формат на стая</Text>
          <Text style={[styles.subText, {width: '80%'}]}>2. Направи си отбор</Text>
          <Text style={styles.mainText}>3. ИГРАЙ</Text>
        </View>
        <TouchableOpacity style={styles.playButton} 
                onPress={() => navigation.navigate("ChooseBet")}>
            <Text style={styles.buttonText}>ВЛЕЗ В НОВА СТАЯ</Text>
        </TouchableOpacity>
    </View>
  );
};

export default ChooseRound;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerView: {
    backgroundColor: '#CCD4E1',
    paddingVertical: 5,
    paddingHorizontal: 30,
    width: '90%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 15
  },
    headerText: {
    fontWeight: 'bold',
    fontSize: 25,
    marginVertical: 5
  },
  headerInfo: {
    flexDirection: 'row'
  },
  headerInfoText: {
    marginHorizontal: 15,
    fontSize:20
  },
  roundContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: 15
  },
  logo: {
    width: '60%',
    height: 110,
    resizeMode: 'contain',
  },
  playButton: {
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    width: '70%',
    backgroundColor: '#72E86C',
    paddingVertical: 20,
    paddingHorizontal: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 22,
    textAlign: 'center'
  },
  subText: {
    marginVertical: 7,
    color: '#A3A5A9',
    fontSize: 22,
    textAlign: 'center'
  },
  emphasis: {
    marginVertical: 7,
    color: '#C5A150',
    fontSize: 22,
    textAlign: 'center',
    width: '70%'
  },
  mainText: {
    marginVertical: 7,
    color: 'white',
    fontSize: 36,
    textAlign: 'center'
  }
});


