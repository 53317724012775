import React from "react";
import { View, StyleSheet, Image, Text } from "react-native";
import Background from '../Common/components/Background';
import palmsBet from '../assets/palmsbet.png';
import { searchRoom } from './actions/rooms';
import { connect } from 'react-redux';
import { getTeam } from '../Team/actions/team';

class SearchRoom extends React.Component {


  componentDidMount() {
    const {navigation} = this.props;
    const bet = navigation.getParam("bet", "0");
    const maxPlayers = navigation.getParam("roomMaxPlayers", "10");

    this.props.searchRoom(bet, maxPlayers);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.foundRoom !== this.props.foundRoom) {      
      this.props.navigation.navigate('FoundRooms');
    }
  }

  render() {
      return (
        <View style={styles.container}>
            <Background />
            <Image source={palmsBet} style={styles.image} />
            <View style={styles.textContainer}>
                <Text style={styles.mainText}>Изчакайте,</Text>
                <Text style={styles.mainText}>търсим Ви стая...</Text>
            </View>
        </View>
      );
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    searchRoom: (bet, maxPlayers) => dispatch(searchRoom(bet, maxPlayers)),
    getTeam: () => dispatch(getTeam())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    foundRoom: state.room.foundRoom
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchRoom);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  textContainer: {
      width: '80%',
      marginTop: 20
  },
  mainText: {
      color: '#D0D2D3',
      fontWeight: 'bold',
      fontSize: 20,
      textAlign: 'center'
  },
  image: {
      width: 100,
      height: 100,
      marginTop: 200
  }
});