import * as React from "react";

function SvgNumber(props) {
  return (
    <svg viewBox="0 0 14.645 16.217" {...props}>
      <path
        d="M7.076 16.217h2.493l.854-4.268h2.729l.449-2.426h-2.707l.618-3.032h2.684l.449-2.403h-2.65L12.815 0h-2.494l-.82 4.088H6.593L7.402 0H4.931L4.1 4.088H1.495l-.46 2.4h2.583L3.012 9.52H.463l-.46 2.426H2.53l-.845 4.271h2.471l.865-4.268H7.93zM5.29 9.737l.7-3.448h3.234l-.711 3.448z"
        fill="#bebebe"
      />
    </svg>
  );
}

export default SvgNumber;
