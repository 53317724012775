import * as React from "react";

function SvgIcPrizepool(props) {
  return (
    <svg viewBox="0 0 21.646 13.367" {...props}>
      <defs>
        <style>{".ic_prizepool_svg__a{fill:#929292}"}</style>
      </defs>
      <path
        className="ic_prizepool_svg__a"
        d="M14.763 4.611V0H6.88v2.984H-.002v10.383h21.646V4.611zM.998 12.367V3.984h5.88v8.383zm12.763 0h-5.88V1h5.883zm6.883 0h-5.883V5.611h5.883z"
      />
      <path
        className="ic_prizepool_svg__a"
        d="M10.666 3.083v2.946h.959V2.236h-.962l-.965.657v.831l.949-.641zM3.777 8.18v-.019l.907-.835a1.726 1.726 0 00.683-1.165c0-.665-.559-1.127-1.421-1.127a1.31 1.31 0 00-1.478 1.261v.019h.884v-.019a.53.53 0 01.562-.536.465.465 0 01.52.434c0 .236-.115.42-.431.723L2.524 8.277v.644h2.891V8.18zM17.692 9.848c-.368 0-.6-.181-.613-.465h-.92a1.343 1.343 0 001.517 1.222c.943 0 1.574-.449 1.574-1.146a.908.908 0 00-.928-.909v-.021a.888.888 0 00.789-.876c0-.625-.555-1.03-1.4-1.03-.881 0-1.467.481-1.48 1.212h.873a.514.514 0 01.565-.494c.347 0 .546.17.546.444s-.213.454-.538.454h-.46v.665h.462c.368 0 .592.179.592.473s-.235.471-.579.471z"
      />
    </svg>
  );
}

export default SvgIcPrizepool;
