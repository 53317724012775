import { 
  SET_TEAM_FOR_TRANSFERS, 
} from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setError } from '../../Common/actions/errors';

export const getTeam = () => {
  return (dispatch,getState) => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
    })
    .then(token => {
        return fetch(BASE_URL + '/users/transfers', {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
        if (res.has_error && res.status === 503) {
          dispatch(setError('Кръга е затворен!', 503));
          return;
        }
        const userTeam = res.data;
        userTeam.groups.forEach(g => {
            g.players.forEach(p => {
            p.iconUrl = BASE_URL + p.iconUrl;
            p.realPlayerId = p.id;
            });
        });
        userTeam.reserves.forEach(p => {
            p.iconUrl = BASE_URL + p.iconUrl;
            p.realPlayerId = p.id;
        });
      
        dispatch(setTeam(userTeam));
    });
  };
}

export const setTeam = team => {
  return {
    type: SET_TEAM_FOR_TRANSFERS,
    team
  };
};