import {
    UI_START_LOADING,
    UI_STOP_LOADING,
    LEADERBOARD_START_LOADING,
    LEADERBOARD_STOP_LOADING,
    TEAM_START_LOADING,
    TEAM_STOP_LOADING
} from './actionTypes';

export const leaderBoardStartLoading = () => {
    return {
        type: LEADERBOARD_START_LOADING
    };
};

export const leaderBoardStopLoading = () => {
    return {
        type: LEADERBOARD_STOP_LOADING
    };
};

export const uiStartLoading = () => {
    return {
        type: UI_START_LOADING
    };
};

export const uiStopLoading = () => {
    return {
        type: UI_STOP_LOADING
    };
};

export const teamStartLoading = () => {
    return {
        type: TEAM_START_LOADING
    };
};

export const teamStopLoading = () => {
    return {
        type: TEAM_STOP_LOADING
    };
};