import { 
  SET_PREVIOUS_TEAM, 
} from "../actions/actionTypes";
 
const initialState = {
    userTeam: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_PREVIOUS_TEAM:
      return {
        ...state,
        userTeam: action.team
      };

    default:
      return state;
  }
};

export default reducer;