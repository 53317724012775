import { 
    SET_ROOM_INFO, 
    SET_FOUND_ROOM
} from "../actions/actionTypes";
  
const initialState = {
    roomInfo: null,
    foundRoom: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_ROOM_INFO:
        return {
          ...state,
          roomInfo: {...action.info},
          foundRoom: null,
        };
      case SET_FOUND_ROOM: {
        return {
          ...state,
          foundRoom: action.room
        }
      }
      default: return state;
    }
};

export default reducer;