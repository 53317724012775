import React from 'react';
import PropTypes from 'prop-types';

import * as Icons from './Icons';

export const Icon = ({icon, size, ...props}) => {
	switch (icon) {
		case 'difficulty':
			return <Icons.Difficulty width={size} height={size} {...props} />;
		case 'form':
			return <Icons.Form width={size} height={size}{...props} />;
		case 'number':
			return <Icons.Number width={size} height={size}{...props} />;
		case 'user':
			return <Icons.User width={size} height={size}{...props} />;
		case 'prizePool':
			return <Icons.Prizepool width={size} height={size}{...props} />;
		case 'points':
			return <Icons.Points width={size} height={size}{...props} />;
		case 'doublePoints':
			return <Icons.Pointspp width={size} height={size}{...props} />;
		case 'prize':
			return <Icons.Price width={size} height={size}{...props} />;
		case 'legend':
			return <Icons.Legend width={size} height={size} {...props} />
		case 'info':
			return <Icons.Info width={size} height={size} {...props} />
		case 'player':
			return <Icons.Player width={size} height={size} {...props} />
		case 'arrow':
			return <Icons.Arrow width={size} height={size} {...props} />

		default:
			console.error('Icon not found!', icon);

			return null;
	}
};

Icon.propTypes = {
	icon: PropTypes.oneOf([
		'difficulty',
		'form',
		'info',
		'legend',
		'number',
		'player',
		'points',
		'doublePoints',
		'prize',
		'prizePool',
		'user',
		'arrow',
		'check',
		'cross'
	]),
	size: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	color: PropTypes.string
};

Icon.defaultProps = {
	size: 16,
	color: '#fff'
};