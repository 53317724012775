import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  Button,
  TouchableOpacity,
  ScrollView,
  FlatList
} from 'react-native';
import PlayerPopup from '../../Transfers/components/PlayerPopup';
import PlayerView from '../../Common/components/PlayerView';
import LoadingScreen from '../../Common/components/LoadingScreen';
import BudgetContainer from '../../Common/components/BudgetContainer';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import budgetIcon from '../../assets/budget-transfers.png';
import { connect } from 'react-redux';
import {
  getAvailablePlayers,
  removeAvailablePlayer,
  restoreAvailablePlayer,
  setGroup,
  sortPlayers
} from '../actions/team';
import { setBudget } from '../../Home/actions/home';
import banIcon from '../../assets/banned-player.svg.png';
import injuredIcon from '../../assets/injured-player.svg.png';
import transferedIcon from '../../assets/transfered-player.svg.png';


class SelectPlayer extends React.Component {

  state = {
    selectedPlayerId: null,
    currentSort: null
  };

  static navigationOptions = {
    title: 'Създаване на отбор'
  };

  onSelectForBuy = () => {
    this.setState(prevState => {
      const playerToBuy = this.props.availablePlayers.find(
        p => p.playerId === prevState.selectedPlayerId);
      playerToBuy.name = playerToBuy.name.split(' ')[1];
      return {
        selectedForBuy: playerToBuy,
        selectedPlayerId: null
      };
    });
  };

  showPlayerPopup = player => {
    this.setState({selectedPlayerId: player.playerId});
  };

  hidePlayerPopup = () => {
    this.setState({selectedPlayerId: null});
  };

  scrollHeader = event => {
    const xOffset = event.nativeEvent.contentOffset.x;
    this.headerRef.scrollTo({x: xOffset, y: 0, animated: false});
  };

  componentDidMount() {
    const group = JSON.parse(sessionStorage.getItem('currentGroup'));
    this.state.group = group;
    this.state.oldBudget = this.props.budget;

    this.props.getAvailablePlayers(group.groupId);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.availablePlayers) return;
    const group = this.state.group;
    group.players.forEach(p => {
      if (p && this.props.availablePlayers.find(p1 => p1.playerId === p.playerId)) {
        this.props.removeAvailablePlayer(p.playerId);
      }
    });
  }

  changeSort = criteria => {
    let {currentSort} = this.state;

    if (currentSort && currentSort.criteria == criteria) {
      currentSort = {...currentSort};
      currentSort.order = currentSort.order == 'asc' ? 'desc' : 'asc';
    } else {
      currentSort = {criteria: criteria, order: 'asc'};
    }

    this.setState({currentSort});
    this.props.sortPlayers(currentSort);
  };

  getSortIcon = criteria => {
    if (!this.state.currentSort)
      return String.fromCharCode(9660);
    if (this.state.currentSort.criteria === criteria && this.state.currentSort.order == 'asc') {
      return String.fromCharCode(9650);
    }
    return String.fromCharCode(9660);
  };

  renderRenderTableRow = ({item}) => {
    return (
      <TouchableOpacity style={styles.tableRow}
                        onPress={() => this.showPlayerPopup(item)}>
        <View style={styles.playerNameContainer}>
          <Image source={{uri: item.iconUrl}} style={styles.iconStyle}/>
          {item.isBanned && <Image source={banIcon} style={styles.specialIconStyle}/>}
          {item.isInjured && <Image source={injuredIcon} style={styles.specialIconStyle}/>}
          {item.isTransfered && <Image source={transferedIcon} style={styles.specialIconStyle}/>}

          <View style={styles.playerForm}>
            <Text>{item.name}</Text>
            <Text style={{color: '#9497A7'}}>{item.team}</Text>
          </View>
        </View>
        <View style={[styles.tableColumn, {backgroundColor: 'white'}]}>
          <Text>{item.shape}</Text>
        </View>

        <View style={[styles.tableColumn, {backgroundColor: 'white'}]}>
          <Text>{item.palms_price}</Text>
        </View>
        <View style={[styles.tableColumn, {backgroundColor: 'white'}]}>
          <Text>{item.points}</Text>
        </View>
      </TouchableOpacity>);
  };

  onSelectPlayer = () => {
    const player = this.props.availablePlayers.find(p => p.playerId === this.state.selectedPlayerId);
    if (!player) return;
    const group = {...this.state.group};
    let index = 0;
    while (group.players[index] !== null && index < group.players.length) {
      index++;
    }

    if (index === group.players.length) {
      alert('Няма свободни позиции');
    } else {
      const {teamId} = player;
      var count = 0;
      this.props.userTeam.groups.forEach(g => {
        if (g.groupId === group.groupId) {
          group.players.forEach(p => {
            if (p && p.teamId === player.teamId) count++;
          });
        } else {
          g.players.forEach(p => {
            if (p && p.teamId === player.teamId) count++;
          });
        }
      });

      // if (count >= this.props.championshipInfo.user_team_max_players_from_same_team) {
      //     alert('Максимум ' + this.props.championshipInfo.user_team_max_players_from_same_team + "-ма играчи от един и същ отбор!" );
      // } else {
      group.players[index] = player;
      this.props.removeAvailablePlayer(player.playerId);
      this.setState({group});
      this.props.setBudget(this.props.budget - player.palms_price);
      //}
    }
    this.setState({selectedPlayerId: null});
  };

  restorePlayer = player => {
    if (player !== null) {
      this.props.restorePlayer(player);
      const group = {...this.state.group};
      const index = group.players.findIndex(p => p && p.playerId === player.playerId);
      group.players[index] = null;
      this.setState({group});
      this.props.setBudget(this.props.budget + player.palms_price);
    }
  };

  setGroup = () => {
    this.props.setGroup(this.state.group);
    this.props.navigation.goBack();
  };

  render() {
    const {navigation, isLoading} = this.props;

    if (isLoading) {
      return <LoadingScreen/>;
    }

    const group = this.state.group || JSON.parse(sessionStorage.getItem('currentGroup'));
    return (
      <React.Fragment>
        {this.state.selectedPlayerId &&
        <PlayerPopup
          playerId={this.state.selectedPlayerId}
          onDismiss={this.hidePlayerPopup}
          navigation={this.props.navigation}
          onConfirm={this.onSelectPlayer}
          buttonText="ИЗБЕРИ"/>
        }
        <View style={styles.container}>
          <HeaderWithBack
            title={'Избор на играчи'}
            navigation={navigation}
            onBackPressed={() => this.props.setBudget(this.state.oldBudget)}
          />
          <View style={styles.headerView}>
            <Text style={styles.positionText}>{group.name}</Text>
            <BudgetContainer budget={this.props.budget}/>
          </View>
          <View style={styles.transferContainer}>
            {group.players.map((player, i) => (
              <PlayerView
                key={i * (Math.random() * 10000)}
                containerStyle={{width: '18%'}}
                iconStyle={styles.iconStyle}
                showPrice={true}
                nullPlayer={player === null}
                canRemoveFromList={player !== null}
                onPress={() => this.restorePlayer(player)}
                {...player}/>
            ))}
          </View>
          <ScrollView style={styles.tableHeader}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      ref={ref => this.headerRef = ref}>
            <TouchableOpacity onPress={() => this.changeSort('name')}>
              <Text style={styles.playerNameHeader}>
                {'Име на играч' + this.getSortIcon('name')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.changeSort('shape')}>
              <Text style={styles.headerText}>{'Форма' + this.getSortIcon('shape')}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.changeSort('price')}>
              <Text style={styles.headerText}>{'Цена' + this.getSortIcon('price')}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.changeSort('points')}>
              <Text style={styles.headerText}>{'Точки' + this.getSortIcon('points')}</Text>
            </TouchableOpacity>
          </ScrollView>
          <ScrollView horizontal={true}
                      onScroll={this.scrollHeader}
                      onScrollEndDrag={this.scrollHeader}
                      scrollEventThrottle={160}>
            <FlatList
              data={this.props.availablePlayers}
              renderItem={this.renderRenderTableRow}
              keyExtractor={(item, index) => '' + item.playerId}/>
          </ScrollView>
          <TouchableOpacity onPress={this.setGroup} style={styles.confirmButton}>
            <Text style={styles.buttonText}>ДОБАВИ В ОТБОР</Text>
          </TouchableOpacity>
        </View>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAvailablePlayers: positionId => dispatch(getAvailablePlayers(positionId)),
    removeAvailablePlayer: playerId => dispatch(removeAvailablePlayer(playerId)),
    restorePlayer: player => dispatch(restoreAvailablePlayer(player)),
    setGroup: group => dispatch(setGroup(group)),
    setBudget: budget => dispatch(setBudget(budget)),
    sortPlayers: criteria => dispatch(sortPlayers(criteria))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    availablePlayers: state.userTeam.availablePlayers,
    userTeam: state.userTeam.userTeam,
    championshipInfo: state.championship.championshipInfo,
    budget: state.userInfo.budget,
    isLoading: state.ui.isLoading
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SelectPlayer);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    width: '100%',
    flex: 1
  },
  headerView: {
    height: 70,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#CCD4E1'
  },
  headerText: {
    fontSize: 17,
    margin: 5
  },
  headerIcon: {
    width: 20,
    height: 20,
    marginRight: 2
  },
  specialIconStyle: {
    width: 20,
    height: 20,
    top: -10,
    right: 40,
    zIndex: 3,
    marginRight: -20
  },
  statWithIcon: {
    flexDirection: 'row'
  },
  statContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  positionText: {
    marginLeft: 10,
    flex: 3,
    fontSize: 20
  },
  statText: {
    fontWeight: 'bold',
    fontSize: 20,
    color: '#DEC84B'
  },
  statDescription: {
    fontSize: 14,
    color: '#69D565'
  },
  teamContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    width: 130
  },
  iconStyle: {
    width: 60,
    height: 80,
    marginHorizontal: 25,
    marginBottom: 10
  },
  transferContainer: {
    width: '100%',
    backgroundColor: '#E5EAF3',
    flexDirection: 'row'
  },
  tableHeader: {
    minHeight: 45,
    paddingVertical: 10,
    backgroundColor: '#CCD4E1'
  },
  headerText: {
    flex: 1,
    textAlign: 'center',
    width: 100
  },
  playerNameHeader: {
    textAlign: 'center',
    width: 250
  },
  playerNameContainer: {
    flexDirection: 'row',
    backgroundColor: '#D9DDF5',
    borderRightWidth: 1,
    borderColor: '#C1C6DF',
    width: 250
  },
  tableRow: {
    backgroundColor: 'white',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#C1C6DF'
  },
  playerForm: {
    height: 80,
    paddingVertical: 10,
    justifyContent: 'space-between'
  },
  tableColumn: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 100
  },
  confirmButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 50,
    backgroundColor: '#72E86B',
    borderRadius: 10,
    left: '25%',
    bottom: 20,
    position: 'absolute'
  }
});