import { GET_MATCH_HIGHLIGHTS } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { uiStartLoading, uiStopLoading } from "../../Common/actions/ui";
import { showConfirmPopup } from "../../ConfirmPopup/actions/confirmPopup"
import * as translations from "../../Common/utils/translations"

export const getMatchHighlights = (id) => {

  return dispatch => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
        dispatch(uiStopLoading())

      })
      .then(token => {
        return fetch(BASE_URL + `/palms/games/highlights/${id}`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err)
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setMatchHighlights(res.data))
        }
      });
  };
}

export const setMatchHighlights = data => {
  return {
    type: GET_MATCH_HIGHLIGHTS,
    data
  };
};