import {
    GET_TOURNAMENT_OVERVIEW,
    GET_TOURNAMENT_RULES,
    GET_TOURNAMENT_FIXTURES,
    GET_TOURNAMENT_ENTRIES,
    GET_TOURNAMENT_PRIZES,
    CLEAR_FIXTURES
} from "../actions/actionTypes";

const initialState = {
    overView: null,
    rules: null,
    fixtures: null,
    entries: null,
    prizes: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOURNAMENT_OVERVIEW:
            return {
                ...state,
                overView: action.data,
                rules: null,
                fixtures: null
            };
        case GET_TOURNAMENT_RULES:
            return {
                ...state,
                rules: action.data,
                fixtures: action.shouldKeepFixtures ? state.fixtures : null,
                prizes: null,
                entries: null
            };
        case GET_TOURNAMENT_FIXTURES:
            return {
                ...state,
                fixtures: action.data,
                rules: null,
                prizes: null,
                entries: null
            };
        case GET_TOURNAMENT_ENTRIES:
            return {
                ...state,
                entries: action.data,
                rules: null,
                fixtures: null,
                prizes: null,
            };
        case GET_TOURNAMENT_PRIZES:
            return {
                ...state,
                entries: null,
                rules: null,
                fixtures: null,
                prizes: action.data
            }
            case CLEAR_FIXTURES: {
                return {
                    ...state,
                    fixtures: null
                }
            }
        default:
            return state;
    }
};

export default reducer;