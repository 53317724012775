import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { getHomeInfo } from '../../Home/actions/home';
 
class RoomsPopup extends React.Component { 

    componentDidMount() {
      this.props.getHomeInfo(this.props.currentRoundId);
    }

    render() {
      const { rooms } = this.props.userInfo;
      const { onClose, open } = this.props;

      if (!rooms) return null;
      
      return(
        <Dialog onClose={onClose} aria-labelledby="dialog-title" open={open}
        style={{maxHeight: 500}}>
            <DialogTitle id="dialog-title">Изберете стая</DialogTitle>
            <div>
              <List>
                {rooms.map(room => (
                  <ListItem button onClick={() => onClose(room)} key={""+room.id}>
                    <ListItemText primary={room.title} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Dialog>
      )
    }   
 }

 const mapStateToProps = (state, ownProps) => {
     return {
         userInfo: state.userInfo.info,
         currentRoundId: state.rounds.currentRoundId
     }
 }

 const mapDispatchToProps = (dispatch, ownProps) => {
   return {
     getHomeInfo: roundId => dispatch(getHomeInfo(roundId))
   }
 }
 
 export default connect(mapStateToProps, mapDispatchToProps)(RoomsPopup);