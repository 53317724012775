import { makeStyles } from '@material-ui/core/styles';
import { getDomainOperator } from '../../utils/domainMapper';

const operator = getDomainOperator();

const design = operator.design;

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '90%',


  },

  innerContainer: {
    // margin: '0 10px',
    width: '100%',
    overflow: 'hidden',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  roundsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // transform: window.innerWidth >= 768 ? 'translate(555px)' : 'translate(610px)',
  },

  pageContainer: {
    margin: '0px 2px',
    padding: '20px',
    backgroundColor: '#E2E2E2',
    cursor: 'pointer'
  },

  nextButton: {
    backgroundColor: '#EFEFEF',
    position: 'absolute',
    right: -5,
    zIndex: 9999
  },

  previousButton: {
    backgroundColor: '#EFEFEF',
    position: 'absolute',
    left: -5,
    display: 'inline-block',
    zIndex: 9999
  },

  upcoming: {
    backgroundColor: design.primaryColor,
    color: '#fff'
  },

  currentPageContainer: {
    padding: '22.5px 20px',
    color: design.textColorPrimary,
    backgroundColor: design.secondaryColor,
    marginLeft: window.innerWidth > 768 ? '0px' : '0px',
  },

  actualRoundSelected: {
    border: `3px solid ${design.secondaryColor}`,
  },

  page: {
    fontWeight: 700,
    fontFamily: design.textFontFamily,
  }
}));