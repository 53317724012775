import { getDomainOperator } from "./domainMapper";

export function tKey(key) {
    //const language = JSON.parse(localStorage.getItem("language"));
    const language = getDomainOperator().language;
    const lang = translations[language]? translations[language] : translations['en'];
    const value = lang[key] ? lang[key] : translations['en'][key];
    return value;
}


export function tKeyWithText(key, text) {
    const language = JSON.parse(localStorage.getItem("language"));

    let lang = translations[language];

    let string = lang
        ? (lang[key]
            ? lang[key]
            : (translations.en[key]
                ? translations.en[key]
                : key))
        : (translations.bg[key]
            ? translations.bg[key]
            : key);
    return string.replace('$text', text)
}



export function tKeyWithArrayParams(key, params) {
    const language = JSON.parse(localStorage.getItem("language"));

    let lang = translations[language];
    let string = lang
        ? (lang[key]
            ? lang[key]
            : (translations.en[key]
                ? translations.en[key]
                : key))
        : (translations.bg[key]
            ? translations.bg[key]
            : key);
    for (var j = 1; j <= params.length; j++) {
        string = string.replace("{" + j + "}", params[j - 1]);
    }
    return string
}

export const translations = {
    bg: {
        'str_error_no_public_id': 'Unauthorized',
        "str_player_points_dialog_point": "точка",
        "str_player_points_dialog_points": "точки",
        "str_player_points_dialog_no_existing_stats": "Няма статистика",
        "str_tournament_highlights_show_more": "Покажи повече",
        "str_tournament_highlights_match_started": "Мачът е стартирал",
        "str_tournament_highlights_match_ended": "Мачът е завършил",
        "str_tournament_highlights_scoring": "Точкуване",
        "str_tournament_highlights_first_halftime": "Първо полувреме",
        "str_tournament_highlights_second_halftime": "Второ полувреме",
        "str_tournament_highlights_assistance": "Асистенция",
        "str_auth_error": "* Грешка с влизането. Моля опитайте отново.",
        "str_captain_icon": "К",
        "str_vice_captain_icon": "ВК",
        "str_refresh": "Обнови",
        "str_demo_title": "Информация",
        "str_demo_description": "Тази част от демото все още не е налична.",
        "str_error": "Грешка",
        "str_enter_sub_tournament_error_save_budget": "Не можеш да запазиш отбор с отрицателен бюджет.",
        "str_enter_sub_tournament_error_game_started": "Тази игра вече е започнала.",
        "str_enter_sub_tournament_error_enter_game": "За да влезеш в играта",
        "str_enter_sub_tournament_error_name": "- Не можеш да запазиш отбор без име \n",
        "str_enter_sub_tournament_error_player_count": "- Избери пълния брой играчи, нужни за твоя отбор",
        "str_enter_sub_tournament_error_pick_captain": "- Избери капитан",
        "str_enter_sub_tournament_error_pick_vice_captain": "- Избери вице капитан",
        "str_legend_information_dialog_title": "Легенда",
        "str_legend_information_dialog_stats": "Статистика и програма",
        "str_legend_information_dialog_number": "Номер",
        "str_legend_information_dialog_player": "Играч",
        "str_legend_information_dialog_user": "Потребител",
        "str_legend_information_dialog_difficulty": "Трудност на следващ мач",
        "str_legend_information_dialog_form": "Форма (среден брой точки от последните 4 кръга",
        "str_legend_information_dialog_prize_pool": "Награден фонд",
        "str_legend_information_dialog_points": "Точки",
        "str_legend_information_dialog_round_point": "Точки кръг",
        "str_legend_information_dialog_avg_points": "Среден брой точки от играчите, които вече са играли.",
        "str_legend_information_dialog_prize": "Цена/Награда",
        "str_support_description_intro": "Добре дошли в света на дневните и седмичните фентъзи игри! За тези от вас, които още не са запознати с основите на фентъзи игрите, ние сме тук да помогнем.",
        "str_support_description_fantasy_game": "Влизайки в една фентъзи игра, ти поемаш ролята на мениджър на виртуален отбор от играчи по твой избор. Този отбор може да се състои от 5, 6, 11 или дори 15 играчи, а самата игра може да продължи само един мач, кръг или дори цял сезон. Всяка игра също предоставя на мениджърите определен бюджет, с който те трябва да съставят своя отбор. Всеки играч пък получава фентъзи точки на база представянето си в истинския мач или мачове, в които участва. Печели този мениджър, събрал най-много точки в края на играта.",
        "str_support_description_team_selection": "След избиране на отбор и влизане в определена игра, за теб остава само да гледаш мачовете, да се забавляваш и да трупаш точки. Фентъзи игрите са игри на умение, в които футболните знания може да донесат големи печалби. За повече информация около детайлите на точкуването виж секцията „Точкуване.“",
        "str_support_description_contact_base": "Ще се радваме да чуем твоите въпроси и коментари на ",
        "str_support_description_contact_url": "www.ffstars.com",
        "str_support_description_contact_addition": " или на имейл: ",
        "str_support_description_contact_email": "info@ffstars.com",
        "str_support_description_contact_end": " . Надяваме се и ти като нас да се потопиш в невероятното изживяване, наречено фентъзи футбол!",
        "str_support_rules_safe_shift_intro": "Правилото “Спасителни Смени” подсигурява, че ако някой от твоите играчи не започне своя мач като \nтитуляр, то той ще бъде автоматично заменен ако това е възможно.",
        "str_support_rules_safe_shift_applications": "Това правило важи само за игри, в които няма резерви. За да влезе друг играч, то той трябва да:",
        "str_support_rules_safe_shift_applications_1": "е от същия отбор като заменения играч",
        "str_support_rules_safe_shift_applications_2": "играе на същата позиция като заменения играч",
        "str_support_rules_safe_shift_applications_3": "има същата или по-ниска цена като заменения играч",
        "str_support_rules_safe_shift_applications_end": "И трите условия трябва да бъдат изпълнени, иначе правилото няма да влезе в сила и първоначалният избор ще остане. Ако условията са изпълнени, системата ще избере играча с най-висока цена, който отговаря и на трите условия. Няма лимит на броя пъти, в които правилото “Спасителни Смени” може да влиза в сила.",
        "str_support_rules_safe_shift_conditions_intro": "Ако двама или повече потенциални смени струват еднакво, то системата ще избере играч по следните параметри и в следния ред:",
        "str_support_rules_safe_shift_conditions_1": "- форма (играчът с най-много точки в последните няколко мача).",
        "str_support_rules_safe_shift_conditions_2": "- общо точки за сезона",
        "str_support_rules_safe_shift_conditions_3": "- азбучен ред",
        "str_support_rules_safe_shift_conditions_end": "Ако един играч не започне мача, но влезе в последствие, то той пак ще бъде заменен, но само ако неговата \nсмяна от “Спасителни Смени” е направила повече точки от него. От друга страна, ако играчът започне мача но \nв последствие бъде сменен, то тогава правилото “Спасителни Смени” не влиза в сила.",
        "str_support_rules_safe_shift_conditions_captain": "Ако твоят капитан не започне мача и бъде сменен чрез “Спасителни Смени”, то тогава смяната от “Спасителни Смени” ще бъде новият ти капитан. Същото важи и за вицекапитана.",
        "str_tournament_rules_safety_net_open": "Правилото “Спасителни Смени” подсигурява, че ако някой от твоите играчи не започне своя мач като \nтитуляр, то той ще бъде автоматично заменен ако това е възможно. За повече информация, виж секцията \nПравила.",
        "str_tournament_rules_stacking_penalty_open": "Още не е налично",
        "str_home_tournament_see_more": "Виж повече",
        "str_top_bar_home": "Начало",
        "str_top_bar_team": "Отбор",
        "str_top_bar_transfers": "Трансфери",
        "str_top_bar_points": "Точки",
        "str_top_bar_results": "Резултати",
        "str_top_bar_stats": "Статистики",
        "str_header_button_home": "Начало",
        "str_header_button_my_games": "Моите игри",
        "str_header_button_more": "Информация",
        "str_home_tab_official": "Официални",
        "str_home_tab_duels": "Дуели",
        "str_home_tab_users": "Потребителски",
        "str_all_championships": "Всички лиги",
        "str_league_select_title": "Избор на лига",
        "str_league_filter_open": "Отворени",
        "str_league_filter_started": "Стартирали",
        "str_league_filter_ended": "Завършили",
        "str_league_filter_title": "Статус",
        "str_fixtures_dialog_title": "Мачове в тази игра",
        "str_information_dialog_title": "Информация",
        "str_home_empty_state": "В момента няма налични игри.",
        "str_home_coming_soon": "Следващата игра ще бъде обявена когато всички отбори се квалифицират за следващ рунд.",
        "str_coming_soon": "(скоро)",
        "str_buy_ticket": "Купи билет",

        "str_tcard_places_left": " оставащи",
        "str_tcard_hot": "HOT",
        "str_tcard_duration": "Продължителност:",
        "str_tcard_details": "Детайли",
        "str_action_play": "Играй",
        "str_action_play_free": "Играй безплатно",
        "str_tcard_action_view": "Преглед на отбор",
        "str_tcard_player": "участник",
        "str_tcard_players": "участници",
        "str_tcard_places": "места",

        "str_currency": " лв.",

        "str_details_free": "Безплатно",
        "str_details_overview": "Преглед",
        "str_details_fixtures": "Програма",
        "str_details_round": "Кръг",
        "str_details_fixtures_not_started": "Мачът все още не е започнал",
        "str_details_seasonal_game_not_available": "Очаквайте тази функционалност до 25.09",
        "str_details_entries": "Участници",
        "str_details_prizes": "Награди",
        "str_details_rules": "Правила",

        "str_overview_name": "Име",
        "str_overview_creator": "Създател",
        "str_overview_start_date": "Започва",
        "str_overview_end_date": "Завършва",
        "str_overview_buy_in": "Вход",
        "str_overview_prize_pool": "Награден фонд",
        "str_overview_entrants": "Участници",

        "str_entries_all_teams": "Всички отбори:",
        "str_entries_search_user": "Търси потребител",
        "str_entries_table_no": "No",
        "str_entries_table_user": "Потребител",
        "str_entries_table_pts": "Точки",
        "str_entries_table_pts_pp": "Ср. Точки",

        "str_prizes_place": "Място",
        "str_prizes_ratio": "Съотношение",
        "str_prizes_amount": "Размер",
        "str_prizes_prize": "Награда",

        "str_rules_tournament_run": "Продължителност",
        "str_rules_budget": "Бюджет",
        "str_rules_max_players_per_team": "Максимален брой играчи от 1 отбор",
        "str_rules_bench_players": "Резерви",
        "str_rules_score_calc": "Точкуване",
        "str_rules_teams_per_user_allowed": "Максимален брой отбори от 1 потребител",
        "str_rules_prize_distribution": "Разпределение на награди",
        "str_rules_safety_net": "Спасителни Смени",
        "str_rules_stacking_penalty": "Stacking penalty",
        "str_rules_unlimited": "Неограничен",
        "str_rules_ratio": "Спасителни Смени",
        "str_seasonal_detail_rules": "Детайлни правила",

        "str_scoring_dialog_title": "Точкуване",

        "str_enter_game_choose_captain": "Избор на капитан",
        "str_enter_game_choose_vice": "Избор на вицекапитан",
        "str_enter_game_no_players_captain_title": "Няма избрани играчи!",
        "str_enter_game_no_players_captain_description": "Трябва да изберете играч преди да можете да изберете Капитан",
        "str_enter_game_no_players_vice": "Трябва да изберете играч преди да можете да изберете Вице Капитан",
        "str_enter_game_back_button": "Back to lobby",
        "str_enter_game_prizepool": "Награден фонд",
        "str_enter_game_no_limit": "Без лимит",
        "str_enter_game_details": "Детайли",
        "str_enter_game_scoring": "Точкуване",
        "str_enter_game_remaining_budget": "Оставащ бюджет",
        "str_enter_game_players_selected": "Избрани играчи",
        "str_enter_game_autocomplete": "Автоматичен избор",
        "str_enter_game_wildcard": "Уайлдкард",
        "str_enter_game_wildcard_activated": "Уайлдкард използван",
        "str_enter_game_wildcard_active": "Уайлдкард активен",
        "str_enter_game_clear_team": "Изчисти отбор",
        "str_enter_game_set_captain": "Избери капитан",
        "str_enter_game_set_vice": "Избери вицекапитан",
        "str_enter_game_deadline": "Краен срок",
        "str_enter_game_this_game": "За тази игра",
        "str_enter_game_rules": "важат правилата",
        "str_enter_game_rule": "важи",
        "str_enter_game_and": " и",
        "str_enter_game_as_with": "както и",
        "str_enter_game_standard_rules": "стандартното точкуване",
        "str_enter_game_max_players_same_team": "Максимум играчи от един и същи отбор: ",
        "str_save_changes": "Запази",

        "str_sub_details_team_info": "Информация за отбора",
        "str_sub_details_team_owner": "Собстеник на отбора",
        "str_sub_details_team_name": "Име на отбора",
        "str_sub_details_team_value": "Стойност на отбора за кръг",
        "str_sub_details_finished_players": "Приключили играчи",
        "str_sub_details_active_players": "Активни играчи",
        "str_sub_details_remaining_players": "Оставащи играчи",
        "str_sub_details_current_points": "Текущи точки",
        "str_sub_details_current_position": "Текуща позиция",
        "str_sub_details_created_at": "Създаден в",
        "str_sub_details_tournament_info": "Информация за играта",
        "str_sub_details_name": "Име",
        "str_sub_details_id": "№",
        "str_sub_details_creator": "Създател",
        "str_sub_details_gameweek": "Кръг",
        "str_sub_details_starts_at": "Започва в",
        "str_sub_details_ends_at": "Завършва в",

        "str_player_options_buy_shirt": "Купи тениска на играча",
        "str_player_options_transfer_player": "Трансферирай",
        "str_player_options_set_as_captain": "Направи капитан",
        "str_player_options_set_as_vice": "Направи вицекапитан",
        "str_player_options_remove": "Премахни",
        "str_player_options_player_info": "Информация за играча",
        "str_player_options_possible_changes": "Възможни смени",
        "str_player_options_do_swap": "Извърши смяна",

        "str_position_gk": "Вратар",
        "str_position_def": "Защитник",
        "str_position_mid": "Полузащитник",
        "str_position_fwd": "Нападател",

        "str_player_info_stats": "Статистика",
        "str_player_info_fixtures": "Програма",
        "str_player_info_form": "Форма",
        "str_player_info_total_pts": "Общо точки",
        "str_player_info_seasonal_price": "Сезонна цена",
        "str_player_info_gw_price": "Цена за кръга",
        "str_player_info_general_stats": "Показатели",
        "str_player_info_total": "Общо",
        "str_player_info_average": "Средно",

        "str_playground_add_gkp": "Add GKP",
        "str_playground_add_def": "Add DEF",
        "str_playground_add_mid": "Add MID",
        "str_playground_add_fwd": "Add FWD",

        "str_select_players_back_button": "Обратно към отбора",
        "str_select_players_confirm_players": "Потвърди Играчи",
        "str_select_players_max_price": "Максимална цена",
        "str_select_players_expected": "Активни",
        "str_select_players_confirmed": "Потвърден титуляр",
        "str_select_players_not_available": "Отсъстващ",
        "str_select_players_all_teams": "Всички отбори",
        "str_select_players_all_tab": "ВСЧ",
        "str_select_players_gkp_tab": "ВРТ",
        "str_select_players_def_tab": "ЗАЩ",
        "str_select_players_mid_tab": "ПЗЩ",
        "str_select_players_fwd_tab": "НАП",
        "str_select_players_found": "Намерени",
        "str_select_players_results": "Резултати",
        "str_select_players_info": "Инфо",
        "str_select_players_player": "Играч",
        "str_select_players_diff": "Трудност",
        "str_select_players_form": "Форма",
        "str_select_players_pts": "Тчк.",
        "str_select_players_price": "Цена",
        "str_legend": "Легенда",

        "str_dashboard_all": "Всички игри",
        "str_dashboard_current": "Стартирали",
        "str_dashboard_past": "Завършили",
        "str_dashboard_active": "Активни",
        "str_dashboard_points": "Точки",
        "str_dashboard_place": "Място",
        "str_dashboard_team_owner": "Собственик",
        "str_dashboard_change_team": "Промени отбор",
        "str_dashboard_transfer_team": "Трансфери",
        "str_dashboard_teams": "Отбори",
        "str_dashboard_your_teams": "Твоите отбори",
        "str_dashboard_pos": "Поз.",
        "str_dashboard_user": "Потребител",
        "str_dashboard_payouts": "Текуща награда",
        "str_dashboard_pts": "Точки",
        "str_dashboard_pts_pp": "Ср. Точки",
        "str_dashboard_leaderboard": "Класиране:",
        "str_dashboard_search_user": "Търси потребител",
        "str_dashboard_select_team": "Избери отбор",

        "str_dashboard_card_view_my_teams": "Преглед на моите отбори",
        "str_dashboard_card_add_new_team": "Добави нов отбор",
        "str_dashboard_card_in_progress": "Стартирала",
        "str_dashboard_card_finished": "Завършила",

        "str_info_deadline": "Краен срок за вход в играта",
        "str_info_budget": "Общ бюджет за играта",
        "str_info_captain": "Тази игра има капитан",
        "str_info_vice": "Тази игра има вицекапитан",
        "str_info_duration": "Кръг",
        "str_info_safety_net": "За тази игра важи правилото \"Спасителни Смени\"",
        "str_info_stacking_penalty": "За тази игра важи правилото \"Stacking penalty\"",
        "str_tooltip_points_pp": "Среден брой точки от играчите, които вече са играли.",
        "str_info_got_it": "Разбрах!",
        "str_info_title": "Информация",
        "str_info_team_before_start": "Не можеш да гледаш отборите на други мениджъри преди кръгът да е започнал.",

        "str_pagination_from": "от",

        "str_support_title": "Помощ",
        "str_support_getting_started": "Първи стъпки във фентъзи игрите",
        "str_support_overview": "Преглед на ffstars",
        "str_support_faq": "Правило: Спасителни Смени",
        "str_support_winners": "Победители",
        "str_tips_advice": "Съвети и препоръки",
        "str_recommend": "Препоръчай на приятел",
        "str_fantasy_sport": "Фентъзи спорт",
        "str_matchup_game": "The Matchup game",
        "str_sport_betting": "Спортни залагания",
        "str_account": "Профил",
        "str_deposit_cashout": "Депозити и тегления",
        "str_bonuses_promotions": "Бонуси и промоции",

        "str_highlight_goal": "Гол",
        "str_highlight_own_goal": "Автогол",
        "str_highlight_penalty_score": "Вкарана дузпа",
        "str_highlight_penalty_save": "Спасена дузпа",
        "str_highlight_penalty_miss": "Пропусната дузпа",
        "str_highlight_save": "Спасяване",
        "str_highlight_substitution": "Смяна",
        "str_highlight_change_position": "Размени резерва",
        "str_highlight_change_position_no_option": "Няма подходящи смени.",
        "str_highlight_yellow_card": "Жълт картон",
        "str_highlight_double_yellow_card": "Втори жълт картон",
        "str_highlight_red_card": "Червен картон",

        "str_subscription": "Абониране",
        "str_unsubscribe": "Отписване",
        "str_unsubscribe_body": "Вие се отписвате от Фентъзи Футбол!",
        "str_unsubscribe_body_success": "Успешно отписване.",
        "str_unsubscribe_body_failure": "Неуспешно отписване.",

        "str_no_winners": "Няма победители засега!",
        "str_game_week": "кръг",
    },
    en: {
        'str_error_no_public_id': 'Unauthorized',
        "str_player_points_dialog_point": "point",
        "str_player_points_dialog_points": "points",
        "str_player_points_dialog_no_existing_stats": "No stats",
        "str_tournament_highlights_show_more": "Show more",
        "str_tournament_highlights_match_started": "Match started",
        "str_tournament_highlights_match_ended": "Match ended",
        "str_tournament_highlights_scoring": "Scoring",
        "str_tournament_highlights_first_halftime": "First halftime",
        "str_tournament_highlights_second_halftime": "Second halftime",
        "str_tournament_highlights_assistance": "Assistance",
        "str_auth_error": "* Error logging in. Please try again.",
        "str_captain_icon": "C",
        "str_vice_captain_icon": "VC",
        "str_refresh": "Refresh",
        "str_demo_title": "Information",
        "str_demo_description": "This part of the demo is not yet available",
        "str_error": "Error",
        "str_enter_sub_tournament_error_save_budget": "You can't save a team with negative budget",
        "str_enter_sub_tournament_error_game_started": "This game has already started",
        "str_enter_sub_tournament_error_enter_game": "To enter a new game \n ",
        "str_enter_sub_tournament_error_player_count": "- Pick the full number of players, needed for your team \n ",
        "str_enter_sub_tournament_error_name": "- You can't save a team without a name \n",
        "str_enter_sub_tournament_error_pick_captain": "- Pick a captain \n ",
        "str_enter_sub_tournament_error_pick_vice_captain": "- Pick a vice captain \n ",
        "str_enter_sub_tournament_info_dialog_title": "Important",
        "str_enter_sub_tournament_info_dialog_palms_description": "The bonuses, which PalmsBet offers ARE NOT VALID for Fantasy Football! Upon deposit, press \"Decline\" on every bonus and deposit without bonus.",
        "str_enter_sub_tournament_info_dialog_wildcard_description": "The first Wildcard will be available after Round 10",
        "str_legend_information_dialog_title": "Overview",
        "str_legend_information_dialog_stats": "Statistics and schedule",
        "str_legend_information_dialog_number": "Position",
        "str_legend_information_dialog_player": "Player",
        "str_legend_information_dialog_user": "User",
        "str_legend_information_dialog_difficulty": "Difficulty of next match",
        "str_legend_information_dialog_form": "Form (average number of points from the last 4 gameweeks)",
        "str_legend_information_dialog_prize_pool": "Prize pool",
        "str_legend_information_dialog_points": "Points",
        "str_legend_information_dialog_round_point": "Round points",
        "str_legend_information_dialog_avg_points": "Average number of points from players who have already played.",
        "str_legend_information_dialog_prize": "Price / Reward",
        "str_support_description_intro": "Welcome to the Orange EURO 2024 Fantasy football game!",
        "str_support_description_fantasy_game": "For those of you who are not yet familiar with the basics of fantasy games, we are here to help. When you start a fantasy football game, you take on the role of manager of a virtual team of football players of your choice. This team consists of 11 players, and the game lasts for one round of the EURO 2024 Championship. Each game provides managers with a specific budget to build their team. Each football player receives fantasy points based on their performance in the real matches in which they participate. The manager who collects the most points at the end of the game wins. ",
        "str_support_description_team_selection": "After choosing a team and entering a certain game, all you have to do is watch the matches, have fun and accumulate points. Fantasy football games are games of skill in which football knowledge can bring big prizes. For more information visit the Rules and Scoring sections.",
        "str_support_description_contact_base": "We hope that you will immerse yourself in the incredible experience called fantasy football!",
        // "str_support_description_contact_url": "www.ffstars.com",
        // "str_support_description_contact_addition": " or email: ",
        // "str_support_description_contact_email": "info@ffstars.com",
        // "str_support_description_contact_end": " . We hope that you, like us, will immerse yourself in the incredible experience called fantasy football!",
        "str_support_rules_safe_shift_intro": "The “Safety subs” rule ensures that if one of your players does not start his match, they will be automatically replaced if possible.",
        "str_support_rules_safe_shift_applications": "This rule only applies to games in which you have no substitutes/bench players in your team. In order for another player to enter, that player must:",
        "str_support_rules_safe_shift_applications_1": "be on the same team as the substitute",
        "str_support_rules_safe_shift_applications_2": "play in the same position as the substitute",
        "str_support_rules_safe_shift_applications_3": "has the same or lower price as the replaced player",
        "str_support_rules_safe_shift_applications_end": "All three conditions must be met, otherwise the rule will not take effect and the initial player will remain in your team. If the conditions are met, the system will select the player with the highest price who meets all three conditions. There is no limit to the number of times the “Safety subs” rule can take effect.",
        "str_support_rules_safe_shift_conditions_intro": "If two or more potential subs cost the same, the system will select a player according to the following parameters and in the following order:",
        "str_support_rules_safe_shift_conditions_1": "- form (the player with the most points in the last few games).",
        "str_support_rules_safe_shift_conditions_2": "- total points for the season",
        "str_support_rules_safe_shift_conditions_3": "- alphabetical order",
        "str_support_rules_safe_shift_conditions_end": "If a player does not start the match, but enters the pitch later, they will be replaced again, but only if they got more points than the “Safety subs” player. On the other hand, if the player starts the match but is subsequently replaced, then the “Safety subs” rule does not take effect.",
        "str_support_rules_safe_shift_conditions_captain": "If your captain does not start the match and is replaced by a “Safety subs” player, then the replacement player will be your new captain. The same goes for your vice-captain.",
        "str_tournament_rules_safety_subs": "\"Safety Subs\" ensures that if one of your players does not start a game he will automatically be switched out for a starter, if that's possible. For more information please visit the \"Rules\" section.",
        "str_tournament_rules_stacking_penalty_open": "Not yet available",
        "str_home_tournament_see_more": "See more",
        "str_top_bar_home": "Home",
        "str_top_bar_team": "Team",
        "str_top_bar_transfers": "Transfers",
        "str_top_bar_points": "Points",
        "str_top_bar_results": "Results",
        "str_top_bar_stats": "Stats",
        "str_header_button_home": "Start",
        "str_header_button_my_games": "My Games",
        "str_header_button_more": "Info",
        "str_home_tab_official": "Official",
        "str_home_tab_duels": "Head 2 Head",
        "str_home_tab_users": "User created",
        "str_all_championships": "All leagues",
        "str_league_select_title": "Select a league",
        "str_league_filter_open": "Open",
        "str_league_filter_started": "Started",
        "str_league_filter_ended": "Ended",
        "str_league_filter_title": "Status",
        "str_fixtures_dialog_title": "Fixtures in this tournament",
        "str_information_dialog_title": "Information",
        "str_home_empty_state": "There are no games at the moment.",
        "str_home_coming_soon": "The next game will be live when all teams qualify for the next Round.",
        "str_coming_soon": "(soon)",
        "str_buy_ticket": "Buy a ticket",

        "str_tcard_places_left": "left",
        "str_tcard_hot": "HOT",
        "str_tcard_duration": "Duration:",
        "str_tcard_details": "Details",
        "str_action_play": "Play",
        "str_action_play_free": "Play",
        "str_tcard_action_view": "View Team",
        "str_tcard_player": "player",
        "str_tcard_players": "players",
        "str_tcard_places": "places",

        "str_currency": " € ",

        "str_details_free": "Free",
        "str_details_overview": "Overview",
        "str_details_fixtures": "Fixtures",
        "str_details_round": "Round",
        "str_details_fixtures_not_started": "Fixtures not started",
        "str_details_seasonal_game_not_available": "Seasonal game not available",
        "str_details_entries": "Entries",
        "str_details_prizes": "Prized",
        "str_details_rules": "Rules",

        "str_overview_name": "Name",
        "str_overview_creator": "Creator",
        "str_overview_start_date": "Start date",
        "str_overview_end_date": "End date",
        "str_overview_buy_in": "Buy in",
        "str_overview_prize_pool": "Prize pool",
        "str_overview_entrants": "Entrants",

        "str_entries_all_teams": "All teams:",
        "str_entries_search_user": "Search user",
        "str_entries_table_no": "No",
        "str_entries_table_user": "User",
        "str_entries_table_pts": "pts.",
        "str_entries_table_pts_pp": "average",

        "str_prizes_place": "Place",
        "str_prizes_ratio": "Ratio",
        "str_prizes_amount": "Amount",
        "str_prizes_prize": "Prize",

        "str_rules_tournament_run": "Tournament run",
        "str_rules_budget": "Budget",
        "str_rules_max_players_per_team": "Max players from 1 team",
        "str_rules_bench_players": "Bench players",
        "str_rules_score_calc": "Score calculation",
        "str_rules_teams_per_user_allowed": "Teams per user allowed",
        "str_rules_prize_distribution": "Prize distribution",
        "str_rules_safety_net": "Safety subs",
        "str_rules_stacking_penalty": "Stacking penalty",
        "str_rules_unlimited": "Unlimited",
        "str_rules_ratio": "Ratio",
        "str_seasonal_detail_rules": "Detail rules",

        "str_scoring_dialog_title": "Scoring",

        "str_enter_game_choose_captain": "Select captain",
        "str_enter_game_choose_vice": "Select vice captain",
        "str_enter_game_no_players_captain_title": "No selected players!",
        "str_enter_game_no_players_captain_description": "You need to select players before choosing a captain",
        "str_enter_game_no_players_vice": "You need to select players before choosing a vice captain",
        "str_enter_game_back_button": "Back to lobby",
        "str_enter_game_prizepool": "Prize pool",
        "str_enter_game_no_limit": "No limit",
        "str_enter_game_details": "Details",
        "str_enter_game_scoring": "Scoring",
        "str_enter_game_remaining_budget": "Remaining budget",
        "str_enter_game_players_selected": "Players selected",
        "str_enter_game_autocomplete": "Autocomplete",
        "str_enter_game_wildcard": "Wildcard",
        "str_enter_game_wildcard_activated": "Wildcard activated",
        "str_enter_game_wildcard_active": "Wildcard active",
        "str_enter_game_clear_team": "Clear team",
        "str_enter_game_set_captain": "Set captain",
        "str_enter_game_set_vice": "Set vice captain",
        "str_enter_game_deadline": "Deadline",
        "str_enter_game_this_game": "This tournamet",
        "str_enter_game_rules": "has",
        "str_enter_game_rule": "has",
        "str_enter_game_and": " and",
        "str_enter_game_as_with": "as well as",
        "str_enter_game_standard_rules": "standard rules",
        "str_enter_game_max_players_same_team": "Max players from same team: ",
        "str_save_changes": "Save",

        "str_sub_details_team_info": "Team info",
        "str_sub_details_team_owner": "Team owner",
        "str_sub_details_team_name": "Team name",
        "str_sub_details_team_value": "Team value",
        "str_sub_details_finished_players": "Finished players",
        "str_sub_details_active_players": "Active players",
        "str_sub_details_remaining_players": "Remaining players",
        "str_sub_details_current_points": "Current points",
        "str_sub_details_current_position": "Current position",
        "str_sub_details_created_at": "Created at",
        "str_sub_details_tournament_info": "Tournament info",
        "str_sub_details_name": "Name",
        "str_sub_details_id": "id",
        "str_sub_details_creator": "Creator",
        "str_sub_details_gameweek": "Gameweek",
        "str_sub_details_starts_at": "Starts at",
        "str_sub_details_ends_at": "Ends at",

        "str_player_options_buy_shirt": "Buy player shirt",
        "str_player_options_transfer_player": "Transfer player",
        "str_player_options_set_as_captain": "Set as captain",
        "str_player_options_set_as_vice": "Set as vice captain",
        "str_player_options_remove": "Remove player",
        "str_player_options_player_info": "Player info",
        "str_player_options_possible_changes": "Possible changes",
        "str_player_options_do_swap": "Swap player",

        "str_position_gk": "Goalkeeper",
        "str_position_def": "Defender",
        "str_position_mid": "Midfielder",
        "str_position_fwd": "Forward",

        "str_player_info_form": "Form",
        "str_player_info_total_pts": "Total pts",
        "str_player_info_seasonal_price": "Seasonal price",
        "str_player_info_gw_price": "GW price",
        "str_player_info_general_stats": "General stats",
        "str_player_info_total": "Total",
        "str_player_info_average": "Average",

        "str_playground_add_gkp": "Add GKP",
        "str_playground_add_def": "Add DEF",
        "str_playground_add_mid": "Add MID",
        "str_playground_add_fwd": "Add FWD",

        "str_select_players_back_button": "Back to team",
        "str_select_players_confirm_players": "Confirm Players",
        "str_select_players_max_price": "Max price",
        "str_select_players_expected": "Expected",
        "str_select_players_confirmed": "Confirmed",
        "str_select_players_not_available": "Not available",
        "str_select_players_all_teams": "All teams",
        "str_select_players_all_tab": "ALL",
        "str_select_players_gkp_tab": "GKP",
        "str_select_players_def_tab": "DEF",
        "str_select_players_mid_tab": "MID",
        "str_select_players_fwd_tab": "FWD",
        "str_select_players_found": "Found",
        "str_select_players_results": "Results",
        "str_select_players_info": "Info",
        "str_select_players_player": "Player",
        "str_select_players_diff": "Difficulty",
        "str_select_players_form": "Form",
        "str_select_players_pts": "Pts",
        "str_select_players_price": "Price",
        "str_legend": "Legend",

        "str_dashboard_all": "All tournaments",
        "str_dashboard_current": "Current",
        "str_dashboard_past": "Past",
        "str_dashboard_active": "Active",
        "str_dashboard_points": "Points",
        "str_dashboard_place": "Place",
        "str_dashboard_team_owner": "Team owner",
        "str_dashboard_change_team": "Change team",
        "str_dashboard_transfer_team": "Transfer team",
        "str_dashboard_teams": "Teams",
        "str_dashboard_your_teams": "Your teams",
        "str_dashboard_pos": "Pos.",
        "str_dashboard_user": "User",
        "str_dashboard_payouts": "Payouts",
        "str_dashboard_pts": "Points",
        "str_dashboard_pts_pp": "Average",
        "str_dashboard_leaderboard": "Leaderboard:",
        "str_dashboard_search_user": "Search user",
        "str_dashboard_select_team": "Select team",

        "str_dashboard_card_view_my_teams": "View my teams",
        "str_dashboard_card_add_new_team": "Add new team",
        "str_dashboard_card_in_progress": "In progress",
        "str_dashboard_card_finished": "Finished",

        "str_player_info_stats": "Key stats",
        "str_player_info_fixtures": "Fixtures",
        "str_info_deadline": "Deadline",
        "str_info_budget": "Tournament budget",
        "str_info_captain": "Captain needed for tournament",
        "str_info_vice": "Vice captain needed for tournament",
        "str_info_duration": "Duration",
        "str_info_safety_net": "This tournament has \"Safety subs\"",
        "str_info_stacking_penalty": "This tournament has \"Stacking penalty\"",
        "str_tooltip_points_pp": "Average points per finished player",
        "str_info_got_it": "Got it!",
        "str_info_title": "Info",
        "str_info_team_before_start": "Viewing other users' teams is not available before tournament start",

        "str_pagination_from": "from",

        "str_support_title": "Help",
        "str_support_getting_started": "Fantasy football: Getting started",
        "str_support_overview": "Overview",
        "str_support_faq": "Game rules",
        "str_support_winners": "Winners",
        "str_tips_advice": "Advice",
        "str_recommend": "Challenge a friend",
        "str_fantasy_sport": "Fantasy sport",
        "str_matchup_game": "The Matchup game",
        "str_sport_betting": "Sports gaming",
        "str_account": "Profile",
        "str_deposit_cashout": "Deposit cashout",
        "str_bonuses_promotions": "Promos and bonus",

        "str_highlight_goal": "Goal",
        "str_highlight_own_goal": "Own goal",
        "str_highlight_substitution": "Substitution",
        "str_highlight_change_position": "Change position",
        "str_highlight_change_position_no_option": "No substitution available",
        "str_highlight_yellow_card": "Yellow card",
        "str_highlight_double_yellow_card": "Second yellow card",
        "str_highlight_red_card": "Red card",

        "str_subscription": "Subscription",
        "str_unsubscribe": "Unsubscribe",
        "str_unsubscribe_body": "You are about to unsubscribe from Fantasy Football.",
        "str_unsubscribe_body_success": "Unsubscribe successful.",
        "str_unsubscribe_body_failure": "Unsubscribe failed.",

        "str_no_winners": "No winners yet!",
        "str_game_week": "GW",
    },
    ar: {
        "str_player_points_dialog_point": "نقطة",
        "str_player_points_dialog_points": "نقاط",
        "str_player_points_dialog_no_existing_stats": "لا احصائيات",
        "str_tournament_highlights_show_more": "للمزيد",
        "str_tournament_highlights_match_started": "المباراة ابتدأت",
        "str_tournament_highlights_match_ended": "المباراة انتهت",
        "str_tournament_highlights_scoring": "تسجيل",
        "str_tournament_highlights_first_halftime": "الشوط الأول",
        "str_tournament_highlights_second_halftime": "الشوط الثاني",
        "str_tournament_highlights_assistance": "مساعدة",
        "str_auth_error": "* مشكلة في الدخول يرجى المحاولة من جديد",
        "str_captain_icon": "ك",
        "str_vice_captain_icon": "ك ب",
        "str_refresh": "تحديث",
        "str_demo_title": "معلومات",
        "str_demo_description": " هذا الجزء من اللعبة ليس موجود حاليا ",
        "str_error": "Error",
        "str_enter_sub_tournament_error_save_budget": "لا يمكنك حفظ فريق بميزانية سلبية",
        "str_enter_sub_tournament_error_game_started": "هذه اللعبة بدأت حاليا",
        "str_enter_sub_tournament_error_enter_game": "لبداية لعبة جديدة \n ",
        "str_enter_sub_tournament_error_player_count": "- اختر كافة اللاعبين لتشكيل فريقك \n ",
        "str_enter_sub_tournament_error_name": "- لا يمكنك تسجيل فريق من دون اسم \n",
        "str_enter_sub_tournament_error_pick_captain": "- اختر قائد لفريقك \n ",
        "str_enter_sub_tournament_error_pick_vice_captain": "- اختر قائد بديل لفريقك \n ",
        "str_enter_sub_tournament_info_dialog_title": "هام",
        "str_legend_information_dialog_title": "ملخص",
        "str_legend_information_dialog_stats": "احصاءات والجدول",
        "str_legend_information_dialog_number": "مركز",
        "str_legend_information_dialog_player": "لاعب",
        "str_legend_information_dialog_user": "مستخدم",
        "str_legend_information_dialog_difficulty": "صعوبة اللقاء المقبل",
        "str_legend_information_dialog_form": "المستوى (معدل النقاط خلال اخر 4 جولات)",
        "str_legend_information_dialog_prize_pool": "جدول الجوائز",
        "str_legend_information_dialog_points": "النقاط",
        "str_legend_information_dialog_round_point": "نقاط الجولة",
        "str_legend_information_dialog_avg_points": "معدل النقاط للاعبين الذين شاركو.",
        "str_legend_information_dialog_prize": "السعر\الجائزة",
        "str_support_description_intro": " مرحبًا بكم في لعبة كرة القدم الخيالية Orange EURO 2024!",
        "str_support_description_fantasy_game": " بالنسبة لأولئك منكم الذين لم يتعرفوا بعد على أساسيات ألعاب الفانتازيا، فنحن هنا للمساعدة. عندما تبدأ لعبة كرة قدم فانتازية، فإنك تتولى دور مدير فريق افتراضي من لاعبي كرة القدم من اختيارك. يتكون هذا الفريق من 11 لاعبًا، وتستمر اللعبة لمدة جولة واحدة من بطولة يورو 2024. توفر كل لعبة للمديرين ميزانية محددة لبناء فريقهم. يتلقى كل لاعب كرة قدم نقاط فانتازيا بناءً على أدائه في المباريات الحقيقية التي يشارك فيها. يفوز المدير الذي يجمع أكبر عدد من النقاط في نهاية اللعبة..",
        "str_support_description_team_selection": " بعد اختيار فريق والدخول في مباراة معينة، كل ما عليك فعله هو مشاهدة المباريات والاستمتاع وتجميع النقاط. ألعاب كرة القدم الخيالية هي ألعاب مهارة حيث يمكن أن تجلب معرفة كرة القدم جوائز كبيرة. لمزيد من المعلومات، قم بزيارة أقسام القواعد والتسجيل.",
        "str_support_description_contact_end": " نأمل أن تنغمس في تجربة لا تصدق تسمى كرة القدم الخيالية!",

        "str_home_tournament_see_more": "للمزيد",
        "str_top_bar_home": "الصفحة الرئيسية",
        "str_top_bar_team": "فريق",
        "str_top_bar_transfers": "انتقالات",
        "str_top_bar_points": "نقاط",
        "str_top_bar_results": "نتائج",
        "str_top_bar_stats": "احصاءات",
        "str_header_button_home": " الصفحة الرئيسية ",
        "str_header_button_my_games": "مبارياتي",
        "str_header_button_more": "للمزيد",
        "str_home_tab_official": "رسمي",
        "str_home_tab_users": "المستخدم تم تفعيله",
        "str_all_championships": "كل الدوريات",
        "str_league_select_title": "اختر دوري",
        "str_league_filter_open": "فتح",
        "str_league_filter_started": "بدأت",
        "str_league_filter_ended": "انتهت",
        "str_league_filter_title": "الحالة",
        "str_fixtures_dialog_title": "جدول هذه البطولة",
        "str_information_dialog_title": "معلومات",
        "str_home_empty_state": "لا مباريات في هذه اللحظة",
        "str_coming_soon": "(قريبا)",
        "str_buy_ticket": "اشتر بطاقة",

        "str_tcard_places_left": "متبقي",
        "str_tcard_hot": "هام",
        "str_tcard_duration": "المدة:",
        "str_tcard_details": "تفاصيل",
        "str_action_play": "العب",
        "str_action_play_free": "العب",
        "str_tcard_action_view": "عرض",
        "str_tcard_player": "لاعب",
        "str_tcard_players": "لاعبين",
        "str_tcard_places": "اماكن",

        "str_currency": " â‚¬ ",

        "str_details_free": "مجاني",
        "str_details_overview": "ملخص",
        "str_details_fixtures": "جداول",
        "str_details_round": "الجولة",
        "str_details_fixtures_not_started": "الجداول لم تبدأ",
        "str_details_seasonal_game_not_available": "اللعبة الموسمية ليست موجودة",
        "str_details_entries": "ادخالات",
        "str_details_prizes": "حصل عجائزة",
        "str_details_rules": "قواعد",

        "str_overview_name": "اسم",
        "str_overview_creator": "المؤسس",
        "str_overview_start_date": "تاريخ البدء",
        "str_overview_end_date": "تاريخ الانتهاء",
        "str_overview_buy_in": "اشتري",
        "str_overview_prize_pool": "جدول الجوائز",
        "str_overview_entrants": "المشاركين",

        "str_entries_all_teams": "كل الفرق:",
        "str_entries_search_user": "البحث عن مستخدم",
        "str_entries_table_no": "رقم",
        "str_entries_table_user": "المستخدم",
        "str_entries_table_pts": "نقطة",
        "str_entries_table_pts_pp": "معدل",

        "str_prizes_place": "مكان",
        "str_prizes_ratio": "نسبة",
        "str_prizes_amount": "المبلغ",
        "str_prizes_prize": "الجائزة",

        "str_rules_tournament_run": "البطولة بدأت",
        "str_rules_budget": "الميزانية",
        "str_rules_max_players_per_team": "العدد الاقصى للفريق الواحد",
        "str_rules_bench_players": "البدائل",
        "str_rules_score_calc": "احتساب النقاط",
        "str_rules_teams_per_user_allowed": "الفرق للمستخدم الواحد",
        "str_rules_prize_distribution": "توزيع الجوائز",
        "str_rules_safety_net": "التبديلات الامنة",
        "str_rules_unlimited": "غير محدد",
        "str_rules_ratio": "نسبة",
        "str_seasonal_detail_rules": "قواعد مفصلة",

        "str_scoring_dialog_title": "تسجيل",

        "str_enter_game_choose_captain": "اختر القائد",
        "str_enter_game_choose_vice": "اختر القائد البديل",
        "str_enter_game_back_button": "العودة الى اللوبي",
        "str_enter_game_prizepool": "جدول الجوائز",
        "str_enter_game_no_limit": "غير محدد",
        "str_enter_game_details": "تفاصيل",
        "str_enter_game_scoring": "تسجيل",
        "str_enter_game_remaining_budget": "الميزانية المتبقية",
        "str_enter_game_players_selected": "اللاعبين المختارين",
        "str_enter_game_autocomplete": "إكمال تلقائي",
        "str_enter_game_clear_team": "مسح الفريق",
        "str_enter_game_set_captain": "تفعيل القائد",
        "str_enter_game_set_vice": "تفعيل نائب القائد",
        "str_enter_game_deadline": "التاريخ النهائي",
        "str_enter_game_this_game": "هذه البطولة",
        "str_enter_game_rules": "لديه",
        "str_enter_game_rule": "لديه",
        "str_enter_game_and": " و",
        "str_enter_game_as_with": "كما",
        "str_enter_game_standard_rules": "القواعد الاساسية",
        "str_save_changes": "حفظ",

        "str_sub_details_team_info": "معلومات الفريق",
        "str_sub_details_team_owner": "مالك الفريق",
        "str_sub_details_team_name": "اسم الفريق",
        "str_sub_details_team_value": "قيمة الفريق",
        "str_sub_details_finished_players": "اللاعبين المكتملين",
        "str_sub_details_active_players": "اللاعبين المفعلين",
        "str_sub_details_remaining_players": "اللاعبين المتبقيين",
        "str_sub_details_current_points": "النقاط الحالية",
        "str_sub_details_current_position": "المركز الحالي",
        "str_sub_details_created_at": "أنشأ في",
        "str_sub_details_tournament_info": "معلومات البطولة",
        "str_sub_details_name": "أسم",
        "str_sub_details_id": "id",
        "str_sub_details_creator": "المنشئ",
        "str_sub_details_gameweek": "الجولة",
        "str_sub_details_starts_at": "يبدأ في",
        "str_sub_details_ends_at": "ينتهي في",

        "str_player_options_transfer_player": "انتقال اللاعب",
        "str_player_options_set_as_captain": "تفعيل القائد",
        "str_player_options_set_as_vice": "تفعيل نائب القائد",
        "str_player_options_remove": " إزالة اللاعب",
        "str_player_options_player_info": "معلومات عن اللاعب",
        "str_player_options_possible_changes": "التغييرات المحتملة",
        "str_player_options_do_swap": "مبادلة اللاعب",

        "str_position_gk": "حارس مرمى",
        "str_position_def": "مدافع",
        "str_position_mid": "لاعب وسط",
        "str_position_fwd": "مهاجم",

        "str_player_info_form": "المستوى",
        "str_player_info_total_pts": "مجموع النقاط",
        "str_player_info_seasonal_price": "السعر الموسمي",
        "str_player_info_gw_price": "سعر الجولة",
        "str_player_info_general_stats": "احصاءات عامة",
        "str_player_info_total": "المجموع",
        "str_player_info_average": "المعدل",

        "str_playground_add_gkp": "اضافة حارس",
        "str_playground_add_def": "اضافة مدافع",
        "str_playground_add_mid": "اضافة لاعب وسط",
        "str_playground_add_fwd": "اضافة مهاجم",

        "str_select_players_back_button": "الرجوع الى الفريق",
        "str_select_players_find_player": "ابحث عن لاعب",
        "str_select_players_max_price": "السعر الاقصى",
        "str_select_players_expected": "المحتمل",
        "str_select_players_confirmed": "مؤكد",
        "str_select_players_not_available": "غير موجود",
        "str_select_players_all": "الجميع",
        "str_select_players_all_teams": "كل الفرق",
        "str_select_players_status": "الحالة",
        "str_select_players_teams": "الفرق",
        "str_select_players_all_tab": "الكل",
        "str_select_players_gkp_tab": "حارس",
        "str_select_players_def_tab": "مدافع",
        "str_select_players_mid_tab": "وسط",
        "str_select_players_fwd_tab": "مهاجم",
        "str_select_players_found": "وجد",
        "str_select_players_results": "النتائج",
        "str_select_players_gkp": "حراس",
        "str_select_players_def": "مدافعين",
        "str_select_players_mid": "لاعبي وسط",
        "str_select_players_fwd": "مهاجمين",
        "str_select_players_info": "معلومات",
        "str_select_players_player": "لاعب",
        "str_select_players_diff": "صعوبة",
        "str_select_players_form": "المستوى",
        "str_select_players_pts": "النقاط",
        "str_select_players_price": "السعر",
        "str_legend": "Legend",

        "str_dashboard_all": "كل البطولات",
        "str_dashboard_current": "الحالي",
        "str_dashboard_past": "قديم",
        "str_dashboard_active": "مفعل",
        "str_dashboard_points": "نقاط",
        "str_dashboard_place": "مكان",
        "str_dashboard_team_owner": "مالك الفريق",
        "str_dashboard_change_team": "تغيير فريق",
        "str_dashboard_transfer_team": "انتقال فريق",
        "str_dashboard_teams": "فرق",
        "str_dashboard_your_teams": "فرقك",
        "str_dashboard_pos": "مركز",
        "str_dashboard_user": "المستخدم",
        "str_dashboard_payouts": "المدفوعات",
        "str_dashboard_pts": "نقاط",
        "str_dashboard_pts_pp": "معدل",
        "str_dashboard_leaderboard": " الصدارة",
        "str_dashboard_search_user": "البحث عن مستخدم",
        "str_dashboard_select_team": "البحث عن فريق",

        "str_dashboard_card_view_my_teams": " عرض فريقي",
        "str_dashboard_card_add_new_team": "اضافة فريق جديد",
        "str_dashboard_card_in_progress": "يجري حاليا",
        "str_dashboard_card_finished": "انتهى",

        "str_player_info_stats": "احصاءات هامة",
        "str_player_info_fixtures": "الجدول",
        "str_info_deadline": "التاريخ النهائي",
        "str_info_budget": "ميزانية البطولة",
        "str_info_captain": "بحاجة الى قائد للبطولة",
        "str_info_vice": "بحاجة الى نائب قائد للبطولة",
        "str_info_duration": "المدة",
        "str_info_safety_net": "هذه الطولة تحتوي على \"تبديلات امنة\"",
        "str_tooltip_points_pp": "معدل النقاط للاعبين المكتملين",
        "str_info_got_it": "علمت!",
        "str_info_title": "معلومات",
        "str_info_team_before_start": "مشاهدة الفرق الاخرى ليست موجودة لهذه البطولة",

        "str_pagination_from": "من",

        "str_support_title": "مساعدة",
        "str_support_getting_started": "Fantasy football: معلومات للبدء",
        "str_support_overview": "ملخض",
        "str_support_faq": "قواعد اللعبة",
        "str_support_winners": "الفائزين",
        "str_tips_advice": "نصيحة",
        "str_recommend": "تحدي صديق",
        "str_fantasy_sport": "لعبة الفانتزي",
        "str_matchup_game": " لعبة المباراة",
        "str_sport_betting": "الألعاب الرياضية",
        "str_account": "الملف الشخصي",

        "str_highlight_goal": "هدف",
        "str_highlight_own_goal": "هدف في مرماه",
        "str_highlight_penalty_score": "تسجيل ركلة جزاء",
        "str_highlight_penalty_save": "تصدي ل ركلة جزاء",
        "str_highlight_penalty_miss": "اهدار ضربة جزاء",
        "str_highlight_save": "حفظ",
        "str_highlight_substitution": "تبديل",
        "str_highlight_change_position": "تغيير مركز",
        "str_highlight_change_position_no_option": "لا يوجد تبديل",
        "str_highlight_yellow_card": "بطاقة صفراء",
        "str_highlight_double_yellow_card": "بطاقة صفراء ثانية",
        "str_highlight_red_card": "بطاقة حمراء"
    },
    fr: {
        "str_player_points_dialog_point": "Point",
        "str_player_points_dialog_points": "Points",
        "str_player_points_dialog_no_existing_stats": "Pas de statistiques",
        "str_tournament_highlights_show_more": "Voir plus",
        "str_tournament_highlights_match_started": "Le match a commencé",
        "str_tournament_highlights_match_ended": "Le match est terminé",
        "str_tournament_highlights_scoring": "Score",
        "str_tournament_highlights_first_halftime": "Première mi-temps",
        "str_tournament_highlights_second_halftime": "Deuxième mi-temps",
        "str_tournament_highlights_assistance": "Assistance",
        "str_auth_error": "* Problème de connexion, veuillez réessayer",
        "str_captain_icon": "C",
        "str_vice_captain_icon": "VC",
        "str_refresh": "Rafraîchir",
        "str_demo_title": "Information",
        "str_demo_description": "Cette partie du jeu n'est pas disponible pour le moment",
        "str_error": "Erreur",
        "str_enter_sub_tournament_error_save_budget": "Vous ne pouvez pas enregistrer une équipe avec un budget négatif",
        "str_enter_sub_tournament_error_game_started": "Ce jeu a déjà commencé",
        "str_enter_sub_tournament_error_enter_game": "Pour commencer un nouveau jeu",
        "str_enter_sub_tournament_error_player_count": "- Sélectionnez tous les joueurs pour former votre équipe",
        "str_enter_sub_tournament_error_name": "- Vous ne pouvez pas enregistrer une équipe sans nom",
        "str_enter_sub_tournament_error_pick_captain": "- Sélectionnez un capitaine pour votre équipe",
        "str_enter_sub_tournament_error_pick_vice_captain": "- Sélectionnez un vice-capitaine pour votre équipe",
        "str_enter_sub_tournament_info_dialog_title": "Important",
        "str_legend_information_dialog_title": "Résumé",
        "str_legend_information_dialog_stats": "Statistiques et tableau",
        "str_legend_information_dialog_number": "Position",
        "str_legend_information_dialog_player": "Joueur",
        "str_legend_information_dialog_user": "Utilisateur",
        "str_legend_information_dialog_difficulty": "Difficulté du prochain match",
        "str_legend_information_dialog_form": "Forme (moyenne des points sur les 4 derniers tours)",
        "str_legend_information_dialog_prize_pool": "Tableau des prix",
        "str_legend_information_dialog_points": "Points",
        "str_legend_information_dialog_round_point": "Points de la ronde",
        "str_legend_information_dialog_avg_points": "Moyenne des points des joueurs participants",
        "str_legend_information_dialog_prize": "Prix",
        "str_support_description_intro": "Bienvenue dans le jeu de foot fantasy EURO 2024 Orange Tunisie!",
        "str_support_description_fantasy_game": "Pour ceux d'entre vous qui ne connaissent pas encore les bases des foot fantasy, nous sommes là pour aider. Lorsque vous commencez un jeu de football fantasy, vous jouez le rôle d'un manager d'équipe virtuelle de joueurs de football de votre choix. Cette équipe est composée de 11 joueurs et le jeu dure un tour de l'Euro 2024. Chaque jeu fournit aux managers un budget spécifique pour constituer leur équipe. Chaque joueur de football reçoit des points virtuels en fonction de sa performance dans les vrais matchs auxquels il participe. Le manager qui accumule le plus de points à la fin du jeu gagne.",
        "str_support_description_team_selection": "Après avoir sélectionné une équipe et participé à un match spécifique, tout ce que vous avez à faire est de regarder les matchs, de profiter et de collecter des points. Les jeux de football fantasy sont des jeux de compétitions où la connaissance du football peut apporter de grandes récompenses. Pour plus d'informations, voir les sections des règles et des scores.",
        "str_support_description_contact_end": "Nous espérons que vous vous immergerez dans une expérience incroyable appelée football fantasy!",
        "str_home_tournament_see_more": "Voir plus",
        "str_top_bar_home": "Page d'accueil",
        "str_top_bar_team": "Équipe",
        "str_top_bar_transfers": "Transferts",
        "str_top_bar_points": "Points",
        "str_top_bar_results": "Résultats",
        "str_top_bar_stats": "Statistiques",
        "str_header_button_home": "Page d'accueil",
        "str_header_button_my_games": "Mes jeux",
        "str_header_button_more": "Voir plus",
        "str_home_tab_official": "Officiel",
        "str_home_tab_users": "Utilisateur activé",
        "str_all_championships": "Tous les championnats",
        "str_league_select_title": "Sélectionnez un championnat",
        "str_league_filter_open": "Ouvrir",
        "str_league_filter_started": "Commencé",
        "str_league_filter_ended": "Terminé",
        "str_league_filter_title": "Statut",
        "str_fixtures_dialog_title": "Calendrier de ce championnat",
        "str_information_dialog_title": "Informations",
        "str_home_empty_state": "Pas de matchs en ce moment",
        "str_coming_soon": "(Bientôt disponible)",
        "str_buy_ticket": "Acheter un billet",
        "str_tcard_places_left": "Places restantes",
        "str_tcard_hot": "CHAUD",
        "str_tcard_duration": "Durée:",
        "str_tcard_details": "Détails",
        "str_action_play": "Jouer",
        "str_action_play_free": "Jouer",
        "str_tcard_action_view": "Voir",
        "str_tcard_player": "Joueur",
        "str_tcard_players": "Joueurs",
        "str_tcard_places": "Places",
        "str_currency": " TND ",
        "str_details_free": "Gratuit",
        "str_details_overview": "Aperçu",
        "str_details_fixtures": "Calendriers",
        "str_details_round": "Tour",
        "str_details_fixtures_not_started": "Calendriers non commencés",
        "str_details_seasonal_game_not_available": "Le jeu saisonnier n'est pas disponible",
        "str_details_entries": "Entrées",
        "str_details_prizes": "Prix gagnés",
        "str_details_rules": "Règles",
        "str_overview_name": "Nom",
        "str_overview_creator": "Créateur",
        "str_overview_start_date": "Date de début",
        "str_overview_end_date": "Date de fin",
        "str_overview_buy_in": "Acheter",
        "str_overview_prize_pool": "Tableau des prix",
        "str_overview_entrants": "Participants",
        "str_entries_all_teams": "Toutes les équipes:",
        "str_entries_search_user": "Rechercher un utilisateur",
        "str_entries_table_no": "Numéro",
        "str_entries_table_user": "Utilisateur",
        "str_entries_table_pts": "pts.",
        "str_entries_table_pts_pp": "Moyenne",
        "str_prizes_place": "Place",
        "str_prizes_ratio": "Ratio",
        "str_prizes_amount": "Montant",
        "str_prizes_prize": "Prix",
        "str_rules_tournament_run": "Le tournoi a commencé",
        "str_rules_budget": "Budget",
        "str_rules_max_players_per_team": "Nombre maximum de joueurs par équipe",
        "str_rules_bench_players": "Remplaçants",
        "str_rules_score_calc": "Calcul des points",
        "str_rules_teams_per_user_allowed": "Équipes par utilisateur autorisées",
        "str_rules_prize_distribution": "Répartition des prix",
        "str_rules_safety_net": "Substitutions sécurisées",
        "str_rules_unlimited": "Illimité",
        "str_rules_ratio": "Ratio",
        "str_seasonal_detail_rules": "Règles détaillées",
        "str_scoring_dialog_title": "Score",
        "str_enter_game_choose_captain": "Choisir le capitaine",
        "str_enter_game_choose_vice": "Choisir le vice-capitaine",
        "str_enter_game_back_button": "Retour au lobby",
        "str_enter_game_prizepool": "Tableau des prix",
        "str_enter_game_no_limit": "Illimité",
        "str_enter_game_details": "Détails",
        "str_enter_game_scoring": "Score",
        "str_enter_game_remaining_budget": "Budget restant",
        "str_enter_game_players_selected": "Joueurs sélectionnés",
        "str_enter_game_autocomplete": "Complétion automatique",
        "str_enter_game_clear_team": "Effacer l'équipe",
        "str_enter_game_set_captain": "Définir le capitaine",
        "str_enter_game_set_vice": "Définir le vice-capitaine",
        "str_enter_game_deadline": "Date limite",
        "str_enter_game_this_game": "Ce tournoi",
        "str_enter_game_rules": "Règles",
        "str_enter_game_rule": "Règle",
        "str_enter_game_and": "et",
        "str_enter_game_as_with": "comme",
        "str_enter_game_standard_rules": "règles standard",
        "str_save_changes": "Enregistrer",
        "str_sub_details_team_info": "Informations de l'équipe",
        "str_sub_details_team_owner": "Propriétaire de l'équipe",
        "str_sub_details_team_name": "Nom de l'équipe",
        "str_sub_details_team_value": "Valeur de l'équipe",
        "str_sub_details_finished_players": "Joueurs terminés",
        "str_sub_details_active_players": "Joueurs actifs",
        "str_sub_details_remaining_players": "Joueurs restants",
        "str_sub_details_current_points": "Points actuels",
        "str_sub_details_current_position": "Position actuelle",
        "str_sub_details_created_at": "Créé à",
        "str_sub_details_tournament_info": "Informations sur le tournoi",
        "str_sub_details_name": "Nom",
        "str_sub_details_id": "ID",
        "str_sub_details_creator": "Créateur",
        "str_sub_details_gameweek": "Journée",
        "str_sub_details_starts_at": "Commence à",
        "str_sub_details_ends_at": "Se termine à",

        "str_player_options_transfer_player": "Transfert du joueur",
        "str_player_options_set_as_captain": "Définir comme capitaine",
        "str_player_options_set_as_vice": "Définir comme vice-capitaine",
        "str_player_options_remove": "Supprimer le joueur",
        "str_player_options_player_info": "Informations sur le joueur",
        "str_player_options_possible_changes": "Changements possibles",
        "str_player_options_do_swap": "Échanger le joueur",

        "str_position_gk": "Gardien de but",
        "str_position_def": "Défenseur",
        "str_position_mid": "Milieu de terrain",
        "str_position_fwd": "Attaquant",

        "str_player_info_form": "Forme",
        "str_player_info_total_pts": "Total pts",
        "str_player_info_seasonal_price": "Prix saisonnier",
        "str_player_info_gw_price": "Prix par journée",
        "str_player_info_general_stats": "Statistiques générales",
        "str_player_info_total": "Total",
        "str_player_info_average": "Moyenne",

        "str_playground_add_gkp": "Ajouter GAR",
        "str_playground_add_def": "Ajouter DEF",
        "str_playground_add_mid": "Ajouter MIL",
        "str_playground_add_fwd": "Ajouter ATT",

        "str_select_players_back_button": "Retour à l'équipe",
        "str_select_players_find_player": "Trouver un joueur",
        "str_select_players_max_price": "Prix maximum",
        "str_select_players_expected": "Attendu",
        "str_select_players_confirmed": "Confirmé",
        "str_select_players_not_available": "Non disponible",
        "str_select_players_all": "Tous",
        "str_select_players_all_teams": "Toutes les équipes",
        "str_select_players_status": "Statut",
        "str_select_players_teams": "Équipes",
        "str_select_players_all_tab": "Tout",
        "str_select_players_gkp_tab": "GAR",
        "str_select_players_def_tab": "DEF",
        "str_select_players_mid_tab": "MIL",
        "str_select_players_fwd_tab": "ATT",
        "str_select_players_found": "Trouvé",
        "str_select_players_results": "Résultats",
        "str_select_players_gkp": "Gardiens",
        "str_select_players_def": "Défenseurs",
        "str_select_players_mid": "Milieux de terrain",
        "str_select_players_fwd": "Attaquants",
        "str_select_players_info": "Informations",
        "str_select_players_player": "Joueur",
        "str_select_players_diff": "Difficulté",
        "str_select_players_form": "Forme",
        "str_select_players_pts": "Pts",
        "str_select_players_price": "Prix",
        "str_legend": "Légende",

        "str_dashboard_all": "Tous les tournois",
        "str_dashboard_current": "Actuel",
        "str_dashboard_past": "Passé",
        "str_dashboard_active": "Actif",
        "str_dashboard_points": "Points",
        "str_dashboard_place": "Place",
        "str_dashboard_team_owner": "Propriétaire de l'équipe",
        "str_dashboard_change_team": "Changer d'équipe",
        "str_dashboard_transfer_team": "Transférer l'équipe",
        "str_dashboard_teams": "Équipes",
        "str_dashboard_your_teams": "Vos équipes",
        "str_dashboard_pos": "Position",
        "str_dashboard_user": "Utilisateur",
        "str_dashboard_payouts": "Paiements",
        "str_dashboard_pts": "Points",
        "str_dashboard_pts_pp": "Moyenne",
        "str_dashboard_leaderboard": "Classement",
        "str_dashboard_search_user": "Rechercher un utilisateur",
        "str_dashboard_select_team": "Rechercher une équipe",

        "str_dashboard_card_view_my_teams": "Voir mon équipe",
        "str_dashboard_card_add_new_team": "Ajouter une nouvelle équipe",
        "str_dashboard_card_in_progress": "En cours",
        "str_dashboard_card_finished": "Terminé",

        "str_player_info_stats": "Statistiques importantes",
        "str_player_info_fixtures": "Calendrier",
        "str_info_deadline": "Date limite",
        "str_info_budget": "Budget du tournoi",
        "str_info_captain": "Besoin d'un capitaine pour le tournoi",
        "str_info_vice": "Besoin d'un vice-capitaine pour le tournoi",
        "str_info_duration": "Durée",
        "str_info_safety_net": "Ce tournoi inclut des « remplacements sûrs »",
        "str_tooltip_points_pp": "Moyenne des points pour les joueurs terminés",
        "str_info_got_it": "J'ai compris!",
        "str_info_title": "Informations",
        "str_info_team_before_start": "Voir les autres équipes n'est pas disponible pour ce tournoi",

        "str_pagination_from": "De",

        "str_support_title": "Aide",
        "str_support_getting_started": "Football fantasy : Guide de démarrage",
        "str_support_overview": "Résumé",
        "str_support_faq": "Règles du jeu",
        "str_support_winners": "Gagnants",
        "str_tips_advice": "Conseils",
        "str_recommend": "Défier un ami",
        "str_fantasy_sport": "Sport fantasy",
        "str_matchup_game": "Jeu de rencontre",
        "str_sport_betting": "Paris sportifs",
        "str_account": "Profil",

        "str_highlight_goal": "But",
        "str_highlight_own_goal": "Marque contre son camp",
        "str_highlight_penalty_score": "Marquer un penalty",
        "str_highlight_penalty_save": "Arrêter un penalty",
        "str_highlight_penalty_miss": "Manquer un penalty",
        "str_highlight_save": "Arrêt",
        "str_highlight_substitution": "Substitution",
        "str_highlight_change_position": "Changer de position",
        "str_highlight_change_position_no_option": "Pas de changement",
        "str_highlight_yellow_card": "Carton jaune",
        "str_highlight_double_yellow_card": "Deuxième carton jaune",
        "str_highlight_red_card": "Carton rouge"
    }
}
