import * as React from "react";

function SvgPrice(props) {
  return (
    <svg viewBox="0 0 14.091 14.486" {...props}>
      <defs>
        <style>{".price_svg__a{fill:#bebebe}"}</style>
      </defs>
      <path
        className="price_svg__a"
        d="M12.129 3.431H1.962A1.962 1.962 0 000 5.392v7.131a1.962 1.962 0 001.962 1.962h10.167a1.962 1.962 0 001.962-1.962V5.392a1.962 1.962 0 00-1.962-1.961zm-.558 6.385a.859.859 0 11.859-.859.859.859 0 01-.859.859zM11.426 2.701V1.184a1.183 1.183 0 00-1.5-1.139L1.055 2.722a2.138 2.138 0 01.289-.021z"
      />
    </svg>
  );
}

export default SvgPrice;
