import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import * as translations from '../Common/utils/translations';
import EnterTournamentScoring from '../EnterTournament/components/EnterTournamentScoring';
import { Icon } from '../Common/components/Icon';
import { getDomainOperator } from '../Common/utils/domainMapper';
import downArrow from '../assets/images/down_arrow.svg';
import { showConfirmPopup } from '../ConfirmPopup/actions/confirmPopup';
import ReactPlayer from 'react-player'

const operator = getDomainOperator();
// 'https://www.youtube.com/shorts/T5NXhdSbtD4'; // Old video
const VIDEO_PATH = 'https://www.youtube.com/shorts/KFarbR9oGNQ'

const design = operator.design;

const styles = {
  icon: {
    marginRight: 10
  },

  itemText: {
    fontFamily: design.textFontFamily,
    fontSize: 14
  },

  itemTitle: {
    fontSize: 18,
    fontWeight: 550
  },

  scrollContainer: {
    overflowY: 'auto'
  },

  container: {
    width: '95%',
    margin: '0 2.5%'
  },

  titleContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0',
    cursor: 'pointer'
  },

  verticalFlip: {
    transform: 'scale(1, -1)'
  },

  justifyText: {
    textAlign: 'justify'
  },

  justifyLeftText: {
    textAlign: 'left'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '92%',
    marginBottom: 15
  },
  tableHeaderText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  headerButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingVertical: '5px',
    paddingHorizontal: '4%'
  },
  selectedRow: {
    backgroundColor: '#00FF8E'
  },
  tableCellText: {
    fontSize: 14
  },
  tableCellSmall: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableCellMedium: {
    flex: 2,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableCellMediumBig: {
    flex: 3,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableCellBig: {
    flex: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  unsubscribe: {
    textDecoration: 'underline',
    color: 'blue',
    textAlign: 'center',
    marginVertical: '16px',
  }
};

class Support extends React.Component {
  state = {
    expandedSection: null,
    winners: [],
  };

  showUnsubscribePopup = () => {
    this.props.showConfirmPopup(translations.tKey('str_unsubscribe'), translations.tKey('str_unsubscribe_body'), this.unsubscribe);
  }

  unsubscribe = () => {
    const url = 'https://api.ffstars.com/numb/unsubscribe';
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ session_id: window.localStorage.getItem('publicId')}),
    })
    .then(response => response.json())
    .then(response => {
      if(response.status === 'Success') {
        this.props.showConfirmPopup(translations.tKey('str_unsubscribe'), translations.tKey('str_unsubscribe_body_success'), null);
      }
      else {
        this.props.showConfirmPopup(translations.tKey('str_unsubscribe'), translations.tKey('str_unsubscribe_body_failure'), null);
      }
    })
  }

  componentDidMount() {
    const url = operator.winnersURL;
    fetch(url, {
      credentials: 'include',
    })
      .then(response => response.json())
      .then(response => {
          this.setState({winners: response.winners});
        })
  }

  onTitleClick = (section) => {
    if (section === this.state.expandedSection) {
      this.setState({expandedSection: null});
    } else {
      this.setState({expandedSection: section});
    }
  };

  render() {
    const {expandedSection} = this.state;
    const {winners} = this.state;

    return (
      <div style={styles.scrollContainer}>
        <div style={styles.container}>
          <div style={styles.titleContainer} onClick={() => this.onTitleClick(1)}>
            <span style={{...styles.itemText, ...styles.itemTitle}}>
              {translations.tKey('str_support_getting_started')}
            </span>

            <Icon
              icon="arrow"
              size="0.75em"
              color="#FF4183"
              style={{...styles.icon, ...(this.state.expandedSection === 1 && styles.verticalFlip)}}
            />
          </div>

          {expandedSection === 1 && (
            operator.getStarted
          )}

          <div style={styles.titleContainer} onClick={() => this.onTitleClick(2)}>
            <span style={{...styles.itemText, ...styles.itemTitle}}>
              {translations.tKey('str_rules_score_calc')}
            </span>

            <Icon
              icon="arrow"
              size="0.75em"
              color="#FF4183"
              style={{...styles.icon, ...(this.state.expandedSection === 2 && styles.verticalFlip)}}
            />
          </div>

          {expandedSection === 2 && (
            <EnterTournamentScoring isForSupport={true} scrollable={false}/>
          )}

          <div style={styles.titleContainer} onClick={() => this.onTitleClick(3)}>
            <span style={{...styles.itemText, ...styles.itemTitle}}>
              {translations.tKey('str_support_faq')}
            </span>

            <Icon
              icon="arrow"
              size="0.75em"
              color="#FF4183"
              style={{...styles.icon, ...(this.state.expandedSection === 3 && styles.verticalFlip)}}winners
            />
          </div>

          {expandedSection === 3 && (
            operator.rules
          )}
          {operator.winners && (
            <div style={styles.titleContainer} onClick={() => this.onTitleClick(4)}>
              <span style={{...styles.itemText, ...styles.itemTitle}}>
                {translations.tKey('str_support_winners')}
              </span>

              <Icon
                icon="arrow"
                size="0.75em"
                color="#FF4183"
                style={{...styles.icon, ...(this.state.expandedSection === 4 && styles.verticalFlip)}}
              />
            </div>
          )}


          {expandedSection === 4 && (
            <View style={styles.tableContainer}>
              <View style={styles.tableHeader}>
                <div style={{...styles.tableCellSmall}}>
                  <div style={{...styles.headerButton, ...styles.spaceBetween}}>
                    <Icon icon="number" size={14} alt="week"/>

                    <Image style={styles.arrowImage} source={downArrow}/>
                  </div>
                </div>
                <div style={{...styles.tableCellMediumBig}}>
                  <div style={{...styles.headerButton, ...styles.spaceBetween}}>
                    <Icon icon="user" size={16}/>
                    <Image style={styles.arrowImage} source={downArrow}/>
                  </div>
                </div>
                <div style={{...styles.tableCellSmall}}>
                  <div style={{...styles.headerButton, ...styles.spaceBetween}}>
                    <Icon icon="points" size={16}/>

                    <Image style={styles.arrowImage} source={downArrow}/>
                  </div>
                </div>
              </View>
              {(winners.length === 0) ? <div>{translations.tKey('str_no_winners')}</div> : winners.map((entry, index) => {
                return (
                  <div style={{width: '100%'}} key={index}>
                    <div
                      style={styles.tableRow}>
                      <Text style={[styles.tableCellSmall, styles.tableCellText]}>
                        {entry.week}
                      </Text>
                      <Text style={[styles.tableCellMediumBig, styles.tableCellText]}>
                        {entry.msisdn}
                      </Text>
                      <Text style={[styles.tableCellSmall, styles.tableCellText, styles.bold, styles.center]}>
                        {entry.score}
                      </Text>
                    </div>
                  </div>
                );
              })}
            </View>
          )}
          <ReactPlayer url={VIDEO_PATH} controls={true} width='100%'/>

          {(operator.unsubscribe) ?
          <div onClick={() => this.showUnsubscribePopup()}>
            <TouchableOpacity style={styles.unsubscribe}>
              {translations.tKey('str_unsubscribe')}
            </TouchableOpacity>
          </div> :
          <div></div>}
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showConfirmPopup: (title, message, confirmAction) => dispatch(showConfirmPopup(title, message, confirmAction)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    expandedSection: state.expandedSection,
    winners: state.winners,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
