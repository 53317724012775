import { SET_BUTTON_PRESSED } from "../actions/actionTypes";

const initialState = {
    buttonId: 1
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUTTON_PRESSED:
            return {
                ...state,
                buttonId: action.id
            };
        default:
            return state;
    }
};

export default reducer;