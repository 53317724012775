export const UI_START_LOADING = "UI_START_LOADING";
export const UI_STOP_LOADING = "UI_STOP_LOADING";
export const LEADERBOARD_STOP_LOADING = "LEADERBOARD_STOP_LOADING"; 
export const LEADERBOARD_START_LOADING = "LEADERBOARD_START_LOADING";
export const TEAM_START_LOADING = "TEAM_START_LOADING";
export const TEAM_STOP_LOADING = "TEAM_STOP_LOADING";
export const SET_REAL_TEAMS = "SET_REAL_TEAMS";
export const SET_ERROR = "SET_ERROR";
export const SET_ROUNDS = "SET_ROUNDS";
export const SET_CURRENT_ROUND_ID = "SET_CURRENT_ROUND_ID";
export const SET_BUTTON_PRESSED = "SET_BUTTON_PRESSED";
