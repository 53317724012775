import * as React from "react";

function SvgArrow(props) {
  return (
    <svg viewBox="0 0 7.206 4.31" {...props}>
      <path
        d="M.354.355l3.25 3.25 3.25-3.25"
        fill="none"
        stroke={props.color}
      />
    </svg>
  );
}

export default SvgArrow;
