import {
  GET_TOURNAMENT_OVERVIEW,
  GET_TOURNAMENT_RULES,
  GET_TOURNAMENT_FIXTURES,
  GET_TOURNAMENT_ENTRIES,
  GET_TOURNAMENT_PRIZES,
  CLEAR_FIXTURES
} from './actionTypes';
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { uiStartLoading, uiStopLoading } from '../../Common/actions/ui';
import { showConfirmPopup } from '../../ConfirmPopup/actions/confirmPopup';
import * as translations from '../../Common/utils/translations'

export const getTournamentOverview = (id) => {

  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
      })
      .then(token => {
        return fetch(BASE_URL + `/overview/tournaments/${id}`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading());
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading());
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setTournamentOverview(res.data));
        }
      });
  };
};

export const setTournamentOverview = data => {
  return {
    type: GET_TOURNAMENT_OVERVIEW,
    data
  };
};

export const getTournamentRules = (id, shouldKeepFixtures) => {
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
      })
      .then(token => {
        return fetch(BASE_URL + `/rules/tournaments/${id}`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading());
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading());
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setTournamentRules(res.data, shouldKeepFixtures));
        }
      });
  };
};

export const setTournamentRules = (data, shouldKeepFixtures) => {
  return {
    type: GET_TOURNAMENT_RULES,
    data,
    shouldKeepFixtures
  };
};

export const getTournamentFixtures = (id) => {
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
      })
      .then(token => {
        return fetch(BASE_URL + `/fixtures/tournaments/${id}`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading());
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading());
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setTournamentFixtures(res.data));
        }
      });
  };
};

export const setTournamentFixtures = data => {
  return {
    type: GET_TOURNAMENT_FIXTURES,
    data
  };
};

export const getTournamentEntries = (id, name, page, sort) => {
  var filter = `?page=${page}`;
  if (name && name != '') {
    filter += `&name=${name}`;
  };
  if (sort) {
    filter += `&sortBy=${sort.name}&sort=${sort.sort}`
  }
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
      })
      .then(token => {
        return fetch(BASE_URL + `/entries/subTournaments/${id}${filter}`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading());
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading());
        if (res.data.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setTournamentEntries(res.data));
        }
      });
  };
};

export const setTournamentEntries = data => {
  return {
    type: GET_TOURNAMENT_ENTRIES,
    data
  };
};

export const getTournamentPrizes = (id) => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
      })
      .then(token => {
        return fetch(BASE_URL + `/prizes/subTournament/${id}`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.data.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setTournamentPrizes(res.data));
        }
      });
  };
};

export const setTournamentPrizes = data => {
  return {
    type: GET_TOURNAMENT_PRIZES,
    data
  };
};

export const clearFixtures = () => {
  return {
    type: CLEAR_FIXTURES
  };
};
