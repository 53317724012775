export const GET_SUBTOURNAMENT_SCORING = "GET_SUBTOURNAMENT_SCORING";
export const GET_SUBTOURNAMENT_DETAILS = "GET_SUBTOURNAMENT_DETAILS";
export const GET_PLAYER_INFO = "GET_PLAYER_INFO";
export const ENTER_SUB_TOURNAMENT = "ENTER_SUBTOURNAMENT";
export const CLEAR_TOURNAMENT_ENTERED = "CLEAR_TOURNAMENT_ENTERED";
export const GET_TOURNAMENT_FOR_TRANSFER = "GET_TOURNAMENT_FOR_TRANSFER";
export const CLEAR_TOURNAMENT_FOR_TRANSFER = "CLEAR_TOURNAMENT_FOR_TRANSFER";
export const CLEAR_TEAM_EDIT = "CLEAR_TEAM_EDIT";
export const SET_TEAM_EDIT = "SET_TEAM_EDIT";
export const SET_TEAM_ERRORS = "SET_TEAM_ERRORS";
