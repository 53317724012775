import { SET_POSITIONS } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';

export const getAllPositions = () => {
    return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
    })
    .then(token => {
      return fetch(BASE_URL + '/players/positions', {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
            "Authorization" : token
          }
      })
    })
    .catch(err => {
      console.log(err);
    })
    .then(res => res.json())
    .then(res => {
      dispatch(setPositions(res.data));
    })
  };
}


export const setPositions = positions => {
  return {
    type: SET_POSITIONS,
    positions
  };
};