import React from "react";
import { 
    View, 
    Text,
    StyleSheet, 
    TouchableOpacity,
    Image
} from "react-native";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Background from '../Common/components/Background';

import Slider from '@material-ui/lab/Slider';
import { withStyles } from '@material-ui/core/styles';
import rulesIcon from '../assets/exclamation.jpg';
import { getDomainOperator } from "../Common/utils/domainMapper";

const operator = getDomainOperator();

const design = operator.design;

const webStyles = {
  slider: {
    padding: '22px 0px',
  },
  track: {
    height: '7px',
    backgroundColor: '#347190',
    borderRadius: '15px'
  },
  thumb: {
    height: '15px',
    width: '15px',
    backgroundColor: '#347190'
  },
  radio: {
    color: '#6494AD',
    '&$checked': {
      color: '#347190'
    }
  },
  checked: {},
  radioCaption: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: `${design.textColorPrimary} !important`,
    width: '100%',
    textAlign: 'center'
  },
  formControl: {
    margin: '20px 0',
    width: '100%'
  }
};

class ChooseBet extends React.Component {
  state = {
    bet : 0,
    roomMaxPlayers: '10'
  }

  handleChange = (event, value) => this.setState({ bet: value });
  handleChangeRoomType = (event) => this.setState({ roomMaxPlayers: event.target.value });

  goToSearchRoom = () => {
    const { navigation } = this.props;
    this.setState({hideButton: true});
    let bet = 5;
    switch (this.state.bet) {
      case 0: bet = 5; break;
      case 1: bet = 10; break;
      case 2: bet = 20; break;
      case 3: bet = 50; break;
      case 4: bet = 100; break;
      case 5: bet = 500; break;
      default: break;
    }
    navigation.navigate("SearchRoom", 
    {
      bet, 
      roomMaxPlayers: this.state.roomMaxPlayers
    });
  }

  render() {
  const { navigation, classes } = this.props;
  return (
    <View style={styles.container}>
        <Background />
        <Text style={styles.subText}>Избери залог и формат на стая</Text>
        <View style={styles.betView}>
            <Text style={styles.betText}>ЗАЛОГ</Text>
            <Slider
            classes={{track: classes.track, thumb: classes.thumb}}
            value={this.state.bet}
            onChange={this.handleChange}
            min={0}
            max={5}
            step={1}  />
            <View style={{flexDirection: 'row', marginVertical: 10, width: '100%', justifyContent: 'space-between'}}>
              <Text>5</Text>
              <Text style={{marginLeft: 27}}>10</Text>
              <Text style={{marginLeft: 27}}>20</Text>
              <Text style={{marginLeft: 27}}>50</Text>
              <Text style={{marginLeft: 20}}>100</Text>
              <Text>500</Text>
            </View>
       <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" classes={{root: classes.radioCaption}}>ФОРМАТ</FormLabel>
          <RadioGroup
            name="roomType"
            className={classes.group}
            value={this.state.roomMaxPlayers}
            onChange={this.handleChangeRoomType}>
              <FormControlLabel
                value="10"
                checked
                control={<Radio classes={{
                  root: classes.radio, 
                  checked: classes.checked}} />}
                label="до 10 играча"/>
              <FormControlLabel
                value="-1"
                control={<Radio classes={{
                  root: classes.radio, 
                  checked: classes.checked}} />}
                label="без лимит на играчи"/>
              <FormControlLabel
                value="2"
                control={<Radio classes={{
                  root: classes.radio, 
                  checked: classes.checked}} />}
                label="един срещу друг"/>
          </RadioGroup>
        </FormControl>
    </View>

    {!this.state.hideButton && <TouchableOpacity style={styles.playButton} onPress={this.goToSearchRoom}>
        <Text style={styles.buttonText}>ТЪРСИ СТАЯ</Text>
    </TouchableOpacity>}


    <TouchableOpacity style={styles.playButton} onPress={() => this.props.navigation.navigate("Home")}>
        <Text style={styles.buttonText}>ОТКАЖИ</Text>
    </TouchableOpacity>

    <TouchableOpacity style={styles.rules} onPress={()=>navigation.navigate('Rules')}>
      <Image source={rulesIcon} style={styles.image} />
      <Text style={styles.rulesText}>правила на играта</Text>
    </TouchableOpacity>
    </View>
  );
  }
};

export default withStyles(webStyles)(ChooseBet);



const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  betView: {
    backgroundColor: '#CCD4E1',
    paddingVertical: 5,
    paddingHorizontal: 30,
    width: '90%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 15
  },
  betText: {
    fontWeight: 'bold',
    fontSize: 25,
    marginVertical: 5
  },
  logo: {
    width: '60%',
    height: 110,
    resizeMode: 'contain',
  },
  playButton: {
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    width: '70%',
    backgroundColor: '#CDD4E0',
    paddingVertical: 20,
    paddingHorizontal: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 22
  },
  subText: {
    zIndex: 200,
    fontWeight: 'bold',
    width: '100%',
    marginVertical: 12,
    marginTop: 70,
    color: 'white',
    fontSize: 25,
    textAlign: 'center'
  },
  emphasis: {
    marginVertical: 7,
    color: '#C5A150',
    fontSize: 22,
    textAlign: 'center',
    width: '70%'
  },
  mainText: {
    marginVertical: 7,
    color: 'white',
    fontSize: 36,
    textAlign: 'center'
  },
  rules: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 20
  },
  rulesText: {  
    color: '#C3A050',
    fontSize: 18,
    margin: 10
  },
  image: {
    width: 30, 
    height: 30,
    resizeMode: 'contain'
  }
});