import * as React from "react";

function SvgDifficulty(props) {
  return (
    <svg viewBox="0 0 13.459 13.459" {...props}>
      <defs>
        <style>{".difficulty_svg__a{fill:#bebebe}"}</style>
      </defs>
      <path
        className="difficulty_svg__a"
        d="M12.597 13.459H.862A.862.862 0 010 12.597V.862a.862.862 0 011.724 0v10.874h10.873a.862.862 0 110 1.724z"
      />
      <path
        className="difficulty_svg__a"
        d="M3.485 8.53a.862.862 0 01-.579-1.5L6.03 4.201a.862.862 0 011.24.087l1.486 1.783 3.274-2.864a.863.863 0 011.135 1.3L9.227 7.953a.871.871 0 01-.635.211.862.862 0 01-.594-.307L6.522 6.086 4.064 8.312a.858.858 0 01-.579.218z"
      />
    </svg>
  );
}

export default SvgDifficulty;
