import { 
  SET_CURRENT_ROUND_ID,
  SET_ROUNDS, 
} from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';

export const getRounds = (id) => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
    })
    .then(token => {
        return fetch(BASE_URL + `/championships/${id}/rounds`, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => {
      dispatch(setRounds(res.data));
    });
  };
}


export const setRounds = rounds => {
  return {
    type: SET_ROUNDS,
    rounds
  };
};

export const setCurrentRound = roundId => {
  console.log('in action', roundId);
  return {
    type: SET_CURRENT_ROUND_ID,
    roundId
  }
}