import * as React from "react";

function SvgInfo(props) {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M8 16a8.009 8.009 0 01-8-8 8.009 8.009 0 018-8 8.009 8.009 0 018 8 8.009 8.009 0 01-8 8zM7.562 6l-.815 3.852a4.187 4.187 0 00-.1.78 1.488 1.488 0 00.355 1.08 1.25 1.25 0 00.93.36 1.352 1.352 0 00.894-.318 1.282 1.282 0 00.427-.918.982.982 0 01-.384.072.56.56 0 01-.378-.1.438.438 0 01-.114-.341 1.512 1.512 0 01.048-.384l.864-4.08zM8.75 3.444a.907.907 0 00-.671.282.937.937 0 00-.276.678.916.916 0 00.276.672.918.918 0 00.671.276.942.942 0 00.679-.276.907.907 0 00.282-.672.928.928 0 00-.282-.678.93.93 0 00-.679-.282z"
        fill="#bebebe"
      />
    </svg>
  );
}

export default SvgInfo;
