import * as React from "react";

function SvgLegend(props) {
  return (
    <svg viewBox="0 0 22 22" {...props}>
      <circle cx={11} cy={11} r={11} fill="#ff085d" />
      <path
        d="M7.376 8.565h2.1a1.438 1.438 0 011.531-1.443 1.322 1.322 0 011.474 1.272c0 .737-.3 1.117-1.3 1.715a2.388 2.388 0 00-1.4 2.545l.016.45h2.056v-.411c0-.76.287-1.14 1.319-1.738a2.9 2.9 0 001.66-2.63c0-1.769-1.466-3-3.685-3-2.382-.003-3.732 1.331-3.771 3.24zm3.476 8.247a1.238 1.238 0 001.272-1.218 1.238 1.238 0 00-1.272-1.219 1.236 1.236 0 00-1.265 1.219 1.236 1.236 0 001.265 1.218z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLegend;
