import React from 'react';
import { StyleSheet, TouchableOpacity, Text, Image, View } from 'react-native';
import arrow from '../../assets/arrow-pointing-to-right.png';

const linkView = (props) => {
    return (
        <TouchableOpacity 
            style={[styles.container, props.style]} 
            onPress={() => props.navigation.navigate(props.page)}>
            <Text style={styles.title}>{props.title}</Text>
            
            
            <View style={{flexDirection: 'row', overflow: 'hidden'}}>
                <View style={styles.border}></View>
                <View style={styles.imageContainer}>
                    <Image source={arrow} style={styles.arrow} />
                </View>
            </View>
            
        </TouchableOpacity>
    );
}

export default linkView;

const styles = StyleSheet.create({
  container : {
      width: '90%',
      backgroundColor: 'white',
      borderRadius: 10,
      flexDirection: 'row',
      marginVertical: 5,
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 15,
      height:50,
      overflow: 'hidden'
  },
  title: {
      fontWeight: 'bold',
      fontSize: 15
  },
  arrow: {
      width: 30,
      height: 20,
  },
  imageContainer: {
      backgroundColor: '#CCD4E1',
      paddingVertical: 15,
      paddingLeft: 30,
      paddingRight: 30,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      flexDirection: 'row',
      overflow: 'hidden',
      alignItems: 'center',
  },
  border: {
    transform: [{skewY : "12deg"}],
    top: 0,
    left: 10,
    width: 20,
    height: 57,
    backgroundColor: '#cbd4e2',
    overflow: 'hidden'
  }
});

