export const authGetToken = () => {
    return (dispatch, getState) => {
        const promise = new Promise((resolve, reject) => {
            let token = getState().auth.token;
            if (!token) {
                token = localStorage.getItem('token');
                if (!token)
                    reject(); 
                else
                    resolve(token);          
            } else {
                resolve(token);
            }
        });  
        return promise;
    };
};