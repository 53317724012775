import * as actionTypes from './actionTypes'

export const showConfirmPopup = (title, message, confirmAction) => {
    return dispatch => {
        dispatch(setShowConfirmPopup(title, message, confirmAction));
    };
};

export const setShowConfirmPopup = (title, message, confirmAction) => {
    return {
        type: actionTypes.SHOW_CONFIRM_MODAL,
        show: true,
        title: title,
        message: message,
        confirmAction: confirmAction
    };
};

export const hideConfirmModal = () => {
    return dispatch => {
        dispatch(setHideConfirmModal());
    };
};

export const setHideConfirmModal = () => {
    return {
        type: actionTypes.HIDE_CONFIRM_MODAL,
        show: false
    };
};