import {
    GET_MATCH_HIGHLIGHTS
} from "../actions/actionTypes";

const initialState = {
   highlights: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MATCH_HIGHLIGHTS:
            return {
                ...state,
                highlights: action.data,
               
            };
        default:
            return state;
    }
};

export default reducer;